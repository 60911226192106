import React, {useEffect} from 'react';
import "./bikes.scss"
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {fetchBikeList} from "../features/bike/bikeSlice";
import {label} from "../service";
import {useScrollTop} from "../hooks";
import {Carousel} from "../components/common/Carousel";


export const Bikes = () => {
    let bikes = useAppSelector(state => state.bikes.list);
    let mobile = useAppSelector(state => state.common.mobile);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchBikeList());
    }, []);

    useScrollTop();

    let Bikes = bikes.map((item, index) => {
        return {
            img: item.img_full,
            id: item.id,
            index: index + 1,
            name: item.name,
            price: item.price,
            price_from: `€${item.price_from} / ${label('Day')}`,
            visitors:item.visitors,
            last_order:item.last_order
        }
    })

    return (
        <main className="main bike_list w-full">
            <div className="bike_list_title page-header">
                <h1 className={'title'}>{label('Select your Motorcycle')}</h1>
            </div>
            <Carousel items={Bikes} mobile={mobile}/>
        </main>
    );
};


