import React, {FC, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {TourItemCity, TourItemCountry} from "../../types/tourItem";
import {TooltipOverviewItem} from "./TooltipOverviewItem";
import {Link} from "react-router-dom";

export const CitiesOverview = () => {
    const cities = useAppSelector(state => state.toursItem.cities);

    if (!cities.length) {
        return null;
    }

    return (
        <div className={"tour_info_overview_column"}>
            <h5>{label('Cities')}</h5>
            <div className={"tour_info_overview_item"}>
                <div className={"tour_info_overview_list"}>
                    {
                        cities.map((city: TourItemCity) => {
                            return (
                                <CityItem
                                     key={city.id}
                                     city={city}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

interface cityItemProps {
    city: TourItemCity
}

const CityItem: FC<cityItemProps> = ({city}) => {
    return (
        <div className={"tour_info_overview_list_item"}>
            {city.name}
        </div>
    )
    /*
    * <TooltipOverviewItem
            name={city.name}
            image={city.image}
            info={city.info}
        />*/
}