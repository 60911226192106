import React, {FC, useState} from "react";
import {PackageItem} from "../../types/myPackages";
import {useAppSelector} from "../../app/hooks";
import moment from "moment";
import {label} from "../../service";
import {RiArrowDownSLine, RiArrowUpSLine} from "react-icons/ri";
import {MyPackageItemDetails} from "./MyPackageItemDetails";
import {MyPackageItemChildren} from "./MyPackageItemChildren";

interface itemProps{
    item:PackageItem
}
export const MyPackagesItem:FC<itemProps> = ({item}) => {
    const mobile = useAppSelector(state=>state.common.mobile);
    const [open,setOpen] = useState(false);

    const getStatusClass = () => {
        switch(+item.status){
            case 5:
                return "canceled_item";
            case 4:
                return "completed_item";
            case 3:
                return "performed_item";
            case 2:
                return "paid_item";
            case 1:
            default:
                return "";
        }
    }

    const toggleOpen = () => {
        setOpen(!open);
    }

    return (
        <li className={"my_packages_list_item"}>
            <div className={"my_packages_list_item_heading"}
                 onClick={toggleOpen}
            >
                <div className={"my_packages_list_item_heading_decor " +(getStatusClass())}/>
                <div className={"my_packages_list_item_heading_row"}>
                    <div className={"my_packages_list_item_heading_status"}>
                        <p className={"my_packages_list_item_heading_label"}>
                            <span style={{fontWeight:'bold'}}>{'#'+item.id}</span>
                            <time>{item.create_time}</time>
                        </p>
                        <p className={"my_packages_list_item_heading_value"}>{item.status_name}</p>
                    </div>
                    {!open && <div className={"my_packages_list_item_heading_price"}>
                        <p className={"my_packages_list_item_heading_label"}>{label('Total price')}</p>
                        <p className={"my_packages_list_item_heading_value"}>{item.price}€</p>
                    </div>}
                    {!open && <div className={"my_packages_list_item_heading_price"}>
                        <p className={"my_packages_list_item_heading_label"}>{label('Count Days')}</p>
                        <p className={"my_packages_list_item_heading_value"}>{item.count_days}</p>
                    </div>}
                    {!open && <div className={"my_packages_list_item_heading_price"}>
                        {+item.status === 2 && +item.count_left > 0 && <p className={"my_packages_list_item_heading_label"}>{label('Days Left')}</p>}
                        {+item.status === 2 && +item.count_left > 0 && <p className={"my_packages_list_item_heading_value"}>{item.count_left}</p>}
                    </div>}
                    {!open && <div className={"my_packages_list_item_heading_goods"}>
                        {!mobile && item.bike_img_small!=="" &&  <div className={"my_packages_list_item_heading_goods_item"}>
                            <img src={item.bike_img_small} alt={item.bike_name}/>
                        </div>}
                        {mobile && item.bike_img_medium!=="" &&  <div className={"my_packages_list_item_heading_goods_item"}>
                            <img src={item.bike_img_medium} alt={item.bike_name}/>
                        </div>}
                    </div>}
                    <div className={"my_packages_list_item_heading_chevron"}>
                        {!open ? <RiArrowDownSLine/>
                            :
                            <RiArrowUpSLine />
                        }
                    </div>
                </div>
            </div>
            {open && <MyPackageItemDetails item={item}/>}
            {open && item.children.length > 0 && <MyPackageItemChildren children={item.children}/>}
        </li>
    )
}