import React, {FC} from 'react';
import {label} from "../../service";
import {moneyFormat} from "../../utils";
import {PaymentPercent} from "../../types";
import {CheckBox} from "../common/checkbox";


interface paymentProps{
    total: number
    percent: number
    percentChange: (percent:number) => void
    payment_percents:PaymentPercent[]
}
export const Paymend: FC<paymentProps> = ({total, percent, percentChange, payment_percents}) => {
        const amount = moneyFormat((total * percent) / 100);
        return (
            <div className=" accessor payment">
                <div className={"heading"}>{label('Payment percentage')}</div>
                <div className="total-checked">
                    <div className={"rental_values radio_values"}>
                        {
                            payment_percents.map((item) => {
                                const handleChange = () => {
                                    percentChange(item.percent)
                                }
                                return (
                                    <CheckBox key={item.id}
                                              onChange={handleChange}
                                              label={item.name}
                                              name={''}
                                              price={item.name}
                                              checked = {+percent === +item.percent}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                {false && <div className="total-amount">
                    <div className="total-amount_value"><span>{amount}</span></div>
                    <div className={"total-amount_note"}>*{label('To pay now')}</div>
                </div>}
            </div>
        );
    };

