import React, {ChangeEvent, FC, useMemo} from "react";
import {label} from "../../service";
import {Equipments} from "../../types";

interface accessoriesSizesProps {
    equip: Equipments[]
    onAccessoryChangeSize: (id:number,number:number,size:string) => void
    accessor: any
}

const AccessorySize:FC<{accessor:any,item:Equipments,changeSelect:(e:React.ChangeEvent<HTMLSelectElement>,id:number,number:number)=>void}> =
    ({accessor,item,changeSelect})=>{

    const sizes = useMemo(()=>{
          const sizes:{value:string,name:string}[] = [] ;
          item.sizes.forEach(el=>{
            sizes.push({value:el.size, name: el.size});

        })

        sizes.push({value:'-1', name: label('For request')});
        return sizes
    },[item]);

    return(
        <div className="accessor_size_items_row" >

            <div className="accessor_size_items_row_sizes">
                {Object.keys(accessor[item.id].sizes).map((number) => {
                    let size = accessor[item.id].sizes[number];
                    return (
                        <div className="accessor_size_items_row_sizes_item">
                            <div className="accessor_size_items_row_sizes_item_number">
                                {item.name+' '+number}
                            </div>
                            <div className="accessor_size_items_row_sizes_item_size">
                                <select
                                    value={size}
                                    name={'size'}
                                    onChange={(e)=>{changeSelect(e,item.id,+number)}}
                                >
                                    {
                                        sizes.map((i) => {
                                            return (
                                                <option key={i.value}
                                                        value={i.value}>
                                                    {i.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export const AccessoriesSizes: FC<accessoriesSizesProps> = ({onAccessoryChangeSize,equip, accessor}) => {


    const changeSelect = (e:ChangeEvent<HTMLSelectElement>,id:number,number:number) => {
        onAccessoryChangeSize(id,number,e.target.value);
    }
    return (
        <div className="accessor_size">
            <div className="heading">
                {label('Accessories sizes')}
            </div>
            <div className="accessor_size_items">
                {
                    equip.map((item,index) => {
                        if (accessor[item.id] !== undefined && !!item.sized && (item.count || true)) {
                            return <AccessorySize
                                accessor={accessor}
                                item={item} changeSelect={changeSelect}  key={index}
                                />
                        } else {
                            return null;
                        }
                    })
                }
            </div>
        </div>
    )
}