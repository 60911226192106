import React from "react";
import {label} from "../../service";
import moment from "moment";
import {useAppSelector} from "../../app/hooks";
import {getBikePrice, getPackageOrderTotal} from "../../utils/packageOrder";

export const PackageOrderCheckoutCalc = () => {
    const values = useAppSelector(state => state.packageBikeDetails.values);
    const equip = useAppSelector(state => state.packageBikeDetails.equip);
    const extra = useAppSelector(state => state.packageBikeDetails.extra);
    const bike_list = useAppSelector(state => state.packageBikeDetails.bike_list);
    const overtimeData = useAppSelector(state => state.packageBikeDetails.overtime);
    const bike = bike_list.find(i => i.id === +values.bike_id);
    const {date,overtime} = values;

    const canBook = date.start && date.end && date.optionStart && date.optionEnd;

    if (!bike) {
        return null;
    }

    const bike_price = getBikePrice(values, bike_list, date.duration);
    const total = getPackageOrderTotal(values, equip, extra, bike_list, overtimeData,date.duration);
    return (
        <div className="order-calc">
            <div className={"heading"}>{label('Total')}</div>
            <div className="total-checked">
                {(date.start || date.end) && <div className="total-checked-date">
                    {date.start && <div className="total-checked-date_item">
                        <div className="total-checked-date_item_name">{label('Pick-up')}</div>
                        <div className="total-checked-date_item_date">{date.start} {date.optionStart}</div>
                    </div>}
                    {date.end && <div className="total-checked-date_item">
                        <div className="total-checked-date_item_name">{label('Drop-off')}</div>
                        <div className="total-checked-date_item_date">{date.end} {date.optionEnd}</div>
                    </div>}
                </div>}
                {date.duration > 0 && <div className="total-checked-rent">
                    <div className="total-checked-rent_item">
                        <div className="total-checked-rent_item_name">{label('Days Used')}</div>
                        <div className="total-checked-rent_item_date">{date.duration}</div>
                    </div>
                </div>}
                {(date.start && date.end) && bike_price > 0 && <div className="total-checked-rent">
                    <div className="total-checked-rent_item">
                        <div className="total-checked-rent_item_name">{label('Motorcycle Rent')}</div>
                        <div className="total-checked-rent_item_date">{bike_price}</div>
                    </div>
                </div>}
                {Object.keys(values.accessor).length > 0 && <div className="total-checked-accessories">
                    {equip.map(item => {
                        if (values.accessor[item.id] !== undefined) {
                            let count = values.accessor[item.id].count || 1;
                            return <div className="total-checked-accessories_item"
                                        key={item.id}
                            >
                                <div className="total-checked-accessories_item_name">
                                    {count > 1 && <div
                                        className="total-checked-accessories_item_name_count">{count + 'x '}&nbsp;</div>}
                                    <div className="total-checked-accessories_item_name_value">{item.name}</div>
                                    {+item.count === 0 && <div className="total-checked-accessories_item_name_request">
                                        <span className={"for-request"}>{label('For request')}</span></div>}
                                </div>
                                <div className="total-checked-accessories_item_price">
                                    {item.price > 0 ? (+item.period === 1 ? +item.price * count * date.duration : +item.price * count) : label('Free')}
                                </div>
                            </div>
                        } else {
                            return null;
                        }
                    })}
                </div>}
                {values.extras.length > 0 && <div className="total-checked-extras">
                    {extra.map(item => {
                        if (values.extras.includes(item.id)) {
                            return <div className="total-checked-extras_item"
                                        key={item.id}
                            >
                                <div className="total-checked-extras_item_name">
                                    <div className="total-checked-extras_item_name_value">{item.name}</div>
                                </div>
                                <div className="total-checked-extras_item_price">
                                    {+item.price > 0 ? 1 * +item.price : label('Free')}
                                </div>
                            </div>
                        } else {
                            return null;
                        }
                    })}
                </div>}
                {overtimeData && <div className={"total-checked-extras"}>
                    {Object.entries(overtimeData).map(([key, item]: any) => {
                        if (overtime.includes(key)) {
                            return (<div className="total-checked-extras_item"
                                         key={item.id}
                            >
                                <div className="total-checked-extras_item_name">
                                    <div className="total-checked-extras_item_name_value">{item.name}</div>
                                </div>
                                <div className="total-checked-extras_item_price">
                                    {item.price > 0 ? item.price : label('Free')}
                                </div>
                            </div>)
                        } else {
                            return null
                        }
                    })
                    }
                </div>}
            </div>
            {canBook && <div className="total-amount">
                <div className="total-amount_name">{label('Total price')}</div>
                <div className="total-amount_value"><span>{total}€</span></div>
            </div>}
        </div>
    );
}