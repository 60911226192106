import React, {FC} from 'react';
import {clsx} from "clsx";
interface TextAreaProps {
    rows?:number,
    error:boolean,
    value:string|number,
    onChange:(e:React.ChangeEvent<HTMLTextAreaElement>)=>void,
    name:string,
    placeHolder:string
}

export const TextArea:FC<TextAreaProps> = ({rows = 5,error,value,onChange,placeHolder,name}) => {
    return (
        <div className={clsx(
            "input-item",
            error && "error"
        )}>
            <textarea rows={rows} value={value} name={name} onChange={onChange} placeholder={placeHolder}/>
        </div>
    );
};

