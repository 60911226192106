import React, {FC} from "react";

interface ruleProps {
    text: string
    name: string
}

export const PackageRulesTextName: FC<ruleProps> = ({text,name}) => {
    if (!text) {
        return null;
    }
    return (
        <div className={"packages_rules_item text_name"}>
            <span className={"text_name_name"}>{name+':'}</span>
            <span className={"text_name_text"}>{text}</span>
        </div>
    )
}