import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {CertificateCheckoutOne} from "./CertificateCheckoutOne";
import {CertificateVoucher} from "./CertificateVoucher";
import {CertificateCheckoutPrice} from "./CertificateCheckoutPrice";
import {CertificateCheckoutUser} from "./CertificateCheckoutUser";
import {CertificateCheckoutPayment} from "./CertificateCheckoutPayment";
import {CertificateVoucherCanvas} from "./CertificateVoucherCanvas";
import {
    fetchCertificatePromo,
    fetchCertificateSave,
    processSuccess,
    resetSuccess
} from "../../features/certificate/certificateSlice";
import {CheckoutImageBlock} from "./CheckoutImageBlock";
import {CheckoutTextBlock} from "./CheckoutTextBlock";
import {CheckoutPriceBlock} from "./CheckoutPriceBlock";
import {CheckoutUserBlock} from "./CheckoutUserBlock";
import {UserError} from "../../types/certificate";
import {validateEmail, validateName, validatePhone} from "../../utils";
import {label} from "../../service";
import {CertificateRules} from "./CertificateRules";
import {PaymentMethod} from "../../types";
import {useLocation} from "react-router-dom";
import {OrderModal} from "../common/OrderModal";

export const CertificateCheckout = () => {
    const [error, setError] = useState<UserError>({
        email: false,
        phone: false,
        name: false,
        surname: false,
        zip_code: false,
        city: false,
        address: false
    });

    const stage = useAppSelector(state => state.certificate.stage);
    const values = useAppSelector(state => state.certificate.values);
    const payment_methods = useAppSelector(state => state.certificate.payment_methods);
    const user = useAppSelector(state => state.certificate.user);
    const success = useAppSelector(state => state.certificate.success);
    const invoiceUrl = useAppSelector(state => state.certificate.invoiceUrl);

    const dispatch = useAppDispatch();

    const location = useLocation();

    const [paid, setPaid] = useState<boolean>(false);

    const orderVoucher = () => {
        if (
            values.image_id > 0 &&
            values.price > 0 &&
            values.text !== '' &&
            values.method !== '' &&
            user.name !== '' &&
            user.email !== '' &&
            user.phone !== ''
        ) {
            dispatch(processSuccess());
            dispatch(fetchCertificatePromo({values}));
        }
    }

    useEffect(()=>{
        if(values.canvas){
           dispatch(fetchCertificateSave({values,user}));
        }
    },[values.canvas]);

    const method = payment_methods.find((method: PaymentMethod) => method.id === values.method);
    useEffect(() => {
        if (+success === 2 && invoiceUrl !== '' && values.method) {
            let target = "_self";
            if (method && method.target) {
                target = method.target;
            }
            // 👇 Open link in new tab programmatically
            window.open(invoiceUrl, target, 'noreferrer');
        }
    }, [success, invoiceUrl]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('order')) {
            setPaid(true);
        }
    }, [location.search]);

    const closePaid = () => {
        setPaid(false);
    }

    const getCheckoutPage = () => {
        switch (stage) {
            case 0:
                return <CertificateCheckoutOne/>;
            case 1:
                return <CertificateCheckoutPrice/>;
            case 2:
                return <CertificateCheckoutUser/>;
            case 3:
                return <CertificateCheckoutPayment
                    orderVoucher={orderVoucher}
                />;

        }
        return null;
    }

    const validateUserData = (needSetError = false) => {
        const error: UserError = {
            email: false,
            phone: false,
            name: false,
            surname: false,
            zip_code: false,
            city: false,
            address: false
        }
        let result = true;
        if (!validateEmail(user.email)) {
            error.email = true;
            result = false;
        }
        if (!validatePhone(user.phone)) {
            error.phone = true;
            result = false;
        }
        if (!validateName(user.name)) {
            error.name = true;
            result = false;
        }
        if (user.city === '') {
            // error.city = true;
            // result = false;
        }
        if (user.address === '') {
            // error.address = true;
            // result = false;
        }
        if (user.zip_code === '') {
            // error.zip_code = true;
            // result = false;
        }
        if (needSetError) {
            setError(error);
        }
        return result;
    }

    const getSuccess = () => {
        switch(+success){
            case -1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Something wrong.')}</h1>
                        <span>{label('Please try again later.')}</span>
                        <div className={"btn btn-secondary"} onClick={resetErrorLayer}>OK</div>
                    </div>
                )
            case 1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Create in progress.')}</h1>
                        <span className="loader"/>
                    </div>
                )
            case 2:
                return null;
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Create successfully accepted.')}</h1>
                        <span>{label('We will contact you shortly..')}</span>
                    </div>
                )
        }
    }

    const resetErrorLayer = () => {
        dispatch(resetSuccess());
    }

    return (
        <div className={"certificate_checkout "}>
            {paid && <OrderModal
                onClose={closePaid}
                certificate={true}
            />}
            <div className={"certificate_checkout_layer " + (!success ? "disable_layer" : "")}>
                {getSuccess()}
            </div>
            <div className={"certificate_checkout_page"}>
                <div className={"checkout"}>
                    <div className={"selected-block bikes-block"}>
                        <CheckoutImageBlock/>
                        <CheckoutTextBlock/>
                        <CheckoutPriceBlock/>
                        <CheckoutUserBlock error={error}/>
                    </div>
                    <div className={"booking-block"}>
                        <div className={"btn   btn-secondary" + (!validateUserData() ? "disabled" : "")}
                            // @ts-ignore
                             onClick={validateUserData() ? orderVoucher : null}
                        >{label('Confirm')}</div>
                    </div>
                    <CertificateRules />
                </div>
                <CertificateVoucherCanvas/>
                <CertificateVoucher/>
            </div>
        </div>
    )
}