import React, {FC} from "react";
import {FaChevronDown} from "react-icons/fa";

interface areaProps {
    rentalClass?: string
    canOpen?: boolean
    opened?: boolean
    children: any
    label: string
    type?: string
    lengthItems?: number
    changeType?: (type: string) => void
}

const ItemsArea: FC<areaProps> = ({
                                      canOpen = false,
                                      opened = true,
                                      children,
                                      label,
                                      type = "",
                                      changeType,
                                      rentalClass = "",
                                      lengthItems = 0
                                  }) => {

    const handleToggle = () => {
        if (!opened && canOpen && changeType) {
            changeType(type)
        }
    }

    const getHeight = () => {
        if(canOpen){
            if(opened){
                //return "max-content";
                return lengthItems * 1.7 + 2 + 'em';
            }else{
                return '0';
            }
        }else{
            return "max-content";
        }
    }

    return (
        <div className={"accessor " + (!opened ? " closed " : "") + (canOpen ? ' canOpen ' : "")}>
            <div className="heading" onClick={handleToggle}>
                {label}
                {!opened && canOpen && <div className={"heading_opener "}>
                    <FaChevronDown/>
                </div>}
            </div>
            <div className={"rental_values " + (rentalClass)} style={{height:getHeight()}}>
                {children}
            </div>
        </div>
    )
}

export default ItemsArea;