export const getCurrent = (arrays: any[], current: string) => {
    let find = arrays.find((item:{type:string,items:any[]})=>item.type === current);
    if (!find || !find.items || !find.items.length || !current) {
        let new_current = "";
        arrays.forEach((item:{type:string,items:any[]})=>{
            if(!new_current){
                new_current = item.type;
            }
            return item.type;
        });
        return new_current;
    } else {
        return current;
    }
}