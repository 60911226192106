import React, {FC, useState} from "react";
import {PaymentMethod, PaymentPercent, User} from "../../types";
import {label} from "../../service";
import {Paymend} from "../tools/Paymend";
import {PaymentMethodCheck} from "../tools/PaymentMethod";

import ReadTerms from "./ReadTerms";

interface CheckoutPagePaymentProps {
    onBook: () => void
    onBack: (page: number) => void
    total:number
    percent:number
    method:string
    percentChange:(percent:number)=>void
    methodChange:(method:string)=>void
    payment_methods:PaymentMethod[]
    payment_percents:PaymentPercent[]
}

export const CheckoutPagePayment: FC<CheckoutPagePaymentProps> = ({onBack,onBook,total,percent,method,methodChange,percentChange,payment_methods,payment_percents}) => {
    const [agreeTerms, setTerms] = useState(false);

    const handleAgreeTerms = () => {
        setTerms(!agreeTerms);
    }

    const handleBack = () => {
        onBack(2);
    }
    const canBook = +percent > 0 && method !== '' && agreeTerms;
    const handleNext = () => {
        if(canBook){
            onBook();
        }
    }

    return (
        <div className="checkout">
            <div className="selected-block">
                <div className="main-data">
                    <Paymend total={total} percent={percent} percentChange={percentChange} payment_percents = {payment_percents}/>
                </div>
                <div className="main-data">
                    <PaymentMethodCheck method={method} methodChange={methodChange} payment_methods={payment_methods}/>
                </div>
                <div className="note-data">
                    <ReadTerms onChange={handleAgreeTerms} checked={agreeTerms}/>
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-allow "+(!canBook ? " not-allowed opacity-50":"")}
                     onClick={handleNext}>{label('Confirm')}</div>
            </div>
        </div>
    )
}