export function SetCookie(name:string, value:any, exp?:number, path?:string) {
    if (!path) path = '/';
    if (!exp) exp = 3600 * 24 * 365;
    let d = new Date();
    d.setTime(d.getTime() + (1000 * exp));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=" + path + ";";
}

export function GetCookie(cname:string) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}