import React, {useEffect, useState} from "react";
import {label} from "../../service";
import {MainInput} from "../tools/MainInput";
import {OrderData, User} from "../../types";
import {TextArea} from "../tools/TextArea";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {validateEmail, validateMessage, validateName, validatePhone} from "../../utils";
import {fetchMessage} from "../../features/contacts/contactsSlice";
import {showLogin} from "../../features/login/loginSlice";


export type ContactsFormError = {
    email: boolean;
    phone: boolean;
    name: boolean;
    note: boolean;
}

export const ContactsForm = () => {
    const [form, setForm] = useState<User>({
        name: '',
        surname: '',
        email: '',
        phone: '',
        note: '',
        password: '',
        birthdate:"",
        zipcode:"",
        country:"",
        country_id:0,
        city:"",
        street:"",
        is_firm:0,
        firm_name:"",
        ICO:"",
        DIC:"",
        IC_DPH:"",
    });

    const [error, setError] = useState<ContactsFormError>({
        email: false,
        phone: false,
        name: false,
        note: false
    });

    const dispatch = useAppDispatch();

    const send = useAppSelector(state=>state.contacts.send);

    useEffect(()=>{
        if(send === 'success'){
            setForm({
                name: '',
                surname: '',
                email: '',
                phone: '',
                note: '',
                password:"",
                birthdate:"",
                zipcode:"",
                country:"",
                country_id:0,
                city:"",
                street:"",
                is_firm:0,
                firm_name:"",
                ICO:"",
                DIC:"",
                IC_DPH:"",
            });
        }

    },[send]);

    const onFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newForm: any = {...form};
        if(newForm[e.target.name]!==undefined){
            newForm[e.target.name] = e.target.value;
            setForm(newForm);
            const newError: any = {...error};
            if(newError[e.target.name]!==undefined){
                newError[e.target.name] = false;
                setError(newError)
            }
        }
    }

    const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newForm: any = {...form};
        if(newForm[e.target.name]!==undefined){
            newForm[e.target.name] = e.target.value;
            setForm(newForm)
        }
    }

    const validate = (formData: User) => {
        const error: ContactsFormError = {
            email: false,
            phone: false,
            name: false,
            note: false
        }
        let result = true;
        if (!validateEmail(formData.email)) {
            error.email = true;
            result = false;
        }
        if (!validatePhone(formData.phone)) {
            error.phone = true;
            result = false;
        }
        if (!validateName(formData.name)) {
            error.name = true;
            result = false;
        }
        if (!validateMessage(formData.note)) {
            error.note = true;
            result = false;
        }

        setError(error)
        return result;
    }

    const sendMessage = () => {
        const formData: User = {
            name: form.name,
            email: form.email,
            phone: form.phone,
            note: form.note,
            surname:"",
            birthdate:"",
            zipcode:"",
            country:"",
            country_id:0,
            city:"",
            street:"",
            password: "",
            is_firm:0,
            firm_name:"",
            ICO:"",
            DIC:"",
            IC_DPH:"",
        }

        if (!validate(formData)) {
            return;
        }
        dispatch(fetchMessage(formData));
    }

    return (
        <div className={"contacts_form"}>
            <div className={"contacts_subheader"}>{label('Send Us a Message')}</div>
            <div className={"contacts_form_data"}>
                <div className={"contacts_form_data_column"}>
                    <div className={"contacts_form_data_item"}>
                        <div className={"contacts_form_data_label"}>{label('Your Name')}</div>
                        <div className={"contacts_form_data_value"}>
                            <MainInput error={error.name} value={form.name} onChange={onFormChange} name={'name'} placeHolder={label('Your Name')} />
                        </div>
                    </div>
                    <div className={"contacts_form_data_item"}>
                        <div className={"contacts_form_data_label"}>{label('Email Address')}</div>
                        <div className={"contacts_form_data_value input-email"}>
                            <MainInput error={error.email} value={form.email} onChange={onFormChange} name={'email'} placeHolder={label('Email Address')} />
                        </div>
                    </div>
                    <div className={"contacts_form_data_item"}>
                        <div className={"contacts_form_data_label"}>{label('Phone Number')}</div>
                        <div className={"contacts_form_data_value input-phone"}>
                            <MainInput error={error.phone} value={form.phone} onChange={onFormChange} name={'phone'} placeHolder={'+ --- --------'} />
                        </div>
                    </div>
                </div>
                <div className={"contacts_form_data_column"}>
                    <div className={"contacts_form_data_item"}>
                        <div className={"contacts_form_data_label"}>{label('Your Message')}</div>
                        <div className={"contacts_form_data_value text"}>
                            <TextArea rows={8} error={error.note} value={form.note} onChange={onTextAreaChange} name={'note'} placeHolder={label('Your Message')} />
                        </div>
                    </div>
                </div>
                <div className={"contacts_form_data_column"}>
                    <div className={"contacts_form_data_item button_item"}>
                        <div className={"btn_more"} onClick={sendMessage}>{label('Send')}</div>
                    </div>
                </div>
            </div>
            <div className={"contacts_form_layer "+(send !== 'success' ? "disable_layer" :"")}>
                <h2>{label('Your message send')}</h2>
            </div>
        </div>
    )
}

