import React, {FC, useEffect, useState} from "react";
import {ModalPortal} from "../common";
import {ModalWrap} from "../common/ModalWrap";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {validateEmail} from "../../utils";
import {MainInput} from "../tools/MainInput";
import {label} from "../../service";
import {TourItemDate, TourItemInfo, TourWaitingData} from "../../types/tourItem";
import {prepareDate} from "../../utils/tourInfo";
import {fetchTourWaitingList, resetWaitingSuccess} from "../../features/tours/toursItemSlice";

interface waitingProps {
    onClose: () => void
    date: TourItemDate
    info: TourItemInfo
    success:boolean
}

const TourWaitingModal: FC<waitingProps> = ({onClose, date, info,success}) => {
    const [form, setForm] = useState<any>({
        email: '',
    });
    const [error, setError] = useState<any>({
        email: false,
    });

    const dispatch = useAppDispatch();

    useEffect(()=> {
        if(success){
            const timeout = setTimeout(handleResetSuccess,5000);
            return ()=>{clearTimeout(timeout)}
        }
    },[success])

    useEffect(() => {
        return () => {
            handleResetSuccess()
        }
    }, [])

    const handleResetSuccess = () => {
        dispatch(resetWaitingSuccess());
    }

    const handleWaitingList = () => {
        if (!validate(form)) {
            return
        }
        const waitingData: TourWaitingData = {
            email: form.email,
            tour_id: date.id
        }
        dispatch(fetchTourWaitingList(waitingData));
    }

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newForm = Object.assign({}, form);
        if (newForm[e.target.name] !== undefined) {
            newForm[e.target.name] = e.target.value;
        }
        setForm(newForm);
    }

    const validate = (form: any) => {
        const error: any = {
            email: false,
        }

        let result = true;
        if (!validateEmail(form.email)) {
            error.email = true;
            result = false;
        }
        setError(error)
        return result;
    }

    const current_language = useAppSelector(state => state.common.current_language);
    return (
        <ModalPortal addClass={"medium-modal"}>
            <ModalWrap closeModal={onClose}>
                <div className={"modal-header"}>
                    {label('Add to Waiting List')}
                </div>
                <div className={"modal-message "+(success?" show-message good":"")}>{label('You added to waiting list for this tour.')}</div>
                <div className={"tour_waiting_data"}>
                    <div className={"tour_waiting_header"}>
                        <div className={"tour_waiting_name"}>{info.name}</div>
                        <div className={"tour_waiting_dates"}>{prepareDate(date.start_ts, date.end_ts,current_language)}</div>
                        <div
                            className={"tour_waiting_text"}>{label('Please enter your Email address for adding to waiting list.')}</div>
                    </div>
                    <div className={"tour_waiting_form checkout"}>
                        <MainInput name={"email"} error={error.email} value={form.email} onChange={handleFormChange}
                                   placeHolder={label('Email') + " *"}/>
                    </div>
                    <div className={"tour_waiting_footer"}>
                        <div className={"btn btn-secondary"}
                             onClick={onClose}>{label('Close')}</div>
                        <div className={"btn btn-allow"}
                             onClick={handleWaitingList}>{label('Confirm')}</div>
                    </div>
                </div>
            </ModalWrap>
        </ModalPortal>
    )
}

export default TourWaitingModal;