import React, {FC} from "react";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

import {
    updateHotel,
    updateStage
} from "../../features/tours/tourBookSlice";
import {TourItemHotel} from "../../types/tourItem";
import {CheckBox} from "../common/checkbox";

export const TourBookCheckoutHotels = () => {
    const values = useAppSelector(state => state.tourBook.values);
    const extra = useAppSelector(state => state.tourBook.extra);
    const equip = useAppSelector(state => state.tourBook.equip);
    const hotels = useAppSelector(state => state.tourBook.hotels);
    const checkout_price = useAppSelector(state => state.common.params.checkout_price);
    const days_number = useAppSelector(state => state.tourBook.info.days_number);

    let needSize = false;
    equip.forEach(item => {
        // @ts-ignore
        if (values.accessories[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    const isExtra = equip.length > 0 || extra.length > 0;

    const dispatch = useAppDispatch();

    const handleNext = () => {
        dispatch(updateStage({stage: 4}));
    }

    const handleBack = () => {
        if (needSize) {
            dispatch(updateStage({stage: 2}))
        } else if (isExtra) {
            dispatch(updateStage({stage: 1}))
        } else {
            dispatch(updateStage({stage: 0}))
        }
    }

    const handleHotel = (id: number) => {
        dispatch(updateHotel({id}))
    }

    return (
        <div className={"checkout"}>
            <div className={"selected-block"}>
                <div className="main-data">
                    <CheckoutHotels hotels={hotels} rules={values.hotels}
                                    checkoutPrice = {checkout_price}
                                    duration = {days_number}
                                    onChange={handleHotel} person={0}/>
                </div>
            </div>
            <div className={"booking-block two"}>
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn  " + " btn-secondary"}
                     onClick={handleNext}
                >{label('Next')}</div>
            </div>
        </div>
    )
}

interface hotelsProps {
    onChange: (id: number) => void
    hotels: TourItemHotel[]
    rules: any[],
    person: number
    checkoutPrice: string
    duration: number
}

export const CheckoutHotels: FC<hotelsProps> = ({onChange, hotels, rules, person,checkoutPrice,duration}) => {
    let view_hotels = [] as TourItemHotel[];
    hotels.forEach((item: TourItemHotel) => {
        if (+item.person === 0 || person === item.person) {
            view_hotels.push(item)
        }
    });

    if (!view_hotels.length) {
        return null;
    }

    const getHotelsPrice = (item: TourItemHotel) => {
        let formatString = "";
        if (+(item.price) > 0) {
            switch (+item.one_time) {
                case 1: /// for period
                    formatString = `€${Math.round(item.price * 100) / 100}`;
                    break;
                case 0: /// per day
                default:
                    switch (checkoutPrice) {
                        case 'total':
                            formatString = `€${Math.round(item.price * duration * 100) / 100}`;
                            break;
                        case 'day':
                        default:
                            formatString = `€${item.price} / ${label('Day')}`;
                    }
                    break;
            }

        } else {
            formatString = `${label('Free')}`;
        }

        return formatString;
    }

    return (
        <div className="accessor">
            <div className="heading">
                {label('Hotel Rooms')}
            </div>
            <div className="checkout_hotels rental_values radio_values">
                {
                    view_hotels.map((item) => {
                        let checked = rules.find((rule: { id: number, status: number }) => +rule.id === item.id)
                        return (
                            <CheckBox key={item.id}
                                      onChange={() => {
                                          onChange(item.id)
                                      }}
                                      label={item.name}
                                      name={item.name}
                                      price={getHotelsPrice(item)}
                                      checked={!!checked}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}