import React, {FC} from 'react';
import {label} from "../../service";
import {PaymentMethod} from "../../types";
import {CheckBox} from "../common/checkbox";

interface paymentProps{
    method: string
    methodChange: (method:string) => void
    payment_methods:PaymentMethod[]
}
export const PaymentMethodCheck: FC<paymentProps> = ({ method, methodChange, payment_methods}) => {
        return (
            <div className=" accessor payment">
                <div className={"heading"}>{label('Payment method')}</div>
                <div className="total-checked">
                    <div className={"rental_values radio_values"}>
                        {
                            payment_methods.map((item) => {
                                const handleChange = () => {
                                    methodChange(item.id)
                                }
                                return (
                                    <CheckBox key={item.id}
                                              onChange={handleChange}
                                              label={item.name}
                                              name={''}
                                              price={item.name}
                                              checked = {method === item.id}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    };

