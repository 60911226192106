import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {OrdersState} from "../../types/orders";

export const fetchOrdersList = createAsyncThunk(
    'fetchOrdersList',
    async (thunkAPI)  => {
        return await api.getOrdersList() as OrdersState;

    }
)


const initialState:OrdersState ={
    list:[],
    load:false
};

export const ordersSlice = createSlice({
    name:"orders",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrdersList.fulfilled,(state,action)=>{
            state.list = action.payload.list;
            state.load = true;
        })
    }
})

export const {} = ordersSlice.actions;
export const ordersReducer = ordersSlice.reducer;