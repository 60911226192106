import React, {Fragment} from 'react';
import {useAppSelector} from "../../app/hooks";
import {TourItemDate} from "../../types/tourItem";
import moment from "moment";
import {label} from "../../service";
import {useNavigate} from "react-router";
import {TourInfoMain} from "./TourInfoMain";
import {prepareDate} from "../../utils/tourInfo";
import {Link} from "react-router-dom";

export const TourInfoHeader = () => {
    const info = useAppSelector(state => state.toursItem.info);
    const images = useAppSelector(state => state.toursItem.images);

    if(!info.name){
        return null;
    }

    return (
        <section className={"tour_info_header"}
                 style={{
                     backgroundImage: `url("${images.main}")`,
                     backgroundPosition: "center",
                     backgroundSize: "cover"
                 }}
        >
            <div className={"tour_info_header_gradient"}>
                <div className={"tour_info_caption"}>
                    <h1 className="animated fadeInDown title">{info.name}</h1>
                </div>
            </div>
            <TourInfoMain />
            <TourInfoDates />
        </section>
    )
}


const TourInfoDates = () => {
    const info = useAppSelector(state => state.toursItem.info);
    const dates = useAppSelector(state => state.toursItem.dates);
    const current_language = useAppSelector(state => state.common.current_language);
    if (!Object.keys(dates).length) {
        return null;
    }

    // const navigate = useNavigate();
    // const handleBookDate = (dateId:number) => {
    //     navigate('/tours/'+info.id+'/book?tour_id='+dateId);
    // }

    return <div className={"tour_info_dates animated " +(current_language === 'he' ? "fadeInLeft" : "fadeInRight" )}>
        <div className={"tour_info_dates_wrap "}>
            {
                Object.keys(dates).map((i) => {
                    // @ts-ignore
                    const date: TourItemDate = dates[i];
                    // const handleData = () => {
                    //     if(date.left_bikes > 0){
                    //         handleBookDate(date.id)
                    //     }
                    // }
                    return (
                        <Fragment key={date.id}>
                            {+date.left_bikes > 0 ?
                                <Link className={"tour_info_date  clickable " + (+i === 0 ? 'active_date' : '')}
                                    to={'/tours/'+info.id+'/book?tour_id='+date.id}
                                     // onClick={handleData}
                                >
                                    <small>{+i === 0 ? label('Next Tour Date') : label('Other Tour Date')}</small>
                                    <span>{prepareDate(date.start_ts,date.end_ts,current_language)}</span>
                                </Link>
                                :
                                <div className={"tour_info_date   " + (+i === 0 ? 'active_date' : '')}>
                                    <small>{+i === 0 ? label('Next Tour Date') : label('Other Tour Date')}</small>
                                    <span>{prepareDate(date.start_ts,date.end_ts,current_language)}</span>
                                    <div className={"sold-out"}>{label('Sold Out')}</div>
                                </div>
                            }
                        </Fragment>
                    )
                })
            }
        </div>
    </div>
}