import React, {ChangeEvent,MouseEvent, FC} from 'react';
import "./checkbox.scss"
import {label as labelService} from "../../../service";
import {Equipments} from "../../../types";
interface CheckBoxInterface{

    onChange:()=>void,
    changeCount:(count:number)=>void,
    label:string
    name:string
    price:string
    checked:boolean
    count:number,
    item:Equipments
}

export const CheckBoxCountable:FC<CheckBoxInterface>=
    ({ label,onChange,changeCount,name,price,checked,count,item}) => {

    const changeSelect = (e:ChangeEvent<HTMLSelectElement>) => {
        changeCount(Number(e.target.value));
    }

    const handleSelect = (e:MouseEvent<HTMLSelectElement>) => {
        e.stopPropagation();
    }

    const handleClick = (e:MouseEvent<HTMLDivElement>) =>{
        onChange();
    }

    let select = [] as number[];
    select.push(1);
    select.push(2);
    return (
        <div className="checkbox-wrapper-12"
             onClick={(e)=>{handleClick(e)}}
        >
            <div className="cbx">
                <input checked={checked} onChange={()=>{}} type="checkbox"/>
                <label  ></label>
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                </svg>
            </div>
            <div className="label">{name}</div>
            <div className="value">{price}</div>
            {checked && !!item.is_person && (!!item.count || true) &&
                <div className={"count "+(checked ? "count_active" :"")}>
                    <select
                        value={count}
                        name={'count'}
                        onChange={(e)=>{changeSelect(e)}}
                        onClick={(e)=>{handleSelect(e)}}
                    >
                        {
                            select.map((i) => {
                                return (
                                    <option key={i}
                                            value={i}>
                                        {i}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            }

            {checked && count>item.count &&
                <div className="count count_active for-request">{labelService('For request')}</div>
            }

        </div>
    );
};

