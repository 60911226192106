import React, {FC} from "react";
import {PackageTable} from "../../../types/packages";
import {PackageRulesTable} from "./PackageRulesTable";

interface tableProps{
    table:PackageTable[]
}
export const PackageRulesMultiTable:FC<tableProps> = ({table}) => {
    return (
        <div className={"packages_rules_item multitable_item"}>
            {table.map((t:PackageTable,j:number)=>{
                return <PackageRulesTable table={t} key={j} />
            })}
        </div>
    )
}