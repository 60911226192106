import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {api} from "../../service";
import {CertificateState, CertificateUser, Values} from "../../types/certificate";


export const fetchCertificate = createAsyncThunk(
    'fetchCertificate',
    async (thunkAPI)  => {
        return await api.getCertificate() as CertificateState;
    }
)

export const fetchCertificatePromo = createAsyncThunk(
    'fetchCertificatePromo',
    async (data:{values:Values}, thunkAPI) => {
        return await api.getCertificatePromo(data);
    }
)

export const fetchCertificateSave = createAsyncThunk(
    'fetchCertificateSave',
    async (data:{values:Values,user:CertificateUser}, thunkAPI) => {
        return await api.saveCertificateData(data);
    }
)

const initialState:CertificateState ={
    prices: [],
    images: [],
    rules: {
        expire:'',
        text:{
            header:'',
            items: []
        }
    },
    payment_methods: [],
    values: {
        image_id: 0,
        expire_ts: 0,
        price: 0,
        text: '',
        method: '',
        promo_code:"",
        canvas:"",
    },
    user: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        zip_code: '',
        address: '',
        city: '',
    },
    stage: 0,
    success:0,
    invoiceUrl: '',
};

export const certificateSlice = createSlice({
    name:"certificate",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        checkImage: (state, action: PayloadAction<{ image_id: number }>) => {
            let newValues = {...state.values};
            newValues.image_id = action.payload.image_id;
            return {...state, values: newValues};
        },
        checkPrice: (state, action: PayloadAction<{ price: number }>) => {
            let newValues = {...state.values};
            newValues.price = action.payload.price;
            return {...state, values: newValues};
        },
        checkMethod: (state, action: PayloadAction<{ method: string }>) => {
            let newValues = {...state.values};
            newValues.method = action.payload.method;
            return {...state, values: newValues};
        },
        updateText: (state, action: PayloadAction<{ text: string }>) => {
            let newValues = {...state.values};
            newValues.text = action.payload.text;
            return {...state, values: newValues};
        },
        updateCanvas: (state, action: PayloadAction<{ canvas: string }>) => {
            let newValues = {...state.values};
            newValues.canvas = action.payload.canvas;
            return {...state, values: newValues};
        },
        updateCertificateStage: (state, action: PayloadAction<{ stage: number }>) => {
            return {...state, stage: action.payload.stage};
        },
        updateUser: (state, action: PayloadAction<{ name:string,value:any }>) => {
            let newUser = {...state.user};
            // @ts-ignore
            if(newUser[action.payload.name]!==undefined){
                // @ts-ignore
                newUser[action.payload.name] = ''+action.payload.value;
            }
            return {...state, user: newUser};
        },
        processSuccess: (state) => {
            return {...state, success: 1};
        },
        resetSuccess: (state) => {
            return {...state, success: 0};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCertificate.fulfilled,(state,action)=>{
            state.prices =  action.payload.prices;
            state.images =  action.payload.images;
            state.rules =  action.payload.rules;
            state.payment_methods =  action.payload.payment_methods;
            state.values =  action.payload.values;
            state.user =  action.payload.user;
        }).addCase(fetchCertificatePromo.fulfilled, (state, action) => {
            // @ts-ignore
            state.values.promo_code = action.payload;
        }).addCase(fetchCertificatePromo.rejected, (state, action) => {
            // @ts-ignore
            state.success = -1;
        }).addCase(fetchCertificateSave.fulfilled,(state,action)=>{
            // @ts-ignore
            if(action.payload.status == 'ok'){
                state.success = 2;
                // @ts-ignore
                state.invoiceUrl = action.payload.invoiceUrl;
            }else{
                state.success = -1;
            }
            // @ts-ignore
            state.values =  action.payload.values;
            // @ts-ignore
            state.user =  action.payload.user;
        }).addCase(fetchCertificateSave.rejected, (state, action) => {
            // @ts-ignore
            state.success = -1;
        })
    }
})

export const {
    updateCertificateStage,
    checkImage,
    checkPrice,
    updateUser,
    checkMethod,
    updateText,
    processSuccess,
    resetSuccess,
    updateCanvas
} = certificateSlice.actions;
export const certificateReducer = certificateSlice.reducer;