import React, {useEffect} from 'react';
import "./home.scss";
import {HomeBikeInfo, HomeMainInfo, HomeTop} from "../components/home";
import {useScrollTop} from "../hooks";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {fetchHomeData} from "../features/common/commonSlice";

export const Home = () => {

    const popular = useAppSelector((state:RootState)=>{
        return state.common.popular;
    })

    useScrollTop();

    const dispatch = useAppDispatch();
    useEffect(()=>{
        dispatch(fetchHomeData());
    },[])

    return (
        <main className={"page "}>
            <div className={"home"}>
                <HomeTop />
                <HomeMainInfo />
                {popular && <HomeBikeInfo item={popular} />}
            </div>
        </main>
    );
};

