import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import ReadTerms from "../bike/ReadTerms";
import {checkTerms} from "../../features/packages/packagesSlice";
import {UserError} from "../../types/packages";

interface termsProps{
    error:UserError
}
export const CheckoutPackageTerms:FC<termsProps> = ({error}) => {
    const values = useAppSelector(state => state.packages.values);
    const agreeTerms = useAppSelector(state => state.packages.agreeTerms);

    const dispatch = useAppDispatch();

    const handleAgreeTerms = () => {
        dispatch(checkTerms({agreeTerms:!agreeTerms}))
    }

    let disabled = +values.package_id === 0;
    return (
        <div className={"main-data "+(error.terms?" error-data ":"")}>
            {disabled && <div className={"disabled-data "} /> }
            <ReadTerms onChange={handleAgreeTerms} checked={agreeTerms}/>
        </div>
    )
}