import React from "react";
import {label} from "../../service";
import {useAppSelector} from "../../app/hooks";

export const CheckoutPackageCalc = () => {
    const values = useAppSelector(state => state.packages.values);
    const packages = useAppSelector(state => state.packages.packages);

    const packageItem = packages.find(i=> +i.id === +values.package_id);
    let total = 0;
    let countDays = 0;
    let bikeName = '';
    if(packageItem){
        total = +packageItem.price;
        countDays = +packageItem.count_days;
        bikeName = packageItem.bike_name;
    }

    return (
        <div className="order-calc">
            <div className={"heading"}>{label('Total')}</div>
            <div className="total-checked">
                <div className="total-checked-rent">
                        <div className="total-checked-rent_item">
                            <div className="total-checked-rent_item_name">{label('Motorcycle')}</div>
                            <div className="total-checked-rent_item_date">{bikeName}</div>
                        </div>
                </div>
                <div className="total-checked-rent">
                    <div className="total-checked-rent_item">
                        <div className="total-checked-rent_item_name">{label('Count Days')}</div>
                        <div className="total-checked-rent_item_date">{countDays}</div>
                    </div>
                </div>
            </div>
            <div className="total-amount">
                <div className="total-amount_name">{label('Total price')}</div>
                <div className="total-amount_value">{total}€</div>
            </div>
        </div>
    );
}