import React, {useEffect} from "react";
import {TourBookCheckout, TourBookHeader, TourInfoHeader, TourInfoRules} from "../components/tours";
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useScrollTop} from "../hooks";
import {useLocation} from "react-router-dom";
import {fetchTourBook, resetTourBook} from "../features/tours/tourBookSlice";
import {PaymentMethod} from "../types";

export const TourItemBook = () => {
    let {id} = useParams();

    const dispatch = useAppDispatch();
    const location = useLocation();

    const rules = useAppSelector(state => state.tourBook.rules);


    useScrollTop();

    let search = {};
    const searchParams = new URLSearchParams(location.search);
    // @ts-ignore
    for (const [key, value] of searchParams.entries()) {
        // @ts-ignore
        search[key] = value;
    }

    useEffect(() => {
        dispatch(fetchTourBook({id: Number(id), search}));
        return () => {
            dispatch(resetTourBook())
        }
    }, []);

    return (
        <main className="main page tour_book w-full">
            <div className="w-wrap">
                <TourBookHeader/>
                <TourInfoRules
                    rules={rules}
                />
                <TourBookCheckout/>
            </div>
        </main>
    )
}