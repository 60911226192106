import React, {useState} from "react";
import {label} from "../../service";
import {useAppSelector} from "../../app/hooks";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

export const HomeTop = () => {
    const [leftHover,setLeftHover] = useState('');
    const contacts = useAppSelector(state => state.common.contacts);

    // const navigate = useNavigate();
    // const handleTours = () => {
    //     navigate('/tours')
    // }
    // const handleBikes = () => {
    //     navigate('/our-motorcycles')
    // }

    const mouseLeftBtnEnter = () => {
        setLeftHover('hover');
    }
    const mouseLeftBtnLeave = () => {
        setLeftHover('');
    }

    const mobile = window._common.mobile;

    if (mobile) {
        return <section className={"home_top w-full"}>
            <div className="home_top_wrapper w-wrap">
                <div className="home_top_title">
                    <article>
                        <h1>{label('Welcome to') + ' ' + contacts.siteName}</h1>
                        <h2>{label('Best Moto Tours and Motorcycle Rentals')}</h2>
                    </article>
                </div>
                <div className="home_top_item home_top_item_tour">
                    <div className="home_top_item_row">
                        <div className="home_top_buttons_title">{label('Go on tour')}</div>
                        <Link className="btn_more"
                              to={'/tours'}
                             // onClick={handleTours}
                        >{label('Details')}
                        </Link>
                    </div>
                </div>
                <div className="home_top_item home_top_item_bike">
                    <div className="home_top_item_row">
                        <div className="home_top_buttons_title">{label('Rent a Motorcycle')}</div>
                        <Link className="btn_more"
                              to={'/our-motorcycles'}
                              // onClick={handleBikes}
                        >{label('Details')}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    } else {
        return (
            <section className={"home_top w-full"}>
                <div className={"home_top_left "+leftHover} onMouseOver={mouseLeftBtnEnter} onMouseLeave={mouseLeftBtnLeave} />
                <div className="home_top_right" />
                <div className="home_top_center">
                    <div className="home_top_wrapper w-wrap">
                        <div className="home_top_title_wrap">
                            <div className="home_top_title">
                                <article>
                                    <h1>{label('Welcome to') + ' ' + contacts.siteName}</h1>
                                    <h2>{label('Best Moto Tours and Motorcycle Rentals')}</h2>
                                </article>
                            </div>
                        </div>
                        <div className="home_top_buttons_wrap">
                            <div className="home_top_buttons_item align_start">
                                <div className="home_top_buttons_title">{label('Go on tour')}</div>
                                <Link className="btn_more"
                                      to={'/tours'}
                                      // onClick={handleTours}
                                      onMouseOver={mouseLeftBtnEnter}
                                      onMouseLeave={mouseLeftBtnLeave}
                                >{label('Details')}
                                </Link>
                            </div>
                            <div className="home_top_buttons_item align_end">
                                <div className="home_top_buttons_title">{label('Rent a Motorcycle')}</div>
                                <Link className="btn_more"
                                      to={'/our-motorcycles'}
                                    //  onClick={handleBikes}
                                >{label('Details')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}