import React from "react";
import {useAppSelector} from "../../app/hooks";
import {GalleryAlbum} from "../../types/gallery";
import GalleryAlbumCard from "./GalleryAlbumCard";

const GalleryAlbums = () => {

    const albums = useAppSelector(state => state.gallery.albums);

    return (
        <section className="gallery_albums">
            {albums.map((album:GalleryAlbum)=>{
                    return <GalleryAlbumCard
                        key={album.album_id}
                        album={album}
                    />
            })}
        </section>
    )
}

export default GalleryAlbums;