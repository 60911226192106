import React, {FC, useEffect, useState} from "react";
import {label} from "../../service";
import {Accessories} from "../bike/Accessories";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {ExtraItem, Extras} from "../../types";

import {
    updateAccessories,
    updateAccessoriesCount,
    updateExtra,
    updateInsurance,
    updateStage
} from "../../features/tours/tourBookSlice";
import {CheckoutInsurance} from "./CheckoutInsurance";
import ItemsArea from "../common/ItemsArea";
import {getCurrent} from "../../utils/itemsArea";
import {CheckBox} from "../common/checkbox";

export const TourBookCheckoutExtras = () => {
    const values = useAppSelector(state => state.tourBook.values);
    const extra = useAppSelector(state => state.tourBook.extra);
    const equip = useAppSelector(state => state.tourBook.equip);
    const hotels = useAppSelector(state => state.tourBook.hotels);
    const insurances = useAppSelector(state => state.tourBook.insurances);
    const checkout_price = useAppSelector(state => state.common.params.checkout_price);
    const days_number = useAppSelector(state => state.tourBook.info.days_number);

    let needSize = false;
    equip.forEach(item => {
        // @ts-ignore
        if (values.accessories[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    let needHotels = hotels.length > 0;

    const dispatch = useAppDispatch();

    const handleNext = () => {
        if (needSize) {
            dispatch(updateStage({stage: 2}));
        } else {
            dispatch(updateStage({stage: 4}));
        }
        /*if (needHotels) {
            dispatch(updateStage({stage: 3}));
        }*/
    }

    const handleBack = () => {
        dispatch(updateStage({stage: 0}));
    }

    const handleExtra = (id: number) => {
        dispatch(updateExtra({id}))
    }

    const handleAccessory = (id:number,count:number) => {
        dispatch(updateAccessories({id,count}))
    }

    const handleAccessoryCount = (id:number,count:number) => {
        dispatch(updateAccessoriesCount({id,count}))
    }

    const handleInsurance = (id: number) => {
        dispatch(updateInsurance({id}))
    }

    const [current, setCurrent] = useState("");

    useEffect(() => {
        let newCurrent = getCurrent([
                {type: 'equip', items: equip},
                {type: 'insurance', items: insurances},
            ]
            , current);

        if(current!==newCurrent){
            setCurrent(newCurrent);
        }
    }, [equip, extra, insurances]);

    const handleType = (type: string) => {
        setCurrent(type);
    }

    return (
        <div className={"checkout"}>
            <div className={"selected-block"}>
                {equip.length > 0 && <div className="main-data">
                    <ItemsArea
                        label={label('Accessories')}
                        type={'equip'}
                        canOpen = {true}
                        opened={current === "equip"}
                        changeType={handleType}
                        lengthItems = {equip.length}
                        children={
                            <Accessories equip={equip} accessor={values.accessories} onAccessoryChange={handleAccessory}
                                         checkoutPrice = {checkout_price}
                                         duration = {days_number}
                                         onAccessoryChangeCount={handleAccessoryCount}/>
                        }
                    />
                </div>}
                {insurances.length>0 && <div className="main-data">
                    <ItemsArea
                        label={label('Insurances')}
                        type={'insurance'}
                        canOpen = {true}
                        opened={current === "insurance"}
                        rentalClass={" radio_values checkout_insurances "}
                        changeType={handleType}
                        lengthItems = {insurances.length}
                        children={
                            <CheckoutInsurance insurances={insurances} rules={values.insurances}
                                               checkoutPrice = {checkout_price}
                                               duration = {days_number}
                                               onChange={handleInsurance}/>
                        }
                    />
                </div>}
            </div>
            <div className={"booking-block two"}>
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn  " +  " btn-secondary"}
                     onClick={handleNext}
                >{ label('Next')}</div>
            </div>
        </div>
    )
}

interface ExtraProps {
    onChange: (id: number) => void
    extra: ExtraItem[]
    extras: number[]
}

export const CheckoutExtra: FC<ExtraProps> = ({onChange, extra, extras}) => {
    return (
        <div className="accessor">
            <div className="heading">
                {label('Extra')}
            </div>
            <div className="rental_values radio_values">
                {
                    extra.map((item) => {
                        let priceName = item.price + ' ' + 'EUR';
                        return (
                            <CheckBox key={item.id}
                                      onChange={() => {
                                          onChange(item.id)
                                      }}
                                      label={item.name}
                                      name={item.name}
                                      price={priceName}
                                      checked={extras.includes(item.id)}
                            />
                        )
                    })
                }
            </div>

        </div>
    )
}