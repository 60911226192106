import React, {useEffect} from "react";
import {label} from "../service";
import {ContactsData, ContactsForm, ContactsMap} from "../components/contacts";
import {useAppDispatch} from "../app/hooks";
import {fetchContactsList} from "../features/contacts/contactsSlice";
import "./contacts.scss";
import {useScrollTop} from "../hooks";

export const Contacts = () => {

    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(fetchContactsList());
    },[])

    useScrollTop();

    return (
        <main className={"page"}>
            <div className={"contacts w-full"}>
                <div className={"contacts_wrap w-wrap"}>
                    <div className="page-header">
                        <h1 className={"title"}>{label('Contacts')}</h1>
                    </div>
                    <div className={"contacts_area"}>
                        <ContactsData/>
                        <ContactsForm />
                    </div>
                    <ContactsMap />
                </div>
            </div>
        </main>
    )
}

export default Contacts;