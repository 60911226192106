import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {api} from "../../service";
import {PrivacyState} from "../../types/privacy";


export const fetchPrivacy = createAsyncThunk(
    'fetchPrivacy',
    async (thunkAPI)  => {
        return await api.getPrivacy() as PrivacyState;
    }
)

const initialState:PrivacyState ={
    info: '',
    load :false
};

export const privacySlice = createSlice({
    name:"privacy",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchPrivacy.fulfilled,(state,action)=>{
            state.info =  action.payload.info;
            state.load =  true;
        })
    }
})

export const {} = privacySlice.actions;
export const privacyReducer = privacySlice.reducer;