import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {GalleryViewerState} from "../../types/galleryViewer";

const initialState:GalleryViewerState = {
    show: false,
    images: [],
    current:0
}

export const galleryViewerSlice = createSlice({
    name:"galleryViewer",
    initialState,
    reducers: {
        showGallery:(state,action:PayloadAction<any>)=>{
            return {show:true,images:action.payload.images,current:action.payload.current}
        },
        hideGallery:()=>{
            return {show:false,images:[],current:0}
        },
    },
    extraReducers: (builder) => {

    }
})

export const {showGallery,hideGallery } = galleryViewerSlice.actions;
export const galleryViewerReducer = galleryViewerSlice.reducer;