declare global {
    interface Window {
        _label?: any;
    }
}
class Label {
    constructor(private label:any) {
    }

    trans(key:string):string{
        if(!this.label){
            return key;
        }

        if(!this.label[key]){
            console.log( key,this.label[key] );
        }

        return this.label[key] || key;
    }

}
const service = new Label(window._label)
export const label = (key:string)=>{
    return service.trans(key);
}