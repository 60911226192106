import React from "react";
import {label} from "../../service";
import {AccessoriesSizes} from "../bike/AccessoriesSizes";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {updateAccessoriesSize, updateStage} from "../../features/tours/tourBookSlice";

export const TourBookCheckoutSizes = () => {
    const values = useAppSelector(state => state.tourBook.values);
    const equip = useAppSelector(state => state.tourBook.equip);
    const hotels = useAppSelector(state => state.tourBook.hotels);

    let needSize = false;
    equip.forEach(item => {
        if (values.accessories[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    let needHotels = hotels.length > 0;

    const dispatch = useAppDispatch();

    const handleBack = () => {
        dispatch(updateStage({stage: 1}));
    }

    const handleNext = () => {
        /*if(needHotels){
            dispatch(updateStage({stage: 3}));
        }*/
        dispatch(updateStage({stage: 4}));

    }

    const handleAccessorySize = (id:number,number:number,size:string) => {
        dispatch(updateAccessoriesSize({id,number,size}))
    }

    return (
        <div className={"checkout"}>
            <div className="selected-block">
                <div className="main-data">
                    {<AccessoriesSizes
                        equip = {equip}
                        accessor = {values.accessories}
                        onAccessoryChangeSize = {handleAccessorySize}
                    />}
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-secondary"}
                     onClick={handleNext}>{label('Next')}</div>
            </div>
        </div>
    )
}