import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useScrollTop} from "../hooks";
import {label} from "../service";
import {fetchPrivacy} from "../features/privacy/privacySlice";
import "./privacy.scss";

export const Privacy = () => {

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchPrivacy());
    }, []);

    const privacy = useAppSelector(state=>state.privacy);

    // console.log('f',focus);
    return (
        <main className={"privacy page w-full"}>
            <div className={"privacy_wrap ql-editor w-wrap"}>
                <h1 className="title">{label('Privacy Policy')}</h1>
                <div className={"privacy_text "} dangerouslySetInnerHTML={{__html:privacy.info}}/>
            </div>
        </main>
    )
}