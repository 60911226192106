import React, {useEffect, useState} from "react";
import {useScrollTop} from "../hooks";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {label} from "../service";
import {fetchPackages, resetSuccess, updateUser} from "../features/packages/packagesSlice";
import "./packages.scss";
import {PackagesCheckout, PackagesList, PackagesRules} from "../components/packages";
import {PaymentMethod} from "../types/packages";
import {useLocation} from "react-router-dom";
import {OrderModal} from "../components/common/OrderModal";

export const Packages = () => {
    const values = useAppSelector(state => state.packages.values);
    const success = useAppSelector(state => state.packages.success);
    const invoiceUrl = useAppSelector(state => state.packages.invoiceUrl);
    const profile = useAppSelector(state=>state.login.profile);
    const load = useAppSelector(state=>state.packages.load);
    const payment_methods = useAppSelector(state => state.packages.payment_methods);

    const [paid, setPaid] = useState<boolean>(false);

    const location = useLocation();

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchPackages());
        return () => {
            dispatch(resetSuccess());
        }
    }, []);

    const method = payment_methods.find((method: PaymentMethod) => method.id === values.method);
    useEffect(() => {
        if (+success === 2 && invoiceUrl !== '' && values.method) {
            let target = "_self";
            if (method && method.target) {
                target = method.target;
            }
            // 👇 Open link in new tab programmatically
            window.open(invoiceUrl, target, 'noreferrer');
        }
    }, [success, invoiceUrl]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('order')) {
            setPaid(true);
        }
    }, [location.search]);

    useEffect(() => {
        if (+profile.user_id > 0 && load) {
            dispatch(updateUser({name:'name',value:profile.name}));
            dispatch(updateUser({name:'email',value:profile.mail}));
            dispatch(updateUser({name:'phone',value:profile.phone}));
            dispatch(updateUser({name:'birthdate',value:profile.birthdate}));
            dispatch(updateUser({name:'zipcode',value:profile.zipcode}));
            dispatch(updateUser({name:'country',value:profile.country}));
            dispatch(updateUser({name:'country_id',value:profile.country_id}));
            dispatch(updateUser({name:'city',value:profile.city}));
            dispatch(updateUser({name:'street',value:profile.street}));
            dispatch(updateUser({name:'is_firm',value:profile.is_firm}));
            dispatch(updateUser({name:'firm_name',value:profile.firm_name}));
            dispatch(updateUser({name:'ICO',value:profile.ICO}));
            dispatch(updateUser({name:'DIC',value:profile.DIC}));
            dispatch(updateUser({name:'IC_DPH',value:profile.IC_DPH}));
        }
    }, [profile,load]);

    const closePaid = () => {
        setPaid(false);
    }

    const getSuccess = () => {
        switch(+success){
            case -1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Something wrong.')}</h1>
                        <span>{label('Please try again later.')}</span>
                        <div className={"btn btn-secondary"} onClick={resetErrorLayer}>OK</div>
                    </div>
                )
            case 1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order in progress.')}</h1>
                        <span className="loader"/>
                    </div>
                )
            case 2:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order successfully accepted.')}</h1>
                        <span>{label('We will contact you shortly..')}</span>
                    </div>
                )
        }
    }

    const resetErrorLayer = () => {
        dispatch(resetSuccess());
    }

    return (
        <main className={"packages page w-full"}>
            <div className={"packages_wrap w-wrap"}>
                <div className={"page-header"}>
                    <h1 className={"title"}>{label('Packages')}</h1>
                </div>
                <div className={"packages_area "}>
                    {false && <div className={"packages_area_header "}>{label('Choose Package')}</div>}
                    {load && <div className={"packages_choose " + (success ? " success_now ":"")}>
                        {paid && <OrderModal
                            onClose={closePaid}
                        />}
                        <div className={"packages_checkout_layer " + (!success ? "disable_layer" : "")}>
                            {getSuccess()}
                        </div>
                        <PackagesList/>
                        {+values.package_id > 0 &&  <PackagesCheckout/>}
                    </div>}
                    {load && <PackagesRules />}
                </div>
            </div>
        </main>
    )
}