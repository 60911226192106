import React, {useEffect, useState} from "react";
import ItemsArea from "../common/ItemsArea";
import {label} from "../../service";
import {Accessories} from "../bike/Accessories";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Extra} from "../bike/Extra";
import {getCurrent} from "../../utils/itemsArea";
import {
    updateAccessories,
    updateAccessoriesCount, updateExtra,
    updateStage
} from "../../features/packageBikeDetails/packageBikeDetailsSlice";
import {ExtraExternal} from "../bike";

export const PackageOrderCheckoutEquip = () => {
    const values = useAppSelector(state => state.packageBikeDetails.values);
    const extra = useAppSelector(state => state.packageBikeDetails.extra);
    const equip = useAppSelector(state => state.packageBikeDetails.equip);
    const checkout_price = useAppSelector(state => state.common.params.checkout_price);
    const {date} = values;

    const [current, setCurrent] = useState("");

    const dispatch = useAppDispatch();

    useEffect(() => {
        let newCurrent = getCurrent(
            [
                {type: 'equip', items: equip},
                {type: 'extra', items: extra},
            ]
            , current);

        if (current !== newCurrent) {
            setCurrent(newCurrent);
        }
    }, [equip, extra, extra]);

    const handleType = (type: string) => {
        setCurrent(type);
    }

    let needSize = false;
    equip.forEach(item => {
        if (values.accessor[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    const handleExtra = (id:number) => {
        dispatch(updateExtra({id}))
    }

    const handleAccessory = (id:number,count:number) => {
        dispatch(updateAccessories({id,count}))
    }

    const handleAccessoryCount = (id:number,count:number) => {
        dispatch(updateAccessoriesCount({id,count}))
    }

    const handleBack = () => {
        dispatch(updateStage({stage: 0}));
    }

    const handleNext = () => {
        if (needSize) {
            dispatch(updateStage({stage: 2}));
        } else {
            dispatch(updateStage({stage: 3}));
        }
    }

    return (
        <div className={"checkout"}>
            <div className={"selected-block"}>
                {equip.length > 0 && <div className="main-data">
                    <ItemsArea
                        label={label('Accessories')}
                        type={'equip'}
                        canOpen={true}
                        opened={current === "equip"}
                        changeType={handleType}
                        lengthItems={equip.length}
                        children={
                            <Accessories
                                // @ts-ignore
                                equip={equip} accessor={values.accessor} onAccessoryChange={handleAccessory}
                                         checkoutPrice={checkout_price}
                                         duration={date.duration}
                                         onAccessoryChangeCount={handleAccessoryCount}/>
                        }
                    />
                </div>}
                {extra.length > 0 && <div className="main-data">
                    <ItemsArea
                        label={label('Extra')}
                        type={'extra'}
                        canOpen={true}
                        opened={current === "extra"}
                        rentalClass={" radio_values checkout_extra "}
                        changeType={handleType}
                        lengthItems={extra.length}
                        children={
                            <ExtraExternal extra={extra} extras={values.extras} onChange={handleExtra}/>
                        }
                    />
                </div>}
            </div>
            <div className={"booking-block two"}>
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn  " + " btn-secondary"}
                     onClick={handleNext}
                >{label('Next')}</div>
            </div>
        </div>
    )
}