import React, {Fragment, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {clsx} from "clsx";
import {label} from "../../service";
import {IoIosMenu} from "react-icons/io";
import {useAppSelector} from "../../app/hooks";

const fixture = [
    {
        to:"/",
        name:"Home"
    },
    {
        to:"/our-motorcycles",
        name:"Rent a Motorcycle"
    }
    /*,{
        to:"/rent-motorcycle",
        name:"Rent a Motorcycle"
    }*/,
    {
        to:"/tours",
        name:"Tours"
    },
    {
        to:"/packages",
        name:"Packages",
        new:true
    },
/*    {
        to:"/routes",
        name:"Routes"
    },*/
/*    {
        to:"/photos",
        name:"Photos"
    },*/
/*    {
        to:"/gallery",
        name:"Gallery"
    },*/
    {
        to:"/certificate",
        name:"Gift Vouchers",
    },
/*    {
        to:"/contacts",
        name:"Contacts"
    },*/
];

export const Menu = () => {
    const current_language = useAppSelector(state => state.common.current_language);
    const [expand, setExpand] = useState<boolean | false>(false);
    const location = useLocation();
    const handleMenuToggle = () => {
        setExpand(!expand);
    }
    const handleMenuClose = () => {
        setExpand(false);
    }

    const getMenu = () => {
        let menu: ({ to: string; name: string; new?: undefined; } | { to: string; name: string; new: boolean; })[] = [];
        fixture.forEach(i=>{
            let need = true;
            if( i.name=== 'Packages' && current_language === 'he'){
                need = false;
            }
            if(need){
                menu.push(i);
            }
        })
        return menu;
    }
    return (
        <Fragment>
            <div className={"menu_icon"} onClick={handleMenuToggle}>
                <IoIosMenu/>
            </div>
            <nav className={"menu "+(expand?"expand":"")}>
                <ul>
                    {
                        getMenu().map((item,index)=>{
                            return <li className="menu-item" key={index}>
                                <Link className={clsx(
                                    item.to==location.pathname && 'active'
                                )} to={item.to}
                                onClick={handleMenuClose}>
                                    <span>
                                        {label(item.name)}
                                    </span>
                                    {item.new && <img className={"new-item"} alt={""} src={'/www/images/new-arrow.png'}/>}
                                </Link>
                            </li>
                        })
                    }
                </ul>
            </nav>
        </Fragment>
    );
};

