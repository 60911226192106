import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {fetchTourInfo, resetTourItem} from "../features/tours/toursItemSlice";
import {
    TourInfoDays,
    TourInfoDescription,
    TourInfoGallery,
    TourInfoHeader,
    TourInfoMain,
    TourInfoOverview, TourInfoRules
} from "../components/tours";
import {useScrollTop} from "../hooks";

export const TourItem = () => {
    let {id} = useParams();
    const rules = useAppSelector(state=>state.toursItem.rules);

    const dispatch = useAppDispatch();

    useScrollTop();

    useEffect(() => {
        dispatch(fetchTourInfo(Number(id)));
        return ()=>{
            dispatch(resetTourItem())
        }
    }, []);

    return (
        <main className="main page tour_info">
            <div className={"tour_info_wrap w-wrap"}>
                <TourInfoHeader />
                <div className={"tour_info_center"}>
                    <div className={"tour_info_center_left"}>
                        <TourInfoDescription />
                        <TourInfoRules rules = {rules}/>
                    </div>
                    <div className={"tour_info_center_right"}>
                        <TourInfoMain />
                    </div>
                </div>
                <TourInfoDays />
                <TourInfoOverview />
            </div>

        </main>
    );
};

