import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {api} from "../../service";
import {
    PackageBikeDetailsState, PackageBikeDetailsValues, PackageDate,
} from "../../types/packageBikeDetails";

export const fetchPackageBikeDetails = createAsyncThunk(
    'fetchPackageBikeDetails',
    async (data: { order_id: number, bike_id: number }, thunkAPI) => {
        return await api.getPackageBikeDetail(data);
    }
)

export const fetchPackageBikeAccessories = createAsyncThunk(
    'fetchPackageBikeAccessories',
    async ({model_id, start, end, optionStart, optionEnd}: {
        model_id: number,
        start: string, end: string,
        optionStart: string, optionEnd: string
    }) => {
        return await api.getAccessory(model_id, start, end, optionStart, optionEnd);
    }
)

export const sendPackagePickupOrder = createAsyncThunk(
    'sendPackagePickupOrder',
    async (data: PackageBikeDetailsValues, thunkAPI) => {
        return await api.sendPackagePickupOrder(data);
    }
)


const initialState: PackageBikeDetailsState = {
    bike_id: 0,
    order_id: 0,
    price: 0,
    count_days: 0,
    count_left: 0,
    min_days: 0,
    max_days: 0,
    bike_list: [],
    user: {
        user_id: 0,
        pass: '',
        name: '',
        surname: '',
        mail: '',
        phone: '',
        birthdate: '',
        zipcode: '',
        country: '',
        country_id: 0,
        city: '',
        street: '',
        is_firm: 0,
        firm_name: '',
        ICO: '',
        DIC: '',
        IC_DPH: '',
    },
    values: {
        parent_id: 0,
        bike_id: 0,
        amount: 0,
        accessor: [],
        extras: [],
        rules: [],
        overtime: [],
        date: {
            start: '',
            end: '',
            duration: 0,
            optionEnd: '',
            optionStart: ''
        },
        note: ""
    },
    avail: 0,
    deny_day: [],
    used_day: [],
    equip: [],
    extra: [],
    insurances: [],
    overtime: {
        pickup: {
            id: 0,
            price: 0,
            name: ''
        },
        drop_off: {
            id: 0,
            price: 0,
            name: ''
        }
    },
    schedule: [],
    status: 0,
    info: [],
    load: false,
    stage: 0,
    invoiceUrl: '',
};

export const packageBikeDetailsSlice = createSlice({
    name: "packageBikeDetails",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetState: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                load: false,
                status: 0,
                info: [],
                bike_list: [],
                values: {
                    parent_id: 0,
                    bike_id: 0,
                    amount: 0,
                    accessor: [],
                    extras: [],
                    rules: [],
                    overtime: [],
                    date: {
                        start: '',
                        end: '',
                        duration: 0,
                        optionEnd: '',
                        optionStart: ''
                    },
                    note: ""
                },
            };
        },
        updateStage: (state, action: PayloadAction<{ stage: number }>) => {
            return {...state, stage: action.payload.stage};
        },
        updateDate: (state, action: { payload: PackageDate }) => {
            const new_values = {...state.values};
            new_values.date = action.payload;
            return {
                ...state,
                values: new_values
            };
        },
        updateOvertime: (state, action: PayloadAction<{ key: string }>) => {
            const new_values = {...state.values};
            let overtime = [...new_values.overtime];
            if (overtime.includes(action.payload.key)) {
                let new_overtime = [] as string[];
                overtime.forEach((i: string) => {
                    if (i !== action.payload.key) {
                        new_overtime.push(action.payload.key);
                    }
                });
                overtime = [...new_overtime];
            } else {
                overtime.push(action.payload.key);
            }
            new_values.overtime = overtime;
            return {
                ...state,
                values: new_values
            };
        },
        updateExtra: (state, action: PayloadAction<{ id: number }>) => {
            let extras = [];
            if (!state.values.extras.includes(action.payload.id)) {
                extras.push(action.payload.id)
            }
            const values = {...state.values, extras};
            return {...state, values};
        },
        updateAccessories: (state, action: PayloadAction<{ id: number, count: number }>) => {
            let accessor = Object.assign({}, state.values.accessor);
            if (accessor[action.payload.id] !== undefined) {
                delete (accessor[action.payload.id]);
            } else {
                accessor[action.payload.id] = {
                    count: 1,
                    sizes: {
                        1: !!action.payload.count ? 'XL' : '-1'
                    }
                };
            }
            const values = {...state.values, accessor};
            return {...state, values};
        },
        updateAccessoriesCount: (state, action: PayloadAction<{ id: number, count: number }>) => {
            if (state.values.accessor[action.payload.id] !== undefined) {
                const digit = {...state.values.accessor[action.payload.id]};
                digit.count = action.payload.count;
                digit.sizes = {};
                for (let i = 0; i < action.payload.count; i++) {
                    digit.sizes[i + 1] = 'XL';
                }

                const accessor = {...state.values.accessor, [action.payload.id]: digit};
                return {...state, values: {...state.values, accessor}};
            }
            return state
        },
        updateAccessoriesSize: (state, action: PayloadAction<{ id: number, number: number, size: string }>) => {
            if (state.values.accessor[action.payload.id] !== undefined && state.values.accessor[action.payload.id].sizes[action.payload.number] !== undefined) {
                const digit = {...state.values.accessor[action.payload.id]};
                const sizes = {...digit.sizes};
                sizes[action.payload.number] = action.payload.size;
                digit.sizes = sizes;
                const accessor = {...state.values.accessor, [action.payload.id]: digit};
                return {...state, values: {...state.values, accessor}};
            }
            return state
        },
        updateNote: (state, action: PayloadAction<{ note: string }>) => {
            const values = {...state.values, note: action.payload.note};
            return {...state, values};
        },
        resetStatus: (state) => {
            return {...state, status: 0};
        },
        processStatus: (state) => {
            return {...state, status: 1};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPackageBikeDetails.fulfilled, (state, action) => {
            // @ts-ignore
            if (action.payload.status !== undefined && action.payload.status === 'fail') {
                state.status = -1;
                // @ts-ignore
                state.info = action.payload.info || [];
            } else {
                console.log('ac', action.payload)
                // @ts-ignore
                state.bike_id = action.payload.bike_id;
                // @ts-ignore
                state.order_id = action.payload.order_id;
                // @ts-ignore
                state.price = action.payload.price;
                // @ts-ignore
                state.count_days = action.payload.count_days;
                // @ts-ignore
                state.count_left = action.payload.count_left;
                // @ts-ignore
                state.min_days = action.payload.min_days;
                // @ts-ignore
                state.max_days = action.payload.max_days;
                // @ts-ignore
                state.bike_list = action.payload.bike_list;
                // @ts-ignore
                state.user = action.payload.user;
                // @ts-ignore
                state.avail = action.payload.avail;
                // @ts-ignore
                state.deny_day = action.payload.deny_day;
                // @ts-ignore
                state.used_day = action.payload.used_day;
                // @ts-ignore
                state.equip = action.payload.equip;
                // @ts-ignore
                state.extra = action.payload.extra;
                // @ts-ignore
                state.insurances = action.payload.insurances;
                // @ts-ignore
                state.overtime = action.payload.overtime;
                // @ts-ignore
                state.schedule = action.payload.schedule;
                // @ts-ignore
                state.values = action.payload.values;
                state.status = 0;
                state.info = [];
            }
            state.load = true;
            state.stage = 0;
        }).addCase(fetchPackageBikeAccessories.fulfilled, (state, {payload}: any) => {
            // state.orderDate=payload.order_date;
            state.equip = payload.equip;
            state.insurances = payload.insurances;
        }).addCase(sendPackagePickupOrder.fulfilled, (state, {payload}: any) => {
            state.status = payload.answer === 'success' ? 2 : -1;
            state.invoiceUrl = payload.invoiceUrl;
            if (payload.info !== undefined) {
                state.info = payload.info;
            }
        }).addCase(sendPackagePickupOrder.rejected, (state, {payload}: any) => {
            state.status =  -1;
            if (payload.info !== undefined) {
                state.info = payload.info;
            }
        })
    }
})

export const {
    resetState,
    updateStage,
    updateDate,
    updateOvertime,
    updateExtra,
    updateAccessories,
    updateAccessoriesCount,
    updateAccessoriesSize,
    updateNote,
    resetStatus,
    processStatus
} = packageBikeDetailsSlice.actions;
export const packageBikeDetailsReducer = packageBikeDetailsSlice.reducer;