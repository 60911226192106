import React from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";

export const ContactsMap = () => {
    const contacts = useAppSelector(state => state.common.contacts);

    if (!contacts.googleCoords) {
        return null;
    }
    return (
        <div className={"contacts_map"}>
            <div className={"contacts_map_area"}>
                <div className={"contacts_subheader"}>{label('Come Visit Us')}</div>
                <div className={"contacts_map_area"}>
                    <iframe
                        src={`https://www.google.com/maps/embed?pb=${contacts.googleCoords}`}
                        width="600" height="450" style={{border: 0}} allowFullScreen={true} loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"/>
                </div>
            </div>
        </div>
    )
}