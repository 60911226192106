import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {checkMethod, checkPercent} from "../../features/packages/packagesSlice";
import {PaymentMethodCheck} from "../tools/PaymentMethod";
import {UserError} from "../../types/packages";

interface methodProps{
    error:UserError
}
export const CheckoutPackageMethod:FC<methodProps> = ({error}) => {
    const values = useAppSelector(state => state.packages.values);
    const packages = useAppSelector(state => state.packages.packages);
    const payment_methods = useAppSelector(state => state.packages.payment_methods);

    const packageItem = packages.find(i=> +i.id === +values.package_id);
    let total = 0;
    if(packageItem){
        total = +packageItem.price;
    }

    const dispatch = useAppDispatch();

    const handleChange = (method:string) => {
        dispatch(checkMethod({method}))
    }

    let disabled = +values.package_id === 0;
    return (
        <div className={"main-data "+(error.method?" error-data ":"")}>
            {disabled && <div className={"disabled-data "} /> }
            <PaymentMethodCheck method={values.method} methodChange={handleChange} payment_methods={payment_methods}/>
        </div>
    )
}