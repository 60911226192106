import React, {Dispatch, FC} from 'react';
import {clsx} from "clsx";
import {CalType} from "../../page/Bike";
import {label} from "../../service";

export const TypePanel:FC<{type:string|undefined,setType:Dispatch<React.SetStateAction<CalType>>}> = ({type,setType}) => {
    return (
        <div className={"btn-panel"}>
            <div
                onClick={()=>{setType('normal')}}
                className={
                    clsx("btn-more",type==="normal"?"active":"")}>{label("Regular")}</div>
            <div
                onClick={()=>{setType('half')}}
                className={
                    clsx("btn-more",type==="half"?"active":"")}>{label("Half-day")}</div>
            <div
                onClick={()=>{setType('weekend')}}
                className={
                    clsx("btn-more",type==="weekend"?"active":"")}>{label("Week-end")}</div>
        </div>
    );
};

