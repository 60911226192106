import React from "react";
import {label} from "../../service";
import {useAppSelector} from "../../app/hooks";

export const CertificateRules = () => {

    const rules = useAppSelector(state => state.certificate.rules);

    return (
        <div className={"certificate_rules"}>
            {rules.text.header && <div className={"certificate_rules_header"}>{rules.text.header}</div>}
            {rules.text.items.length > 0 &&  <ul className={"certificate_rules_items"}>
                {rules.expire !== '' && <div className={"certificate_rules_name certificate_rules_expires"}>{label('Gift Voucher Expires')+ ' '+rules.expire} </div>}
                {rules.text.items.map(item=>{
                    return (
                        <li className={"certificate_rules_item "}>
                            <div className={"certificate_rules_name "}>{item.name}</div>
                            <div className={"certificate_rules_text "}>{item.text}</div>
                        </li>
                    )
                })}
            </ul>}
        </div>
    )
}