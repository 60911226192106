import React from "react";
import {label} from "../../service";
import './NotFound.scss';

export const NotFound = () => {
    return (
        <main className = {"page not-found w-full"}>
            <div className={"w-wrap"}>
                <div className="page-header">
                    <h1 className="title">{label('Page not found')}</h1>
                </div>
                <article className="promo">
                    <p className="promo_subtitle">
                        {label("The page you are looking for can't be found")}
                    </p>
                </article>
                <a className="btn_more" href="/">Back to home</a>
            </div>
        </main>
    )
}