import React, {FC, useState} from "react";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {updateMethod, updatePercent, updateStage} from "../../features/tours/tourBookSlice";
import {Paymend} from "../tools/Paymend";
import {getTourBookTotal} from "../../utils/tourOrder";
import {PaymentMethodCheck} from "../tools/PaymentMethod";
import {TourItemRule} from "../../types/tourItem";
import ReadTerms from "../bike/ReadTerms";
import {CheckBox} from "../common/checkbox";

interface paymentCheckoutProps {
    bookTour: () => void
}

export const TourBookCheckoutPayment: FC<paymentCheckoutProps> = ({bookTour}) => {
    const [agreeRule, setAgree] = useState(false);
    const [agreeTerms, setTerms] = useState(false);

    const handleAgreeRule = () => {
        setAgree(!agreeRule);
    }

    const handleAgreeTerms = () => {
        setTerms(!agreeTerms);
    }

    const payment_percents = useAppSelector(state => state.tourBook.payment_percents);
    const payment_methods = useAppSelector(state => state.tourBook.payment_methods);
    const values = useAppSelector(state => state.tourBook.values);
    const hotels = useAppSelector(state => state.tourBook.hotels);
    const insurances = useAppSelector(state => state.tourBook.insurances);
    const equip = useAppSelector(state => state.tourBook.equip);
    const extra = useAppSelector(state => state.tourBook.extra);
    const rules = useAppSelector(state => state.tourBook.rules);
    const bikes = useAppSelector(state => state.tourBook.bikes);
    const info = useAppSelector(state => state.tourBook.info);
    const promo = useAppSelector(state => state.tourBook.promo);

    const required = rules.filter((item: TourItemRule) => +item.kind === 3);

    const total = getTourBookTotal(values, equip, extra, rules, bikes, hotels, insurances, info.days_number, promo);
    const dispatch = useAppDispatch();

    const handleBack = () => {
        dispatch(updateStage({stage: 4}));
    }
    const canBook = values.percent > 0 && values.method !== '' && (required.length === 0 || agreeRule) && agreeTerms;
    const handleBook = () => {
        if (canBook) {
            bookTour();
        }
    }

    const handlePercentChange = (percent: number) => {
        dispatch(updatePercent({percent}));
    }

    const handleMethodChange = (method: string) => {
        dispatch(updateMethod({method}));
    }

    return (
        <div className="checkout">
            <div className="selected-block one">
                {payment_percents.length > 0 && <div className="main-data">
                    <Paymend total={total} percent={values.percent} percentChange={handlePercentChange}
                             payment_percents={payment_percents}/>
                </div>}
                {payment_methods.length > 0 && <div className="main-data">
                    <PaymentMethodCheck method={values.method} methodChange={handleMethodChange}
                                        payment_methods={payment_methods}/>
                </div>}
                <div className="note-data">
                    {required.length > 0 && <CheckBox
                        onChange={handleAgreeRule}
                        name={label('I agree with the requirements for the tour')}
                        price={""}
                        checked={agreeRule}
                    />}
                    <ReadTerms onChange={handleAgreeTerms} checked={agreeTerms}/>
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-allow " + (!canBook ? "not-allowed opacity-25" : "")}
                     onClick={handleBook}>{label('Confirm')}</div>
            </div>
        </div>
    )
}