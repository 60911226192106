import React from "react";
import {CertificateCheckout} from "./CertificateCheckout";

export const CertificateCreate = () => {

    return (
        <div className={"certificate_create "}>
            <div className={"certificate_order "}>
                <CertificateCheckout/>
            </div>
        </div>
    )
}