import React, {ChangeEvent, FC, Fragment} from "react";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {TourItemDate} from "../../types/tourItem";
import moment from "moment";
import {
    updateBookBike,
    updateBookTour,
    updateHotel,
    updatePassenger,
    updateStage
} from "../../features/tours/tourBookSlice";

import {CheckoutHotels} from "./TourBookCheckoutHotels";
import {TourBookBike, TourBookBikes} from "../../types/tourBook";
import {CheckBox} from "../common/checkbox";

export const TourBookCheckoutBikes = () => {
    const values = useAppSelector(state => state.tourBook.values);
    const dates = useAppSelector(state => state.tourBook.dates);
    const equip = useAppSelector(state => state.tourBook.equip);
    const extra = useAppSelector(state => state.tourBook.extra);
    const bikes = useAppSelector(state => state.tourBook.bikes);
    const info = useAppSelector(state => state.tourBook.info);
    const hotels = useAppSelector(state => state.tourBook.hotels);
    const checkout_price = useAppSelector(state => state.common.params.checkout_price);
    const days_number = useAppSelector(state => state.tourBook.info.days_number);

    const dispatch = useAppDispatch();

    const disabled = +values.bike_id === 0 || +values.tour_id === 0;
    const isExtra = +values.bike_id>0 && (equip.length > 0 || extra.length > 0);

    const handleStage = () => {
        if (!disabled) {
            if (isExtra) {
                dispatch(updateStage({stage: 1}))
            } else {
                dispatch(updateStage({stage: 4}))
            }
        }
    }

    const handleHotel = (id: number) => {
        dispatch(updateHotel({id}))
    }

    if (!dates.length) {
        return null;
    }

    let canNext = false;
    const chosenDate = dates.find((date: TourItemDate) => date.id === +values.tour_id);
    if (chosenDate && chosenDate.left_bikes > 0) {
        canNext = true;
    }

    return (
        <div className={"checkout"}>
            <div className={"selected-block bikes-block"}>
                <div className="main-data">
                    <CheckoutDate
                        dates={dates}
                        tour_id={values.tour_id}
                    />
                    {bikes[values.tour_id] && <Fragment>
                        <CheckoutBikes
                            bikes={bikes}
                            bike_id={values.bike_id}
                            tour_id={values.tour_id}
                            checkoutPrice={checkout_price}
                            days_number={info.days_number}
                        />
                        <CheckoutPassenger
                            passenger={values.passenger}
                        />
                        <CheckoutHotels hotels={hotels} rules={values.hotels}
                                        checkoutPrice={checkout_price}
                                        duration={days_number}
                                        onChange={handleHotel} person={values.passenger + 1}/>
                    </Fragment>}
                    {!bikes[values.tour_id] && <div className="checkout_bikes radio_values">
                        <div className="sold_out_bikes btn btn-danger not-allowed opacity-75">{label('Sold Out')}</div>
                    </div>}
                </div>
            </div>
            {canNext && <div className={"booking-block"}>
                <div className={"btn   btn-secondary" + (disabled ? "disabled" : "")}
                     onClick={handleStage}
                >{label('Next')}</div>
            </div>}
        </div>
    )
}

interface checkoutDateProps {
    dates: TourItemDate[]
    tour_id: number
}

const CheckoutDate: FC<checkoutDateProps> = ({dates, tour_id}) => {
    const dispatch = useAppDispatch();

    const handleTour = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(updateBookTour({tour_id: +e.target.value}))
    }

    return (
        <div className="date-box">
            <div className="date-box-group">
                <label className="date-box-label">
                    <strong>{label('Select tour date')}</strong>
                </label>
                <div className="date-box-value">
                    <select
                        value={tour_id}
                        onChange={(e) => {
                            handleTour(e)
                        }}
                    >
                        {dates.map((date: TourItemDate) => {
                            let start_date = moment(date.start_ts * 1000).format('DD.MM.YYYY');
                            let end_date = moment(date.end_ts * 1000).format('DD.MM.YYYY');
                            return <option
                                key={date.id}
                                value={date.id}
                            >{start_date}&nbsp;-&nbsp;{end_date}
                            </option>
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

interface checkoutBikeProps {
    bikes: TourBookBikes
    days_number: number
    bike_id: number
    tour_id: number
    checkoutPrice: string
}

const CheckoutBikes: FC<checkoutBikeProps> = ({bikes, days_number, bike_id, tour_id, checkoutPrice}) => {
    const dispatch = useAppDispatch();

    let min_price = 1e20;
    if (bikes[tour_id] !== undefined) {
        bikes[tour_id].forEach((bike: TourBookBike) => {
            if (bike.tour_prices[tour_id] !== undefined) {
                const price = +bike.tour_prices[tour_id];
                min_price = Math.min(min_price, price);
            }
        });
    }

    const getDiffPrice = (diff: number) => {
        let formatString = "";
        switch (checkoutPrice) {
            case 'total':
                formatString = `+ €${Math.round(diff * days_number * 100) / 100}`;
                break;
            case 'day':
            default:
                formatString = `+ €${diff} / ${label('Day')}`;
        }
        return formatString;
    }

    return (
        <div className={"checkout_bikes"}>
            <div className="heading">
                {label('Motorcycle')}
            </div>
            <div className="checkout_bikes_rows">
                {bikes[tour_id].map((bike: TourBookBike) => {
                    const checked = +bike_id === +bike.id;
                    const handleBike = () => {
                        dispatch(updateBookBike({bike_id: +bike.id}))
                    }
                    let diff_price = 0;
                    if (bike.tour_prices[tour_id] !== undefined) {
                        const bike_price = +bike.tour_prices[tour_id];
                        diff_price = Math.round(bike_price - min_price);
                    }

                    return (
                        <div className={"checkout_bikes_row " + (checked ? "checked_bike" : "")}
                             key={bike.id}
                             onClick={handleBike}
                        >
                            <div className="checkout_bikes_row_name">{bike.alias}</div>
                            <div className="checkout_bikes_row_image ">
                                <img src={bike.img_small} alt={bike.alias}/>
                            </div>
                            {+diff_price !== 0 &&
                                <div className="checkout_bikes_row_count">{getDiffPrice(diff_price)}</div>}
                        </div>
                    )
                })}
            </div>
            <div className={"checkout_own_bike"}>
                <CheckBox
                    onChange={() => {
                        dispatch(updateBookBike({bike_id: -1}))
                    }}
                    label={""}
                    name={''}
                    price={label('I will go on tour on my motorcycle')}
                    checked={+bike_id === -1}
                />
            </div>
        </div>
    )
}

interface checkoutPassengerProps {
    passenger: number
}

const CheckoutPassenger: FC<checkoutPassengerProps> = ({passenger}) => {
    const dispatch = useAppDispatch();

    const handleChange = (n: number) => {
        dispatch(updatePassenger({passenger: +passenger === 1 ? 0 : 1}))
    }

    return (
        <div className={"checkout_passenger"}>
            <CheckBox
                onChange={() => {
                    handleChange(0)
                }}
                label={""}
                name={''}
                price={label('Add Passenger')}
                checked={+passenger === 1}
            />
        </div>
    )
}