import React, {FC, Fragment, useState} from "react";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchTerms} from "../../features/terms/termsSlice";
import {fetchPrivacy} from "../../features/privacy/privacySlice";
import {ModalPortal} from "../common";
import {ModalWrap} from "../common/ModalWrap";
import {OrderHistoryItem} from "../../types/orders";
import moment from "moment";

interface readProps{
    onChange:()=>void
    checked:boolean
}
const ReadTerms:FC<readProps> = ({onChange,checked}) => {
    const [showTerms,setTerms] = useState(false);
    const [showPolicy,setPolicy] = useState(false);

    const terms = useAppSelector(state=>state.terms);
    const privacy = useAppSelector(state=>state.privacy);

    const dispatch = useAppDispatch();

    const getTerms = (e:React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        if(!terms.load){
            dispatch(fetchTerms());
        }
        setPolicy(false);
        setTerms(true);
    }

    const handleCloseTerms = () => {
        setTerms(false);
    }

    const getPolicy = (e:React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        if(!privacy.load){
            dispatch(fetchPrivacy());
        }
        setTerms(false);
        setPolicy(true);
    }

    const handleClosePolicy = () => {
        setPolicy(false);
    }

    const showTermsModal = () => {
        if(showTerms){
            return (
                <ModalPortal addClass={"medium-modal terms-modal"}>
                    <ModalWrap closeModal={handleCloseTerms}>
                        <div className={"modal-header"}>
                            {label('Terms and Conditions')}
                        </div>
                        <div className={"privacy_text"} dangerouslySetInnerHTML={{__html:terms.info}}/>
                    </ModalWrap>
                </ModalPortal>
            )
        }
        return null;
    }

    const showPolicyModal = () => {
        if(showPolicy){
            return (
                <ModalPortal addClass={"medium-modal terms-modal"}>
                    <ModalWrap closeModal={handleClosePolicy}>
                        <div className={"modal-header"}>
                            {label('Privacy Policy')}
                        </div>
                        <div className={"privacy_text "} dangerouslySetInnerHTML={{__html:privacy.info}}/>
                    </ModalWrap>
                </ModalPortal>
            )
        }
        return null;
    }

    return (
        <Fragment>
            {showTermsModal()}
            {showPolicyModal()}
            <div className="checkbox-wrapper-12"
                 onClick={onChange}
            >
                <div className="cbx">
                    <input checked={checked} onChange={() => {
                    }} type="checkbox"/>
                    <label></label>
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
                        <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                </div>
                <div className="label">
                    <span>{label('I have read and agreed to the')}</span>&nbsp;&nbsp;
                    <span className={"link_item"} onClick={(e)=>{getTerms(e)}}>{label('Terms and Conditions')}</span>&nbsp;,&nbsp;
                    <span className={"link_item"} onClick={(e)=>{getPolicy(e)}} >{label('Privacy Policy')}</span>&nbsp;&nbsp;
                </div>
                <div className="value"/>
            </div>
        </Fragment>
    )
}

export default ReadTerms;