import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {ContactsState} from "../../types/contacts";
import {User} from "../../types";

export const fetchContactsList = createAsyncThunk(
    'fetchContactsList',
    async (thunkAPI)  => {
        return await api.getContactsList() as ContactsState;

    }
)

export const fetchMessage = createAsyncThunk(
    'fetchMessage',
    async (form: User, thunkAPI) => {
        return await api.sendMessage(form);

    }
)


const initialState:any ={
/*    city: '',
    address:'',
    phone:'',
    email:'',
    googleCoords:'',
    siteName:'',*/
    send:''
};

export const contactsSlice = createSlice({
    name:"contacts",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder/*.addCase(fetchContactsList.fulfilled,(state,action)=>{
            state.city =  action.payload.city;
            state.address =  action.payload.address;
            state.phone =  action.payload.phone;
            state.email =  action.payload.email;
            state.siteName =  action.payload.siteName;
            state.googleCoords =  action.payload.googleCoords;
        })*/.addCase(fetchMessage.fulfilled,(state,action)=>{
            // @ts-ignore
            state.send =  action.payload;
        })
    }
})

export const {} = contactsSlice.actions;
export const contactsReducer = contactsSlice.reducer;