import React, {FC, useEffect} from 'react';
import {label} from "../service";
import "./RoutesList.scss";
import {useScrollTop} from "../hooks";
import {useAppDispatch} from "../app/hooks";
import {fetchHomeData, fetchRoutesData} from "../features/common/commonSlice";

export const RoutesList = () => {
    const List = [
        {
            name:"Svätý Jur - Kúty 2h 46min / 164km",
            src:"https://www.google.com/maps/embed?pb=!1m42!1m12!1m3!1d338715.76214605564!2d16.94300160087591!3d48.45236791999212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m27!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.5100657!2d17.4369335!4m5!1s0x476cd19b335fd6a1%3A0x400f7d1c696eff0!2zS8O6dHk!3m2!1d48.658138199999996!2d17.0204841!4m3!3m2!1d48.5223301!2d16.9887974!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677678512300!5m2!1ssk!2ssk",
            href:"https://www.google.com/maps/dir/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/Za+br%C3%A1nou+323,+919+04+Smolenice/K%C3%BAty,+908+01/908+73+Ve%C4%BEk%C3%A9+Lev%C3%A1re/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/@48.40489,17.223177,9z/data=!4m32!4m31!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!1m5!1m1!1s0x476cbab2ed658b09:0xd31acd7c975cd606!2m2!1d17.4368689!2d48.5098884!1m5!1m1!1s0x476cd19b335fd6a1:0x400f7d1c696eff0!2m2!1d17.0204841!2d48.6581382!1m5!1m1!1s0x476cc35e94925305:0x1c00f7d50befd9e0!2m2!1d17.0049991!2d48.5107611!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!3e0?hl=sk"
        },
        {
            name:"Svätý Jur - Brezová pod Bradlom 3h 19min / 184km",
            src:"https://www.google.com/maps/embed?pb=!1m48!1m12!1m3!1d80628.72808297575!2d17.362407478911013!3d48.45297931709882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m33!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.447914999999995!2d17.4268125!4m3!3m2!1d48.6094173!2d17.714095399999998!4m3!3m2!1d48.628752999999996!2d17.4873852!4m3!3m2!1d48.465547799999996!2d17.2327649!4m3!3m2!1d48.244599199999996!2d17.0342174!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677679114712!5m2!1ssk!2ssk",
            href:"https://www.google.com/maps/dir/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/502,+Doln%C3%A9+Ore%C5%A1any/502,+Vrbov%C3%A9/Hradi%C5%A1te+pod+Vr%C3%A1tnom,+906+12/906+37+Solo%C5%A1nica/Dream+Property,+2+48m,+841+06+Z%C3%A1horsk%C3%A1+Bystrica/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/@48.399855,17.365905,9z/data=!4m44!4m43!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!1m5!1m1!1s0x476c97a611dbfa93:0xdc816971d1f3c88c!2m2!1d17.4266639!2d48.4438142!1m5!1m1!1s0x476caeacf4c86161:0xcaa16037be0ee3ee!2m2!1d17.7049055!2d48.6041232!1m5!1m1!1s0x476cb262f49cea0f:0x63053f0f4c1aeaaf!2m2!1d17.487129!2d48.6274767!1m5!1m1!1s0x476cbeda768659df:0x1c00f7d50bef7fa0!2m2!1d17.2410918!2d48.4643713!1m5!1m1!1s0x476cf2ad8092d25b:0x97232502b382afb5!2m2!1d17.0346177!2d48.2445371!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!3e0?hl=sk"}
        ,
        {
            name:"Svätý Jur - Dunajský Klátov 2h 18min / 135km",
            src:"https://www.google.com/maps/embed?pb=!1m48!1m12!1m3!1d170429.7898841255!2d17.30488674756696!3d48.13018933941876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m33!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.2121816!2d17.193136499999998!4m3!3m2!1d48.0387687!2d17.5242978!4m3!3m2!1d48.0266267!2d17.6887954!4m3!3m2!1d48.116099!2d17.622118999999998!4m3!3m2!1d48.251251499999995!2d17.3368136!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677679601357!5m2!1ssk!2ssk",
            href:"https://www.google.com/maps?ll=48.129715,17.444969&z=10&t=m&hl=sk&gl=SK&mapclient=embed&saddr=Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&daddr=Rybni%C4%8Dn%C3%A1+32,+831+07+Vajnory+to:572,+930+35+Michal+na+Ostrove+to:Dunajsk%C3%BD+Kl%C3%A1tov,+930+21+to:1356,+925+08+%C4%8Cierny+Brod+to:Seneck%C3%A1,+900+23+Vini%C4%8Dn%C3%A9+to:Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&dirflg=d"
        },
        {
            name:"Svätý Jur - Veľký Meder 3h 10min / 182km",
            src:"https://www.google.com/maps/embed?pb=!1m52!1m12!1m3!1d66404.95721687356!2d17.297810068111133!3d48.25012364498876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m37!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.205345!2d17.1956416!4m3!3m2!1d47.9814541!2d17.4131996!4m3!3m2!1d47.8523436!2d17.740482699999998!4m3!3m2!1d48.1289361!2d17.6800916!4m3!3m2!1d48.3237965!2d17.434315899999998!4m3!3m2!1d48.2972435!2d17.312291!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677680074317!5m2!1ssk!2ssk",
            href:"https://www.google.com/maps?ll=48.08332,17.46067&z=9&t=m&hl=sk&gl=SK&mapclient=embed&saddr=Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&daddr=Vajnory,+Bratislava+to:Rohovce,+930+30+to:Ve%C4%BEk%C3%BD+Meder,+932+01+to:507,+925+07+Mostov%C3%A1+to:1095,+900+84+B%C3%A1ho%C5%88+to:1086,+%C5%A0enkvice+to:Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&dirflg=d"
        },
    ];

    useScrollTop();

    const dispatch = useAppDispatch();
    useEffect(()=>{
        dispatch(fetchRoutesData());
    },[])

    return (
        <main className="page routes w-full">
            <div className={"routes_wrap w-wrap"}>
                <div className="page-header">
                    <h1 className={"title"}>{label('Routes')}</h1>
                </div>
                <div className={"map_container"}>
                    {List.map((item,index)=>{
                        return (
                            <div className={"map"}>
                                <h2>{item.name}</h2>
                                <iframe
                                    src={item.src}
                                    width="400" height="300" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                                <a  className="btn_more" href={item.href} target="_blank">{label('Go to the route')}</a>
                            </div>
                        )
                    })
                    }
                </div>
                {false && <div className="map_container">
                    <div className="map">
                        <h2>Svätý Jur - Kúty 2h 46min / 164km </h2>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m42!1m12!1m3!1d338715.76214605564!2d16.94300160087591!3d48.45236791999212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m27!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.5100657!2d17.4369335!4m5!1s0x476cd19b335fd6a1%3A0x400f7d1c696eff0!2zS8O6dHk!3m2!1d48.658138199999996!2d17.0204841!4m3!3m2!1d48.5223301!2d16.9887974!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677678512300!5m2!1ssk!2ssk"
                            width="575" height="450" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <div className="btn">
                            <a href="https://www.google.com/maps/dir/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/Za+br%C3%A1nou+323,+919+04+Smolenice/K%C3%BAty,+908+01/908+73+Ve%C4%BEk%C3%A9+Lev%C3%A1re/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/@48.40489,17.223177,9z/data=!4m32!4m31!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!1m5!1m1!1s0x476cbab2ed658b09:0xd31acd7c975cd606!2m2!1d17.4368689!2d48.5098884!1m5!1m1!1s0x476cd19b335fd6a1:0x400f7d1c696eff0!2m2!1d17.0204841!2d48.6581382!1m5!1m1!1s0x476cc35e94925305:0x1c00f7d50befd9e0!2m2!1d17.0049991!2d48.5107611!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!3e0?hl=sk"
                               target="_blank">GO TO THE ROUTE</a>
                        </div>
                    </div>
                    <div className="map">
                        <h2>Svätý Jur - Brezová pod Bradlom 3h 19min / 184km </h2>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m48!1m12!1m3!1d80628.72808297575!2d17.362407478911013!3d48.45297931709882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m33!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.447914999999995!2d17.4268125!4m3!3m2!1d48.6094173!2d17.714095399999998!4m3!3m2!1d48.628752999999996!2d17.4873852!4m3!3m2!1d48.465547799999996!2d17.2327649!4m3!3m2!1d48.244599199999996!2d17.0342174!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677679114712!5m2!1ssk!2ssk"
                            width="575" height="450" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <div className="btn">
                            <a href="https://www.google.com/maps/dir/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/502,+Doln%C3%A9+Ore%C5%A1any/502,+Vrbov%C3%A9/Hradi%C5%A1te+pod+Vr%C3%A1tnom,+906+12/906+37+Solo%C5%A1nica/Dream+Property,+2+48m,+841+06+Z%C3%A1horsk%C3%A1+Bystrica/Krajinsk%C3%A1+cesta+292%2F14,+900+21+Sv%C3%A4t%C3%BD+Jur/@48.399855,17.365905,9z/data=!4m44!4m43!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!1m5!1m1!1s0x476c97a611dbfa93:0xdc816971d1f3c88c!2m2!1d17.4266639!2d48.4438142!1m5!1m1!1s0x476caeacf4c86161:0xcaa16037be0ee3ee!2m2!1d17.7049055!2d48.6041232!1m5!1m1!1s0x476cb262f49cea0f:0x63053f0f4c1aeaaf!2m2!1d17.487129!2d48.6274767!1m5!1m1!1s0x476cbeda768659df:0x1c00f7d50bef7fa0!2m2!1d17.2410918!2d48.4643713!1m5!1m1!1s0x476cf2ad8092d25b:0x97232502b382afb5!2m2!1d17.0346177!2d48.2445371!1m5!1m1!1s0x476c91ca70d28ced:0x20dfef02d870a209!2m2!1d17.2113186!2d48.2478117!3e0?hl=sk"
                               target="_blank">GO TO THE ROUTE</a>
                        </div>
                    </div>
                    <div className="map">
                        <h2>Svätý Jur - Dunajský Klátov 2h 18min / 135km</h2>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m48!1m12!1m3!1d170429.7898841255!2d17.30488674756696!3d48.13018933941876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m33!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.2121816!2d17.193136499999998!4m3!3m2!1d48.0387687!2d17.5242978!4m3!3m2!1d48.0266267!2d17.6887954!4m3!3m2!1d48.116099!2d17.622118999999998!4m3!3m2!1d48.251251499999995!2d17.3368136!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677679601357!5m2!1ssk!2ssk"
                            width="575" height="450" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <div className="btn">
                            <a href="https://www.google.com/maps?ll=48.129715,17.444969&amp;z=10&amp;t=m&amp;hl=sk&amp;gl=SK&amp;mapclient=embed&amp;saddr=Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&amp;daddr=Rybni%C4%8Dn%C3%A1+32,+831+07+Vajnory+to:572,+930+35+Michal+na+Ostrove+to:Dunajsk%C3%BD+Kl%C3%A1tov,+930+21+to:1356,+925+08+%C4%8Cierny+Brod+to:Seneck%C3%A1,+900+23+Vini%C4%8Dn%C3%A9+to:Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&amp;dirflg=d"
                               target="_blank">GO TO THE ROUTE</a>
                        </div>
                    </div>
                    <div className="map">
                        <h2>Svätý Jur - Veľký Meder 3h 10min / 182km</h2>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m52!1m12!1m3!1d66404.95721687356!2d17.297810068111133!3d48.25012364498876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m37!3e0!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIDkwMCAyMSBTdsOkdMO9IEp1cg!3m2!1d48.2478117!2d17.2113186!4m3!3m2!1d48.205345!2d17.1956416!4m3!3m2!1d47.9814541!2d17.4131996!4m3!3m2!1d47.8523436!2d17.740482699999998!4m3!3m2!1d48.1289361!2d17.6800916!4m3!3m2!1d48.3237965!2d17.434315899999998!4m3!3m2!1d48.2972435!2d17.312291!4m5!1s0x476c91ca70d28ced%3A0x20dfef02d870a209!2zS3Jhamluc2vDoSBjZXN0YSAyOTIvMTQsIFN2w6R0w70gSnVy!3m2!1d48.2478117!2d17.2113186!5e0!3m2!1ssk!2ssk!4v1677680074317!5m2!1ssk!2ssk"
                            width="575" height="450" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <div className="btn">
                            <a href="https://www.google.com/maps?ll=48.08332,17.46067&amp;z=9&amp;t=m&amp;hl=sk&amp;gl=SK&amp;mapclient=embed&amp;saddr=Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&amp;daddr=Vajnory,+Bratislava+to:Rohovce,+930+30+to:Ve%C4%BEk%C3%BD+Meder,+932+01+to:507,+925+07+Mostov%C3%A1+to:1095,+900+84+B%C3%A1ho%C5%88+to:1086,+%C5%A0enkvice+to:Krajinsk%C3%A1+cesta+292/14,+900+21+Sv%C3%A4t%C3%BD+Jur&amp;dirflg=d"
                               target="_blank">GO TO THE ROUTE</a>
                        </div>
                    </div>
                </div>}
            </div>
        </main>
    );
}


/*interface mapProps{
    list:any[]
}
const MapsContainer:FC<mapProps> = ({list}) => {
    return (
        <div className={"maps_container"}>
            {list.map((item,index)=>{
                    return (
                        null
                    )
                })
            }
        </div>
    )
}*/