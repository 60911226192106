import React, {useEffect, useState} from "react";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {TextArea} from "../tools/TextArea";
import {updateText} from "../../features/certificate/certificateSlice";

export const CheckoutTextBlock = () => {
    const [counter,setCounter] = useState(0);
    let max_symbol = 120;

    const values = useAppSelector(state => state.certificate.values);

    useEffect(()=>{
        setCounter(values.text.length);
    },[values.text]);

    const dispatch = useAppDispatch();

    const handleText = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        if(text.length <= max_symbol){
            dispatch(updateText({text:text}))
        }
    }

    let disabled = +values.image_id === 0;

    return (
        <div className={"main-data "}>
            {disabled && <div className={"disabled-data "} /> }
            <div className={"accessor"}>
                <div className={"heading"}>{label('Voucher Text')}</div>
                <div className={"certificate_text"}>
                    <div className={"certificate_text_value text"}>
                        <TextArea rows={5} error={false} value={values.text} onChange={handleText} name={'text'} placeHolder={label('Your Message')} />
                    </div>
                    <div className={"certificate_text_note"}>{`${label('The number of characters left')}: ${max_symbol - counter}`}</div>
                </div>
            </div>
        </div>
    )
}