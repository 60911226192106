import React, {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {
    fetchPackageBikeAccessories,
    updateDate, updateOvertime,
    updateStage
} from "../../features/packageBikeDetails/packageBikeDetailsSlice";
import {DataPickerContent} from "../bike/date_picker/DataPickerContent";
import {dateToTs, durFromTs} from "../bike/date_picker/utils";
import {RegularTime} from "../bike/select_time/RegularTime";
import {isWorkTime} from "../../utils";

export const PackageOrderCheckoutDate = () => {
    const avail = useAppSelector(state => state.packageBikeDetails.avail);
    const schedule = useAppSelector(state => state.packageBikeDetails.schedule);
    const deny_day = useAppSelector(state => state.packageBikeDetails.deny_day);
    const used_day = useAppSelector(state => state.packageBikeDetails.used_day);
    const values = useAppSelector(state => state.packageBikeDetails.values);
    const extra = useAppSelector(state => state.packageBikeDetails.extra);
    const equip = useAppSelector(state => state.packageBikeDetails.equip);
    const min_days = useAppSelector(state => state.packageBikeDetails.min_days);
    const max_days = useAppSelector(state => state.packageBikeDetails.max_days);
    const {date, overtime} = values;

    const isExtra = equip.length > 0 || extra.length > 0;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (date.start && date.end && date.optionEnd && date.optionStart) {
            dispatch(fetchPackageBikeAccessories({...date, model_id: +values.bike_id}));
        }
    }, [date])


    const handleDate = (value: string[], duration: number) => {
        const curTime = ('0' + (new Date()).getHours()).slice(-2);
        let startTime = `${curTime}:00`;//"08:00"
        let endTime = value[0] == value[1] ? "" : `${curTime}:00`;
        const durationDate = durFromTs(dateToTs(value[0], startTime), dateToTs(value[1], endTime));

        dispatch(updateDate({
            start: value[0],
            end: value[1],
            duration: durationDate,
            optionEnd: endTime,
            optionStart: startTime,
        }));

        // @ts-ignore
        if (!isWorkTime(value[0], startTime, schedule)) {
            if (!overtime.includes('pickup')) {
                dispatch(updateOvertime({key: 'pickup'}));
            }
        } else {
            if (overtime.includes('pickup')) {
                dispatch(updateOvertime({key: 'pickup'}));
            }
        }

        // @ts-ignore
        if (!isWorkTime(value[1], endTime, schedule)) {
            if (!overtime.includes('drop_off')) {
                dispatch(updateOvertime({key: 'drop_off'}));
            }
        } else {
            if (overtime.includes('drop_off')) {
                dispatch(updateOvertime({key: 'drop_off'}));
            }
        }

    }

    const handleStage = () => {
        if (date.start && date.end && date.optionEnd && date.optionStart) {
            if (isExtra) {
                dispatch(updateStage({stage: 1}))
            } else {
                dispatch(updateStage({stage: 3}))
            }
        }
    }

    const timeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === "") {
            return;
        }

        const new_date = {...date, [e.target.name]: e.target.value};
        if (e.target.name === 'optionStart') {
            new_date.optionEnd = e.target.value;
        }
        new_date.duration = durFromTs(dateToTs(new_date.start, new_date.optionStart), dateToTs(new_date.end, new_date.optionEnd));
        if(new_date.duration < min_days || new_date.duration > max_days){
            return null;
        }
        dispatch(updateDate(new_date));

        // @ts-ignore
        if (!isWorkTime(new_date.start, new_date.optionStart, schedule)) {
            if (!overtime.includes('pickup')) {
                dispatch(updateOvertime({key: 'pickup'}));
            }
        } else {
            if (overtime.includes('pickup')) {
                dispatch(updateOvertime({key: 'pickup'}));
            }
        }

        // @ts-ignore
        if (!isWorkTime(new_date.end, new_date.optionEnd, schedule)) {
            if (!overtime.includes('drop_off')) {
                dispatch(updateOvertime({key: 'drop_off'}));
            }
        } else {
            if (overtime.includes('drop_off')) {
                dispatch(updateOvertime({key: 'drop_off'}));
            }
        }
    }

    const type = "normal";
    const denyDays = useMemo(() => {
        return deny_day.map((item) => {
            return new Date(item);
        })
    }, [deny_day]);

    const usedDays = useMemo(() => {
        return used_day.map((item) => {
            return new Date(item);
        })
    }, [used_day])

    return (
        <div className="checkout">
            <div className="main-data">
                <div className="selected-block">
                    <div className={"form-content-row-value order_add_date"}>
                        {!!avail && <div className={"order_add_date_item"} style={{position: "relative"}}>
                            <div className="DatePicker">
                                <DataPickerContent
                                    selectedValue={new Date()}
                                    onSelect={handleDate}
                                    denyDays={denyDays}
                                    usedDays={usedDays}
                                    type={type}
                                    maxDay={max_days+1}
                                    minDay={min_days===1? 1 : min_days+1}
                                />
                            </div>
                        </div>}
                        {date.start && date.end ? <div className={"order_add_date_side "}>
                                <div className={"order_add_date_side_time "}>
                                    <RegularTime
                                        // @ts-ignore
                                        schedule={schedule}
                                        timeSelectHandler={timeSelectHandler}
                                        // @ts-ignore
                                        overtime={overtime}
                                        date={date}
                                    />
                                </div>
                            </div>
                            :
                            <div className={"order_add_date_side_info "}>
                                {label('Please choose date to continue book motorcycle')}
                            </div>
                        }
                    </div>

                </div>
                <div className="booking-block">
                    {date.start && date.end && date.optionEnd && date.optionStart &&
                        <div className={"btn  " + " btn-secondary"}
                             onClick={handleStage}
                        >{label('Next')}</div>}
                </div>

            </div>
        </div>
    )
}