import React from 'react';
import "./footer.scss"
import {label} from "../../service";
import {useAppSelector} from "../../app/hooks";
import {IoIosArrowUp} from "react-icons/io";

export const Footer = () => {
    const contacts = useAppSelector(state => state.common.contacts);
    const external_links = useAppSelector(state => state.common.external_links);

    const handleToTop = () => {
        window.scrollTo(0, 0);
    }

    if (!contacts) {
        return null;
    }
    let isLinks = external_links && Object.keys(external_links).length > 0;
    return (
            <footer className="footer w-full">
                <div className="footer_wrap w-wrap">
                    <div className="footer_content">
                        <div className="footer_content_item">
                            <div className="footer_content_logo">
                                <a href="/" className="logo"/>
                            </div>
                            <ul>
                                <li>
                                    <span className="icon icon_email"/>
                                    <span><a href={"mailto:" + contacts.email}>{contacts.email}</a></span>
                                </li>
                                <li>
                                    <span className="icon icon_phone"/>
                                    <span><a href={"tel:" + contacts.phone}>{contacts.phone}</a></span>
                                </li>
                            </ul>
                        </div>
                        <div className="footer_content_area">
                            <div className="footer_content_item">
                                <div className="heading">{label('Office Address')}</div>
                                <ul>
                                <li>{contacts.siteName}</li>
                                    <li>{contacts.address}</li>
                                    <li>{contacts.city}</li>
                                    <li>{contacts.country}</li>
                                </ul>
                            </div>
                            <div className="footer_content_item">
                                <div className="heading">{label('Information')}</div>
                                <ul>
                                    <li><a href={"/faq"}>{label('FAQ')}</a></li>
                                    <li><a href={"/vacancy"}>{label('Vacancies')}</a></li>
                                    <li><a href={"/privacy"}>{label('Privacy Policy')}</a></li>
                                    <li><a href={"/terms"}>{label('Terms and Conditions')}</a></li>
                                </ul>
                            </div>
                            <div className="footer_content_item">
                                <div className="heading">{label('More')}</div>
                                <ul>
                                    <li><a href={"/routes"}>{label('Routes')}</a></li>
                                    <li><a href={"/gallery"}>{label('Gallery')}</a></li>
                                    <li><a href={"/contacts"}>{label('Contacts')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer_sub">
                        {isLinks && <div className="footer_sub_links">
                            {external_links.facebook &&
                                <a href={external_links.facebook} target={"_blank"} className="footer_sub_links_item">
                                    <img alt="Tamamoto on Facebook" src={'/www/images/links/facebook_28.png'}/>
                                </a>}
                            {external_links.instagram &&
                                <a href={external_links.instagram} target={"_blank"} className="footer_sub_links_item">
                                    <img alt="Tamamoto in Instagram" src={'/www/images/links/instagram_28.png'}/>
                                </a>}
                        </div>}
                        <div className="footer_sub_site">
                            <span>© 2023 {contacts.siteName + ' ' + label('All rights reserved')}</span>
                        </div>
                    </div>
                </div>
                <div className="footer_content_bottom_top">
                    <div className="footer_content_bottom_top_button"
                         onClick={handleToTop}
                    >
                        <IoIosArrowUp/>
                    </div>
                </div>
            </footer>
    );
    /*
    <div id={"footer"}>
            <div className="footer-content">
                <div className="footer-wrap">
                    <div className="footer-block">
                        <h3 className="heading">{label('Contact')}</h3>
                        <div className="footer-text">
                            <span>{contacts.email}</span>
                            <span>{contacts.phone}</span>
                        </div>
                    </div>
                    <div className="footer-block">
                        <h3 className="heading">{label('Office Address')}</h3>
                        <div className="footer-text">
                            <span>{contacts.siteName}</span>
                            <span>{contacts.address}</span>
                            <span>{contacts.city}</span>
                            <span>{contacts.country}</span>
                        </div>
                    </div>
                    <div className="footer-block">
                        <h3 className="heading">{label('Company Info')}</h3>
                        <div className="footer-links">
                            <a href={"/faq"}>{label('FAQ')}</a>
                            <a href={"/vacancy"}>{label('Vacancies')}</a>
                            <a href={"/privacy"}>{label('Privacy Police')}</a>
                            <a href={"/terms"}>{label('Terms and Conditions')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content-bottom">
                <div className="footer-wrap">
                    <div className="footer-content-bottom-text">{contacts.siteName + ' ' + label('all rights reserved')}</div>
                    <div className="footer-content-bottom-social"></div>
                </div>
                <div className="footer-content-bottom-top">
                    <div className="footer-content-bottom-top-button"
                         onClick={handleToTop}
                    >
                        <IoIosArrowUp />
                    </div>
                </div>
            </div>
        </div>
    */
}

