import React, {FC, useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {FaqListItem, FaqQuestion} from "../../types/faq";
import {SlArrowDown} from "react-icons/sl";

interface listProps {
    focus: number
}

const FaqList: FC<listProps> = ({focus}) => {
    const divRef = useRef(null);

    const list = useAppSelector(state => state.faq.list);

    useEffect(() => {
        if(focus){

            // @ts-ignore
            const element = document.getElementById(focus + '_category');
            console.log('f',focus,element);
            if(element){
                element.focus();
            }
        }
    }, [focus]);

    return (
        <div className={"faq_list"}>
            {list.map((item: FaqListItem) => {
                return (
                    <div className={"faq_list_item"}
                         key={item.category_id}
                         id={item.category_id + '_category'} tabIndex={item.category_id} ref={divRef}
                    >
                        <h2>{item.name}</h2>
                        <div className={"faq_list_questions"}>
                            {item.questions.map((question: FaqQuestion) => {
                                return (
                                    <FaqQuestionItem
                                        key={question.id}
                                        question={question}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

interface questionProps {
    question: FaqQuestion
}

const FaqQuestionItem: FC<questionProps> = ({question}) => {
    const [expand, setExpand] = useState(false);

    const handleExpand = () => {
        setExpand(!expand);
    }

    return (
        <div className={"faq_list_questions_item " + (expand ? " expanded_answer " : "")}>
            <div className={"faq_list_questions_item_row "}
                 onClick={handleExpand}
            >
                <div className={"faq_list_questions_item_question"}>{question.question}</div>
                <div className={"faq_list_questions_item_expander "}>
                    <SlArrowDown/>
                </div>
            </div>
            <div className={"faq_list_questions_item_answer "}>
                <div className={"text_desc"}>
                    {question.answer}
                </div>
            </div>
        </div>
    )
}

export default FaqList;