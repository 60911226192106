import React, {FC, useEffect, useState, Dispatch} from 'react';
import {BikeDetail, OrderData, PaymentMethod, User} from "../../types";
import {
    fetchOrder, fetchResetBikePromo,
    getAccessory,
    processSuccess, resetCheckEmail,
    resetSuccess, sendUserAction
} from "../../features/bike/bikeSlice";
import {useAppDispatch} from "../../app/hooks";
import {isWorkTime, tsFromDateTime, validateEmail, validateName, validatePhone} from "../../utils";
import "./checkout.scss"
import {CheckoutPageOne} from "./CheckoutPageOne";
import {CheckoutPageTwo} from "./CheckoutPageTwo";
import {label} from "../../service";
import {CheckoutPageSize} from "./CheckoutPageSize";
import {OrderCalc} from "./OrderCalc";
import {CheckoutPagePayment} from "./CheckoutPagePayment";
import {useLocation} from "react-router-dom";
import {OrderModal} from "../common/OrderModal";
import {DataPicker} from "./date_picker";
import {priceCalc} from "./priceCalc";
import {SelectTime} from "./select_time";
import {dateToTs, durFromTs} from "./date_picker/utils";
import {CalType} from "../../page/Bike";
import {UserProfile} from "../../types/login";
import {useSet} from "../../hooks";
import {SetT} from "../../hooks/use-map";
import {CountryItem} from "../../types/common";

export type SelectedDaysT = {
    start: string,
    end: string,
    duration: number,
}

export type SelectedOptionT = {
    optionStart: string,
    optionEnd: string,
    option?: any
}
export type SelectedBook = SelectedDaysT & SelectedOptionT

interface CheckoutProps {
    item: BikeDetail
    success: number
    invoiceUrl: string,
    selectedDays: SelectedDaysT
    checkPromoError: boolean
    checkPromo: boolean
    checkEmailError: boolean
    checkEmail: boolean
    profile: UserProfile
    calType: CalType,
    setType: Dispatch<React.SetStateAction<CalType>>
    states: CountryItem[]
    checkoutPrice: string
}

export type CheckoutError = {
    email: boolean;
    phone: boolean;
    password: boolean;
    name: boolean;
    surname: boolean;
    date: boolean;
    birthdate: boolean,
    zipcode: boolean,
    country: boolean,
    city: boolean,
    street: boolean,
    is_firm: boolean,
    firm_name: boolean,
    ICO: boolean,
    DIC: boolean,
    IC_DPH: boolean,
}
const initDate = {
    start: "",
    end: "",
    duration: 0,
    optionStart: "",
    optionEnd: ""
};
export const Checkout: FC<CheckoutProps> = ({
                                                item,
                                                success,
                                                invoiceUrl,
                                                selectedDays,
                                                checkPromoError,
                                                checkPromo,
                                                profile,
                                                states,
                                                calType,
                                                setType,
                                                checkEmailError,
                                                checkEmail,
                                                checkoutPrice
                                            }) => {


    const [isSelectedOption, setISelectedOption] = useState<boolean>(false);
    const [date, setDate] = useState<SelectedBook>(initDate);
    const [accessor, setAccessor] = useState<{ [key: string]: any }>({});
    const extras = useSet<number>([]);//useState(new Set());
    const insurances = useSet<number>([]);
    const overtime = useSet<string>([])
    const {promo} = item;
    useEffect(() => {
        if (isSelectedOption) {
            dispatch(getAccessory({...date, model_id: item.id}));
        }

    }, [isSelectedOption, date])


    const handleSetISelectedOption = (result: boolean) => {
        setISelectedOption(result);
    }


    useEffect(() => {
        setPage(0);
    }, [calType]);

    useEffect(() => {
        reCalcTotal();
    }, [promo, date, date.duration, accessor, extras, insurances, overtime])


    useEffect(() => {
        if (initDate !== date) {
            setDate(initDate);
        }
        if (isSelectedOption) {
            setISelectedOption(false);
        }

    }, [calType]);


    const dispatch = useAppDispatch();

    const location = useLocation();

    const [page, setPage] = useState<number>(0);
    const [paid, setPaid] = useState<boolean>(false);

    const [form, setForm] = useState<User>({
        name: '',
        surname: '',
        email: '',
        password: '',
        phone: '',
        note: '',
        birthdate: "",
        zipcode: "",
        country: "",
        country_id: 0,
        city: "",
        street: "",
        is_firm: 0,
        firm_name: "",
        ICO: "",
        DIC: "",
        IC_DPH: "",
    });

    const [error, setError] = useState<CheckoutError>({
        email: false,
        phone: false,
        password: false,
        name: false,
        surname: false,
        date: false,
        birthdate: false,
        zipcode: false,
        country: false,
        city: false,
        street: false,
        is_firm: false,
        firm_name: false,
        ICO: false,
        DIC: false,
        IC_DPH: false,
    });

    const [total, setTotal] = useState<{ total: number, price: { total: number, discount: number, real: number }, promoDiscount: number }>
    ({
        total: Number(item.price),
        price: {total: Number(item.price), discount: 0, real: Number(item.price)},
        promoDiscount: 0
    });
    const [percent, setPercent] = useState(0);
    const [method, setPayment] = useState('');
    const duration = Math.max(1, date.duration || 1);

    const reCalcTotal = () => {
        let per_day = 0;
        item.equip
            .filter(item => item.price > 0 /*&& item.count > 0*/)
            .forEach(item => {
                // @ts-ignore
                if (accessor[item.id] !== undefined) {
                    // @ts-ignore
                    let count = accessor[item.id].count || 1;
                    per_day += Number(item.price * count);
                }
            });
        let extra_pay = 0;
        item.extra
            .filter(item => item.price > 0)
            .forEach(item => {
                if (extras.has(item.id)) {
                    extra_pay += Number(item.price);
                }
            });
        let insurances_pay = 0;
        item.insurances
            .forEach(item => {
                if (insurances.has(item.id)) {
                    if (item.one_time > 0) {
                        insurances_pay += Number(item.price);
                    } else {
                        insurances_pay += item.price * duration;
                    }
                }
            });
        const calc = priceCalc(item, date, calType);//Number(item.price)

        ////  calc overtime
        let overtime_price = 0;
        if (item.overtime) {
            Object.entries(item.overtime).forEach(([key, value]) => {
                if (overtime.has(key)) {
                    overtime_price += value.price;
                }
            });
        }
        ////
        const totalOrder = duration * per_day + extra_pay + insurances_pay + calc.total.real + overtime_price;
        const promoDiscount = getPromoDiscount(totalOrder);

        const total = duration * per_day + extra_pay + insurances_pay + calc.total.real + promoDiscount + overtime_price;

        setTotal({total, price: calc.total, promoDiscount: promoDiscount});

    }

    const getPromoDiscount = (totalOrder: number) => {
        let discount = 0;
        if (promo.mode === 'voucher') {
            if (+promo.number > total.total) {
                discount = -1 * total.total;
            } else {
                discount = -1 * +promo.number;
            }
        } else {
            let needPrice = 0;
            switch (promo.target) {
                case 'bike':
                    if (+promo.start_rental > 0 && +promo.end_rental > 0) {
                        const start = tsFromDateTime(date.start, date.optionStart);

                        if (start < +promo.start_rental || start > +promo.end_rental) {
                            return discount;
                        }
                    }
                    const calc = priceCalc(item, date, calType);
                    let bike_price = calc.total.real;
                    if (+promo.target_id === 0 || +promo.target_id === +item.id) {
                        needPrice = bike_price;
                    }
                    break;
                case "order":
                    needPrice = totalOrder;
                    break;
                case 'equip':
                    if (accessor[+promo.target_id] !== undefined) {
                        let equip = item.equip.find(i => +i.id === +promo.target_id);
                        if (equip) {
                            const duration = Math.max(1, date.duration || 1);
                            needPrice = Number(equip.price * duration);
                        }
                    }
                    break;
            }
            if (needPrice > 0) {
                switch (promo.type) {
                    case 'relative':
                        discount = needPrice * ((+promo.number) / 100);
                        if (discount > needPrice) {
                            discount = -1 * needPrice;
                        } else {
                            discount = -1 * discount;
                        }
                        break;
                    case 'absolute':
                        if (+promo.number > needPrice) {
                            discount = -1 * needPrice;
                        } else {
                            discount = -1 * +promo.number;
                        }
                        break;
                }
            }
        }


        return Math.round(discount * 100) / 100;
    }


    const methodItem = item.payment_methods.find((item: PaymentMethod) => item.id === method);
    useEffect(() => {
        if (+success === 2 && invoiceUrl !== '' && method) {
            let target = "_blank";
            if (methodItem && methodItem.target) {
                target = methodItem.target;
            }
            if (method === "online_card") {
                setTimeout(() => {
                    window.open(invoiceUrl, target, 'noreferrer');
                }, 5000);
            } else {
                // 👇 Open link in new tab programmatically
                window.open(invoiceUrl, target, 'noreferrer');
            }
        }
    }, [success, invoiceUrl]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('order')) {
            setPaid(true);
        }
    }, [location.search]);

    useEffect(() => {
        if (promo.mail && +profile.user_id === 0) {
            onFormChange('email', promo.mail);
        }
        dispatch(resetCheckEmail());
    }, [promo.mail, profile.user_id]);

    useEffect(() => {
        if (+profile.user_id > 0) {
            const newForm: any = {...form};
            newForm.name = profile.name;
            newForm.email = profile.mail;
            newForm.phone = profile.phone;
            newForm.birthdate = profile.birthdate;
            newForm.zipcode = profile.zipcode;
            newForm.country = profile.country;
            newForm.country_id = profile.country_id;
            newForm.city = profile.city;
            newForm.street = profile.street;
            newForm.is_firm = profile.firm_name === '' ? 0 : 1;
            newForm.firm_name = profile.firm_name;
            newForm.ICO = profile.ICO;
            newForm.DIC = profile.DIC;
            newForm.IC_DPH = profile.IC_DPH;
            setForm(newForm)
        }
    }, [profile]);

    const closePaid = () => {
        setPaid(false);
    }

    const getEquipSize = (id: number) => {
        const equip = item.equip.find((item) => {
            return item.id === id;
        });
        if (!equip) {
            return '-1';
        }
        return equip.sized ? (!!equip.count ? equip.sizes[0].size : "-1") : "";
    }


    const onAccessoryChange = (id: number, count: number) => {

        if (accessor[id] !== undefined) {
            const {[id]: t, ...new_access} = accessor;
            setAccessor(new_access);
        } else {

            let equip_count = 0;
            const equipIndex = item.equip.findIndex((item) => {
                return item.id == id;
            });
            if (equipIndex != -1) {
                equip_count = item.equip[equipIndex].count;
            }

            const new_access = {
                ...accessor, [id]: {
                    count: 1,
                    for_request: equip_count > 0 ? 0 : 1,
                    sizes: {
                        1: getEquipSize(id)
                    }
                }
            }
            setAccessor(new_access);

        }

    }

    const onAccessoryChangeCount = (id: number, count: number) => {

        let equip_count = 0;
        const equipIndex = item.equip.findIndex((item) => {
            return item.id == id;
        });
        if (equipIndex != -1) {
            equip_count = item.equip[equipIndex].count;
        }

        if (accessor[id] !== undefined) {
            accessor[id].count = count;
            accessor[id].for_request = equip_count < count ? 1 : 0;

            for (let i = 0; i < count; i++) {
                accessor[id].sizes[i + 1] = getEquipSize(id);
            }
            let new_access = {...accessor}
            setAccessor(new_access);
        }

    }


    const onAccessoryChangeSize = (id: number, number: number, size: string) => {
        if (accessor[id] !== undefined && accessor[id].sizes[number] !== undefined) {
            const sizes = {...accessor[id].sizes};
            sizes[number] = size;
            let new_access = {...accessor, [id]: {...accessor[id], sizes}}
            setAccessor(new_access);
        }

    }

    const onExtraChange = (id: number) => {
        if (extras.has(id)) {
            extras.delete(id)
        } else {
            extras.clear();
            extras.add(id);
        }

    }

    const onInsurancesChange = (id: number) => {
        if (insurances.has(id)) {
            insurances.delete(id)
        } else {
            insurances.clear();
            insurances.add(id);
        }

    }

    const onFormChange = (name: string, value: any) => {
        const newForm: any = {...form};
        newForm[name] = value;
        setForm(newForm);
        if (name === 'email' && promo.promo_id > 0 && promo.mode === 'roulette' && promo.mail !== "" && promo.mail !== value) {
            dispatch(fetchResetBikePromo(promo.promo_id));
        }
    }

    const percentChange = (new_percent: number) => {
        setPercent(Number(new_percent));
    }

    const methodChange = (new_method: string) => {

        setPayment(new_method);
    }

    const validate = (orderData: OrderData) => {
        const error: CheckoutError = {
            email: false,
            phone: false,
            password: false,
            name: false,
            surname: false,
            date: false,
            birthdate: false,
            zipcode: false,
            country: false,
            city: false,
            street: false,
            is_firm: false,
            firm_name: false,
            ICO: false,
            DIC: false,
            IC_DPH: false,
        }
        let result = true;
        if (!validateEmail(orderData.user.email)) {
            error.email = true;
            result = false;
        }
        if (!validatePhone(orderData.user.phone)) {
            error.phone = true;
            result = false;
        }
        if (!validateName(orderData.user.name)) {
            error.name = true;
            result = false;
        }
        if (!validateName(orderData.user.surname)) {
            error.surname = true;
            result = false;
        }
        /*if(+profile.user_id ===0 && orderData.user.password === ''){
            error.password = true;
            result = false;
        }*/
        /* if(orderData.user.birthdate === ''){
             error.birthdate = true;
             result = false;
         }*/
        if (+orderData.user.country_id === 0) {
            error.country = true;
            result = false;
        }
        if (orderData.user.city === '') {
            error.city = true;
            result = false;
        }
        if (orderData.user.street === '') {
            error.street = true;
            result = false;
        }
        /* if(orderData.user.zipcode === ''){
             error.zipcode = true;
             result = false;
         }*/
        if (date.start === '' || date.end === '') {
            error.date = true;
            result = false;
        }
        if (orderData.user.is_firm) {
            if (orderData.user.firm_name === '') {
                error.firm_name = true;
                result = false;
            }
            if (orderData.user.ICO === '') {
                error.ICO = true;
                result = false;
            }
            if (orderData.user.DIC === '') {
                error.DIC = true;
                result = false;
            }
            if (orderData.user.IC_DPH === '') {
                error.IC_DPH = true;
                result = false;
            }
        }
        setError(error)
        return result;
    }

    const getRulesFromInsurances = (insurances: SetT<number>, item: BikeDetail) => {
        const ins = insurances.arrayFrom();
        const values = ins.filter(id => {
            const find = item.insurances.find((t) => {
                return t.id === id;
            })
            return find !== undefined;
        })
        return values.map(id => {
            return {id: id, status: 2}
        });
    }

    const onBook = () => {
        const orderData: OrderData = {
            bike_id: item.id,
            promo_id: +total.promoDiscount !== 0 ? +promo.promo_id : 0,
            user: form,
            extra: extras.arrayFrom() as number[],
            rules: getRulesFromInsurances(insurances, item),
            accessor: accessor,
            date: date,
            amount: total.total,
            percent,
            method,
            bikeDiscount: total.price.discount,
            user_id: +profile.user_id,
            type: calType,
            overtime: overtime.arrayFrom()
        }

        if (+promo.promo_id > 0 && promo.mode === 'voucher') {
            orderData.amount -= total.promoDiscount;
        }
        dispatch(processSuccess());
        dispatch(fetchOrder(orderData));
    }

    const nextPage = (page: number) => {
        setPage(page);
        dispatch(sendUserAction('getOrderPage' + page));
    }

    const BackPage = (page: number) => {
        setPage(page);
        dispatch(sendUserAction('getOrderPage' + page));
    }

    const paymentPage = () => {

        const orderData: OrderData = {
            bike_id: item.id,
            promo_id: +total.promoDiscount !== 0 ? +promo.promo_id : 0,
            user: form,
            extra: extras.arrayFrom() as number[],
            rules: getRulesFromInsurances(insurances, item),
            accessor: accessor,
            date: date,
            amount: total.total,
            percent,
            method,
            bikeDiscount: total.price.discount,
            user_id: +profile.user_id,
            type: calType,
            overtime: overtime.arrayFrom()
        }
        if (!validate(orderData)) {
            return;
        }
        setPage(3);
        dispatch(sendUserAction('getOrderPage3'));
    }

    let needSize = false;
    item.equip.forEach(item => {
        // @ts-ignore
        if (accessor[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    const getPageData = () => {

        switch (page) {
            case 0:
                return <CheckoutPageOne
                    date={date}
                    equip={item.equip}
                    extra={item.extra}
                    insurances={item.insurances}
                    insurances_values={insurances}
                    accessor={accessor}
                    extras={extras}
                    needSize={needSize}
                    checkoutPrice={checkoutPrice}
                    duration={duration}
                    onAccessoryChange={onAccessoryChange}
                    onAccessoryChangeCount={onAccessoryChangeCount}
                    nextPage={nextPage}
                    onExtraChange={onExtraChange}
                    onInsurancesChange={onInsurancesChange}
                    few={item.few}
                />;
            case 1:
                return <CheckoutPageSize
                    equip={item.equip}
                    accessor={accessor}
                    nextPage={nextPage}
                    onBack={BackPage}
                    onAccessoryChangeSize={onAccessoryChangeSize}
                />;
            case 2:
                return <CheckoutPageTwo
                    error={error}
                    user={form}
                    profile={profile}
                    states={states}
                    needSize={needSize}
                    onFormChange={onFormChange}
                    onBack={BackPage}
                    paymentPage={paymentPage}
                    checkEmail={checkEmail}
                    checkEmailError={checkEmailError}
                />;
            case 3:
                return <CheckoutPagePayment
                    onBook={onBook}
                    onBack={BackPage}
                    method={method}
                    percent={percent}
                    total={total.total}
                    payment_methods={item.payment_methods}
                    payment_percents={item.payment_percents}
                    percentChange={percentChange}
                    methodChange={methodChange}
                />;
            default:
                return null;
        }
    }
    const handleDate = (value: string[], duration: number) => {


        const curTime = ('0' + (new Date()).getHours()).slice(-2);

        let startTime = `${curTime}:00`;//"08:00"
        let endTime = value[0] == value[1] ? "" : `${curTime}:00`;
        if (calType === "weekend") {
            endTime = "08:00";
            startTime = "17:00";
        }
        const durationDate = calType === "normal" ? durFromTs(dateToTs(value[0], startTime), dateToTs(value[1], endTime)) : duration;

        setDate({
            start: value[0],
            end: value[1],
            duration: durationDate,
            optionEnd: endTime,
            optionStart: startTime,
        })

        if (!isWorkTime(value[0], startTime, item.schedule)) {
            if (!overtime.has('pickup')) {
                overtime.add('pickup');
            }
        } else {
            if (overtime.has('pickup')) {
                overtime.delete('pickup')
            }
        }

        if (!isWorkTime(value[1], endTime, item.schedule)) {
            if (!overtime.has('drop_off')) {
                overtime.add('drop_off');
            }
        } else {
            if (overtime.has('drop_off')) {
                overtime.delete('drop_off')
            }
        }

        if (calType === "weekend") {
            setISelectedOption(true);
        } else {
            setISelectedOption(false);
        }

    }

    const handleSetDate = (date: any) => {
        setDate(date);
    }

    const getSuccess = () => {
        switch (+success) {
            case -1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Something wrong.')}</h1>
                        <span>{label('Please try again later.')}</span>
                        <div className={"btn btn-secondary"} onClick={resetErrorLayer}>OK</div>
                    </div>
                )
            case 1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order in progress.')}</h1>
                        <span className="loader"/>
                    </div>
                )
            case 2:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order successfully accepted.')}</h1>
                        {method === 'online_card' && invoiceUrl && methodItem ?
                            <div className={"bike-details_checkout_success_link"}>
                                <span>{label('The transition to payment will be made automatically or click the link.')}</span>
                                <a href={invoiceUrl} target={methodItem.target}>{label('link')}</a>
                            </div> : <span>{label('We will contact you shortly..')}</span>}
                    </div>
                )
        }
    }

    const resetErrorLayer = () => {
        dispatch(resetSuccess());
    }

    return <>
        <div className={"bike-details_checkout "}>
            {paid && <OrderModal
                onClose={closePaid}
            />}
            <div className={"bike-details_checkout_layer " + (!success ? "disable_layer" : "")}>
                {getSuccess()}
            </div>
            {!!item.avail && !page && <div className={"bike-details_item_date"} style={{position: "relative"}}>
                <DataPicker onSelected={handleDate} type={calType} setType={setType}/>
            </div>}
            <div className={"bike-details_checkout"}>
                {paid && <OrderModal
                    onClose={closePaid}
                />}
                <SelectTime
                    setDate={handleSetDate}
                    setISelectedOption={handleSetISelectedOption}
                    date={date}
                    isSelectedOption={isSelectedOption}
                    type={calType}
                    detail={item}
                    overtime={overtime}

                />
                <div className="checkout_page">
                    {getPageData()}
                    <div className="date-select">
                        <div className="date-select-item">
                            <OrderCalc
                                total={total.total}
                                price={total.price}
                                date={date}
                                extras={extras}
                                extra={item.extra}
                                insurances={item.insurances}
                                insurances_values={insurances}
                                accessor={accessor}
                                equip={item.equip}
                                promo={item.promo}
                                checkPromoError={checkPromoError}
                                checkPromo={checkPromo}
                                promoDiscount={total.promoDiscount}
                                overtime={item.overtime}
                                overtime_value={overtime}
                                mileage={item.mileage}
                                calType={calType}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

