import React, {FC, Fragment} from 'react';
import {ExtraItem, Extras} from "../../types";

import {label} from "../../service";
import {SetT} from "../../hooks/use-map";
import {CheckBox} from "../common/checkbox";

interface ExtraProps {
    onChange: (id: number) => void
    extra: Extras[]
    extras:SetT<number>
}

export const Extra: FC<ExtraProps> = ({onChange,extra,extras}) => {
    return (
            <Fragment>
                {
                    extra.map((item) => {
                        let priceName = item.price>0?`€${item.price}`:label('Free');
                        return (
                            <CheckBox key={item.id}
                                      onChange={() => {
                                          onChange(item.id)
                                      }}
                                      label={item.name}
                                      name={item.name}
                                      price={priceName}
                                      checked = {extras.has(item.id)}
                            />
                        )
                    })
                }
            </Fragment>
    )
}