import React, {FC, Fragment} from "react";
import {label} from "../../service";

import {TourItemInsurance} from "../../types/tourItem";
import {CheckBox} from "../common/checkbox";

export const CheckoutInsurance: FC<insuranceProps> = ({onChange, insurances, rules,checkoutPrice,duration}) => {
    if(!insurances.length){
        return null;
    }

    const getInsurancePrice = (item: any) => {
        let formatString = "";
        if (+(item.price) > 0) {
            switch (+item.one_time) {
                case 1: /// for period
                    formatString = `€${Math.round(item.price * 100) / 100}`;
                    break;
                case 0: /// per day
                default:
                    switch (checkoutPrice) {
                        case 'total':
                            formatString = `€${Math.round(item.price * duration * 100) / 100}`;
                            break;
                        case 'day':
                        default:
                            formatString = `€${item.price} / ${label('Day')}`;
                    }
                    break;
            }

        } else {
            formatString = `${label('Free')}`;
        }

        return formatString;
    }

    return (
            <Fragment>
                {
                    insurances.map((item) => {
                        let checked = rules.find((rule:{id:number,status:number})=>+rule.id === item.id  )
                        return (
                            <CheckBox key={item.id}
                                      onChange={() => {
                                          onChange(item.id)
                                      }}
                                      label={item.name}
                                      name={item.name}
                                      price={getInsurancePrice(item)}
                                      checked={!!checked}
                            />
                        )
                    })
                }
            </Fragment>
    )
}

interface insuranceProps {
    onChange: (id: number) => void
    insurances: TourItemInsurance[]
    rules: any[]
    checkoutPrice: string
    duration: number
}