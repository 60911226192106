import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {api} from "../../service";
import {TermsState} from "../../types/terms";


export const fetchTerms = createAsyncThunk(
    'fetchTerms',
    async (thunkAPI)  => {
        return await api.getTerms() as TermsState;
    }
)

const initialState:TermsState ={
    info: '',
    load:false
};

export const termsSlice = createSlice({
    name:"terms",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchTerms.fulfilled,(state,action)=>{
            state.info =  action.payload.info;
            state.load =  true;
        })
    }
})

export const {} = termsSlice.actions;
export const termsReducer = termsSlice.reducer;