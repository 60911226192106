import React from "react";
import {useAppSelector} from "../../app/hooks";
import {TourInfoPicture} from "./TourInfoPicture";
import {label} from "../../service";

export const TourInfoDescription = () => {
    const info = useAppSelector(state => state.toursItem.info);
    const images = useAppSelector(state => state.toursItem.images);

    if(!info.name){
        return null;
    }

    return (
        <div className={"tour_info_desc"}>
            <h2>{label('Tour Information')}</h2>
            {images.gallery.length > 0 && <TourInfoPicture images={images.gallery}/>}
            {info.info && <div className={"tour_info_desc_area"}>
                <div className={"tour_info_desc_text text_desc"}>{info.info}</div>
                {false && images.gallery.length > 0 && <div className={"tour_info_desc_image"}>
                    <img src={images.gallery[0].image_medium}/>
                </div>}
            </div>}
        </div>
    )
}