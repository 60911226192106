import React, {FC, useState} from "react";
import {TourItemDate, TourItemReserveItem, TourItemReserveLeftItem} from "../../types/tourItem";
import {label} from "../../service";
import moment from "moment";
import {useAppSelector} from "../../app/hooks";
import {prepareDate} from "../../utils/tourInfo";
import TourWaitingModal from "./TourWaitingModal";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

interface overviewDateProps {
    date: TourItemDate,
    handleBookBike: (id: number,bike_id:number) => void
}

export const TourInfoOverviewDate: FC<overviewDateProps> = ({date,handleBookBike}) => {
    const [waiting,setWaiting] = useState(false);
    const [showReserved,setShowReserved] = useState(false);
    const info = useAppSelector(state => state.toursItem.info);
    const waitSuccess = useAppSelector(state => state.toursItem.waitSuccess);

    const handleWaiting = () => {
        setWaiting(true);
    }

    const closeWaiting = () => {
        setWaiting(false);
    }

    const reserves = useAppSelector(state => state.toursItem.reserves);
    const current_language = useAppSelector(state => state.common.current_language);

    const tourReserve = reserves[date.id];
    const sold_out = date.left_bikes === 0;
    return (
        <div className={"tour_info_overview_item"}>
            {waiting && <TourWaitingModal
                onClose={closeWaiting}
                date={date}
                info={info}
                success={waitSuccess}
            />}
            <div className={"tour_info_overview_item_desc_area"}>
                <div className={"tour_info_overview_item_desc"}>
                    <h3>{prepareDate(date.start_ts, date.end_ts,current_language)}</h3>
                    <div className={"tour_info_overview_item_highlights"}>
                        <label>{label('Total Places in Tour')}</label>
                        <div className={"value"}>{date.count_bikes}</div>
                    </div>
                    <div className={"tour_info_overview_item_highlights"}>
                        <label>{label('Left Places in Tour')}</label>
                        <div className={"value "+(sold_out ? "danger_text":"")}>{date.left_bikes}</div>
                    </div>
                    <div className={"tour_info_overview_item_button"}>
                        {!sold_out ? <Link className={"btn btn-danger"}
                                           to={'/tours/' + info.id + '/book?tour_id=' + date.id}
                                           // onClick={() => {handleBook(date.id)}}
                            >
                                {label('Book Now')}
                            </Link>
                            :
                            <>
                                <div className={"btn btn-danger not-allowed opacity-75"}>{label('Sold Out')}</div>
                                <div className={"btn btn-info"} onClick={handleWaiting}>
                                    {label('Add to Waiting List')}
                                </div>

                            </>
                        }
                        {tourReserve && tourReserve.reserve.length > 0 && <div className={"btn btn-success"} onClick={()=>{setShowReserved(!showReserved)}}>{showReserved?label('Hide reserved motorcycles'):label('Show reserved motorcycles')}</div>}
                    </div>
                    {(tourReserve && tourReserve.reserve.length > 0 && showReserved) &&
                        <div className={"tour_info_overview_column_data_date_reserve_reserve"}>
                            <div className={"tour_info_overview_column_data_date_reserve_header "}>{label('Reserved motorcycles')}</div>
                            {tourReserve.reserve.map((item: TourItemReserveItem,key) => {
                                return (
                                    <div className={"tour_info_overview_column_data_date_reserve_item "} key={key}>
                                        <div
                                            className={"tour_info_overview_column_data_date_reserve_item_name "}>{item.model}</div>
                                        <div
                                            className={"tour_info_overview_column_data_date_reserve_item_value "}>{item.user}</div>
                                    </div>
                                )
                            })}
                        </div>}
                </div>
                <div className={"tour_info_overview_item_gallery"}>
                    {tourReserve && <div className={"tour_info_overview_column_data_date_reserve "}>
                        {tourReserve.left.length > 0 &&
                            <div className={"tour_info_overview_column_data_date_reserve_left"}>
                                <div className="checkout_bikes_rows">
                                    {tourReserve.left.map((left:TourItemReserveLeftItem) => {
                                        return (
                                            <div className="checkout_bikes_row"
                                                  key={left.id}
                                                  onClick={() => {handleBookBike(date.id,left.id)}}
                                            >
                                                <div className="checkout_bikes_row_name">{left.model}</div>
                                                <div className="checkout_bikes_row_image">
                                                    <img src={left.image} alt="{left.model}" />
                                                </div>
                                                <div className="checkout_bikes_row_count">{label('Left')+': '+left.count}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export const DatesOverview = () => {
    const info = useAppSelector(state => state.toursItem.info);
    const dates = useAppSelector(state => state.toursItem.dates);

    const navigate = useNavigate();

    // const handleBookDate = (dateId: number) => {
    //     navigate('/tours/' + info.id + '/book?tour_id=' + dateId);
    // }
    const handleBookBike = (dateId: number,bikeId: number) => {
        navigate('/tours/' + info.id + '/book?tour_id=' + dateId+'&bike_id='+bikeId);
    }

    if (Object.keys(dates).length==0) {
        return <div />;
    }

    return <div className={"tour_info_overview_column"}>
        <h5>{label('Dates')}</h5>
        <div className={"tour_info_overview_items dates_column"}>
            {
                Object.keys(dates).map((i) => {
                    // @ts-ignore
                    const date: TourItemDate = dates[i];
                    return (
                        <TourInfoOverviewDate
                            key={date.id}
                            date={date}
                            handleBookBike = {handleBookBike}
                        />
                    )
                })
            }
        </div>
    </div>;
}