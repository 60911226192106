import React from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {AccessoriesSizes} from "../bike/AccessoriesSizes";
import {label} from "../../service";
import {updateAccessoriesSize, updateStage} from "../../features/packageBikeDetails/packageBikeDetailsSlice";

export const PackageOrderCheckoutEquipSize = () => {
    const values = useAppSelector(state => state.packageBikeDetails.values);
    const equip = useAppSelector(state => state.packageBikeDetails.equip);

    let needSize = false;
    equip.forEach(item => {
        if (values.accessor[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    const dispatch = useAppDispatch();

    const handleBack = () => {
        dispatch(updateStage({stage: 1}));
    }

    const handleNext = () => {
        dispatch(updateStage({stage: 3}));

    }

    const handleAccessorySize = (id:number,number:number,size:string) => {
        dispatch(updateAccessoriesSize({id,number,size}))
    }

    return (
        <div className={"checkout"}>
            <div className="selected-block">
                <div className="main-data">
                    {<AccessoriesSizes
                        equip = {equip}
                        accessor = {values.accessor}
                        onAccessoryChangeSize = {handleAccessorySize}
                    />}
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-secondary"}
                     onClick={handleNext}>{label('Next')}</div>
            </div>
        </div>
    )
}