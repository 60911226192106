import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {TourState} from "../../types/tour";

export const fetchTourList = createAsyncThunk(
    'fetchTourList',
    async (thunkAPI)  => {
        return await api.getTourList() as TourState;

    }
)

const initialState:TourState ={
    cards:[],
    cities:{},
    days:{},
    dayFilter:0,
};

export const toursSlice = createSlice({
    name:"tours",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setDayFilter:(state,action:PayloadAction<{day:number}>)=>{
            if(+state.dayFilter === +action.payload.day){
                return {...state,dayFilter:0}
            }else{
                return {...state,dayFilter:+action.payload.day}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTourList.fulfilled,(state,action)=>{
            state.cards =  action.payload.cards;
            state.cities =  action.payload.cities;
            state.days =  action.payload.days;
        })
    }
})

export const {setDayFilter } = toursSlice.actions;
export const toursReducer = toursSlice.reducer;