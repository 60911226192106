import React, {FC, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {updateUser} from "../../features/packages/packagesSlice";
import {UserError} from "../../types/packages";
import {UserData} from "../bike/UserData";
import {UserProfile} from "../../types/login";
import {CountryItem} from "../../types/common";

interface userProps {
    error:UserError
    profile:UserProfile
    states:CountryItem[]
    checkEmailError:boolean
}

export const CheckoutPackageUser:FC<userProps> = ({error,profile,states,checkEmailError}) => {
    const user = useAppSelector(state => state.packages.user);
    const values = useAppSelector(state => state.packages.values);

    const dispatch = useAppDispatch();

    const handleUserChange = (name:string,value:any) => {
        dispatch(updateUser({name:name,value:value}));
    }

    const handleChangeInput = (name:string,value:any) => {
        handleUserChange(name,value);
    }

    let disabled = +values.package_id === 0;

    return (
        <div className={"main-data "}>
            {disabled && <div className={"disabled-data "} /> }
            <UserData error={error} form={user} onFormChange={handleChangeInput} profile={profile} states={states} checkEmailError={checkEmailError}/>
        </div>
    )
}