import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {label} from "../../service";
import {Visitors} from "../bike/Visitors";

export const Carousel = ({items, mobile}) => {
    const [galleryItems, setGalleryItems] = useState([]);
    const [galleryClasses, setGalleryClasses] = useState([]);
    const [selectedItem, setSelectedItem] = useState(1);
    const countItems = items.length;

    useEffect(() => {
        if (!galleryItems.length && items.length) {
            let cls = [];
            items.forEach((item, index) => {
                item.number = index + 1;
                cls.push(item.number);
            });
            setGalleryItems(items.slice());
            setGalleryClasses(cls.slice());
        }
    }, [items]);

    const prev = () => {
        galleryClasses.push(galleryClasses.shift());
        setGalleryClasses(galleryClasses.slice());
        updateClasses();
    }
    const next = () => {
        galleryClasses.unshift(galleryClasses.pop());
        setGalleryClasses(galleryClasses.slice());
        updateClasses();
    }

    const handleBike = (item) => {
        /*        if (window._common.mobile) {
                    navigate("/p/" + item.id);
                } else {
                    if (item.number === 1) {
                        navigate("/p/" + item.id);
                    } else */
        if (item.number === 3) {
            next();
        } else if (item.number === 1) {
            prev();
        }
        /*  }*/
    }

    const updateClasses = () => {
        galleryItems.forEach((item, index) => {
            item.number = galleryClasses[index];
            if (item.number === 1) {
                setSelectedItem(item.index);
            }
        });
        setGalleryItems(galleryItems.slice());
    }

    const getGalleryItemImage = (item) => {
        if (mobile || +item.number === 2) {
            return (
                <Link className="gallery_item_image"
                      to={"/p/" + item.id}
                >
                    <img src={item.img} alt={label('Rent a Motorcycle')+' '+item.name+' '+label('in Bratislava')}/>
                </Link>
            )
        } else {
            return (
                <div className="gallery_item_image" onClick={() => {
                    handleBike(item)
                }}>
                    <img src={item.img} alt={''}/>
                </div>
            )
        }
    }

    return (
        <div className="gallery">
            <div className="gallery_container">
                {
                    galleryItems.map((item, index) => {
                        return <div key={index}
                                    className={"gallery_item gallery_item_" + (item.number ? item.number : index + 1)}>
                            <div className="gallery_item_header">
                                <div className={"gallery_item_info"}>
                                    <h1>{item.name}</h1>
                                    <div className={"bike_details_header_price"}>{label('from')} {item.price_from}</div>
                                </div>
                                <div className={"gallery_item_details"}>
                                    <Link className={"btn_more"} to={`/p/${item.id}`}>{label('Details')}</Link>
                                </div>
                            </div>
                            {getGalleryItemImage(item)}
                            <Visitors item={item}/>

                        </div>
                    })
                }
            </div>
            <div className="gallery_controls">
                <div className="gallery_controls_buttons">
                    <button onClick={prev}>&lt;</button>
                    <span>{selectedItem}/{countItems}</span>
                    <button onClick={next}>&gt;</button>
                </div>
            </div>
        </div>
    );
}