import React, {FC, useState} from "react";
import {label} from "../../service";
import {MainInput} from "../tools/MainInput";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {updateUser} from "../../features/certificate/certificateSlice";
import {UserError} from "../../types/certificate";

interface userProps {
    error:UserError
}

export const CheckoutUserBlock:FC<userProps> = ({error}) => {
    const user = useAppSelector(state => state.certificate.user);
    const values = useAppSelector(state => state.certificate.values);

    const dispatch = useAppDispatch();

    const handleUserChange = (name:string,value:any) => {
        dispatch(updateUser({name:name,value:value}));
    }

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUserChange(e.target.name,e.target.value);
    }

    let disabled = +values.image_id === 0 || values.text === '' || +values.price === 0;

    return (
        <div className={"main-data "}>
            {disabled && <div className={"disabled-data "} /> }
            <div className={"personal_info"}>
                <div className={"heading"}>{label('Personal Info')}</div>
                <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('Your Name')}*</div>
                    <MainInput name={"name"} error={error.name} value={user.name} onChange={handleChangeInput}
                               placeHolder={label('Your Name')}/>
                </div>
                <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('Email Address')}*</div>
                    <MainInput name="email" error={error.email} value={user.email} onChange={handleChangeInput}
                               placeHolder={label('Email Address')}/>
                </div>
                <div className={"personal_info_item input-phone"}>
                    <div className={"personal_info_item_label"}>{label('Phone Number')}*</div>
                    <MainInput name={"phone"} error={error.phone} value={user.phone} onChange={handleChangeInput}
                               placeHolder={"000-000-000000"}/>
                </div>
                {false && <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('City')}</div>
                    <MainInput name={"city"} error={error.city} value={user.city} onChange={handleChangeInput}
                               placeHolder={label('City') }/>
                </div>}
                {false && <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('Zip Code')}</div>
                    <MainInput name={"zipcode"} error={error.zip_code} value={user.zip_code} onChange={handleChangeInput}
                               placeHolder={label('Zip Code') }/>
                </div>}
                {false && <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('Address')}</div>
                    <MainInput name={"street"} error={error.address} value={user.address} onChange={handleChangeInput}
                               placeHolder={label('Address') }/>
                </div>}
            </div>
        </div>
    )
}