import React, {FC, useState} from "react";
import {label} from "../../service";
import {FaPlus} from "react-icons/fa";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Price} from "../../types/certificate";
import {CheckBox} from "../common/checkbox";
import {checkPrice} from "../../features/certificate/certificateSlice";

export const CheckoutPriceBlock = () => {
    const prices = useAppSelector(state => state.certificate.prices);
    const values = useAppSelector(state => state.certificate.values);

    const dispatch = useAppDispatch();

    const handlePrice = (price: number) => {
        dispatch(checkPrice({price}))
    }

    let disabled = +values.image_id === 0 || values.text === '';

    return (
        <div className={"main-data " + (disabled ? "disabled-block ":"")}>
            {disabled && <div className={"disabled-data "} /> }
            <div className={"accessor"}>
                <div className={"heading"}>{label('Gift Amount')}</div>
                <div className="total-checked">
                    <div className={"rental_values radio_values"}>
                        {
                            prices.map((item: Price) => {
                                const handleChange = () => {
                                    handlePrice(item.price)
                                }
                                return (
                                    <CheckBox key={item.value}
                                              onChange={handleChange}
                                              label={'€'+item.price}
                                              name={''}
                                              price={'€'+item.price}
                                              checked={values.price === +item.price}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}