import React, {Fragment} from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {PackageItem} from "../../types/myPackages";
import {MyPackagesItem} from "./MyPackagesItem";
import "./myPackages.scss";

export const MyPackagesContent = () => {
    const myPackages = useAppSelector(state => state.myPackages);
    const {load,list} = myPackages;

    if (!load) {
        return null;
    }

    return (
        <div className={"my_packages"}>
            <h1 className="profile_heading">{label('My Packages')}</h1>
            <ul className={"my_packages_list"}>
                {list.length > 0 ?
                    <Fragment>
                        {list.map((item:PackageItem)=>{
                            return <MyPackagesItem
                                key={item.id}
                                item={item}
                            />
                        })}
                    </Fragment>
                    :
                    <div className={"my_packages_list_empty"}>{label('You have not packages')}</div>
                }
            </ul>
        </div>
    )
}