import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchLogoutUser, showLogin} from "../../features/login/loginSlice";
import {FaChevronDown, FaRegUserCircle, FaUserAlt} from "react-icons/fa";
import {label} from "../../service";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";

const UserProfile = () => {
    const [hover, setHover] = useState(false);
    const [hovered, setHovered] = useState(false);

    const profile = useAppSelector(state => state.login.profile);
    const my_packages = useAppSelector(state => state.common.my_packages);

    const dispatch = useAppDispatch();

    const handleLogin = () => {
        if (+profile.user_id === 0) {
            // @ts-ignore
            dispatch(showLogin());
        }
    }

    const handleLogout = () => {
        if (+profile.user_id > 0) {
            // @ts-ignore
            dispatch(fetchLogoutUser());
        }
    }

    const handleHover = () => {
        if (+profile.user_id > 0) {
            setHover(true);
            setHovered(true);
        }
    }

    const handleLeave = () => {
        if (+profile.user_id > 0) {
            setHover(false);
        }
    }

    const navigate = useNavigate();
    const handleLink = (link:string) => {
        setHover(false);
        setHovered(false);
        navigate(link);
    }

    useEffect(()=> {
        if(!hover){
            setTimeout(()=>{setHovered(false)},200);
        }
    },[hover]);

    useEffect(()=> {
        if(+profile.user_id === 0){
            setHover(false);
            setHovered(false);
        }
    },[profile]);

    return (
        <div className={"profile "+(+profile.user_id >0 ? " logined " :"")+(+hovered ? " hovered " :"")}
             onMouseOver={handleHover}
             onMouseLeave={handleLeave}
        >
            <div className={"profile_row"}>
                <div className="profile_icon">
                    <FaUserAlt onClick={handleLogin} />
                </div>
                <div className="profile_down">
                    {+profile.user_id > 0 && <FaChevronDown />}
                </div>
            </div>
            {+profile.user_id > 0 && <div className={"profile_dropdown"}>
                <div className={"profile_dropdown_user"}>
                    <div className={"profile_dropdown_icon"}>
                        <FaRegUserCircle />
                    </div>
                    <div className={"profile_dropdown_name"}>{profile.name}</div>
                </div>
                {false && <h5 className={"profile_dropdown_header"}>{label('My Profile')}</h5>}
                <ul className={"profile_dropdown_list"}>
                    <li>
                        <div onClick={()=>{handleLink("/profile/orders")}}>{label('My Orders')}</div>
                    </li>
                    {my_packages && <li>
                        <div onClick={()=>{handleLink("/profile/packages")}}>{label('My Packages')}</div>
                    </li>}
                    <li>
                        <div onClick={()=>{handleLink("/profile/edit")}}>{label('Edit Profile')}</div>
                    </li>
                    <li className={"profile_logout"}
                        onClick={handleLogout}
                    >{label('Logout')}</li>
                </ul>
            </div>}
        </div>
    )
}

export default UserProfile;