import React, {FC, useEffect} from 'react';
import {UserData} from "./UserData";
import {CheckoutError} from "./Checkout";
import {User} from "../../types";
import {label} from "../../service";
import {UserProfile} from "../../types/login";
import {useAppDispatch} from "../../app/hooks";
import {fetchCheckUserEmail, resetCheckEmail} from "../../features/bike/bikeSlice";
import {CountryItem} from "../../types/common";

interface CheckoutPageTwoProps{
    error:CheckoutError,
    user:User,
    onFormChange:(name:string,value:any)=>void
    paymentPage:()=>void
    onBack:(page:number)=>void
    needSize:boolean
    checkEmail:boolean
    checkEmailError:boolean
    profile:UserProfile
    states:CountryItem[]
}

export const CheckoutPageTwo:FC<CheckoutPageTwoProps> = ({error,user,paymentPage,onFormChange,onBack,needSize,profile,states,checkEmail,checkEmailError}) => {
   // console.log('n',needSize)
    const handleBack = () => {
        onBack(needSize ? 1 : 0);
    }

    const dispatch = useAppDispatch();

    const handleNext = () => {
        if(+profile.user_id > 0){
            paymentPage();
        }else{
            dispatch(fetchCheckUserEmail(user));
        }
    }

    useEffect(()=>{
        return () => {
            dispatch(resetCheckEmail());
        }
    },[]);

    useEffect(()=>{
        if(checkEmail){
            paymentPage();
        }
    },[checkEmail]);

    const handleChange = (name:string,value:any) => {
        if(name === "email"){
            dispatch(resetCheckEmail())
        }
        onFormChange(name,value);
    }

    return (
        <div className="checkout">
            <div className="selected-block one">
                <div className="main-data">
                    <UserData error={error} form={user} onFormChange={handleChange} profile={profile} states={states} checkEmailError={checkEmailError}/>
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-secondary"}
                     onClick={handleNext}>{label('Next')}</div>
            </div>
        </div>
    );
};
