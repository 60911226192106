import React, {Fragment} from "react";
import {useAppSelector} from "../../app/hooks";
import {ProfileMenuItem} from "../../types/profile";
import {Link} from "react-router-dom";
import "./profile.scss";
import {FaEdit, FaRegListAlt, FaRegUserCircle} from "react-icons/fa";

const ProfileSide = () => {
    return (
        <aside className={"profile_side"}>
            <nav>
                <ProfileUser/>
                <ProfileMenu/>
            </nav>
        </aside>
    )
}

const ProfileUser = () => {
    const profile = useAppSelector(state => state.login.profile);

    return (
        <div className={"profile_user"}>
            <div className={"profile_user_item"}>
                <div className={"profile_user_icon"}>
                    <FaRegUserCircle/>
                </div>
                <div className={"profile_user_area"}>
                    <div className={"profile_user_name"}>{profile.name}</div>
                    <div className={"profile_user_mail"}>{profile.mail}</div>
                </div>
            </div>
        </div>
    )
}

const ProfileMenu = () => {
    const menu = useAppSelector(state => state.profile.menu);

    const getModeIcon = (mode: string) => {
        switch (mode) {
            case 'edit':
                return <FaEdit/>
            default:
                return <FaRegListAlt/>
        }
    }

    return <ul className={"profile_menu"}>
        {menu.map((item: ProfileMenuItem) => {
            return (
                <Fragment key={item.mode}>
                    {item.link ?
                        <Link to={item.link} className={"profile_menu_item " + (item.active ? " active_item " : "")}>
                            <span className={"profile_menu_item_icon "}>{getModeIcon(item.mode)}</span>
                            <div className={"profile_menu_item_name "}>{item.name}</div>
                            {item.url && <a href={item.url} className={"profile_menu_item_name "}>{item.name}</a>}
                        </Link>
                        :
                        <Fragment>
                            {item.url ? <a href={item.url}
                                           className={"profile_menu_item " + (item.active ? " active_item " : "")}>
                                    <span className={"profile_menu_item_icon "}>{getModeIcon(item.mode)}</span>
                                    <div className={"profile_menu_item_name "}>{item.name}</div>
                                    {item.url && <a href={item.url} className={"profile_menu_item_name "}>{item.name}</a>}
                                </a>
                                :
                                null
                            }
                        </Fragment>
                    }
                </Fragment>
            )
        })}
    </ul>
}

export default ProfileSide;