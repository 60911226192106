import React, {Fragment, useEffect} from "react";
import {useScrollTop} from "../hooks";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {label} from "../service";
import {fetchGalleryList} from "../features/gallery/gallerySlice";
import GalleryAlbums from "../components/gallery/GalleryAlbums";
import "./galery.scss";

const Gallery = () => {

    const albums = useAppSelector(state => state.gallery.albums);

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchGalleryList());
    }, []);

    return (
        <main className={"page gallery w-full"}>
            <div className={"gallery_wrap w-wrap"}>
                <div className="page-header">
                    <h1 className={"title"}>{label('Gallery')}</h1>
                </div>
                <div className={"gallery_area"}>
                    {albums.length > 0 && <GalleryAlbums/>}
                </div>
            </div>
        </main>
    )
}

export default Gallery;