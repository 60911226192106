import React, {FC, useState} from "react";
import {label} from "../../service";
import ReadTerms from "../bike/ReadTerms";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {MainInput} from "../tools/MainInput";
import {updateNote, updateStage} from "../../features/packageBikeDetails/packageBikeDetailsSlice";

interface componentProps{
    handleBook:()=>void
}
export const PackageOrderCheckoutFinal:FC<componentProps> = ({handleBook})  => {
    const values = useAppSelector(state => state.packageBikeDetails.values);
    const equip = useAppSelector(state => state.packageBikeDetails.equip);
    const extra = useAppSelector(state => state.packageBikeDetails.extra);

    const [agreeTerms, setTerms] = useState(false);

    const handleAgreeTerms = () => {
        setTerms(!agreeTerms);
    }

    const dispatch = useAppDispatch();

    let needSize = false;
    const isExtra = equip.length > 0 || extra.length > 0;
    equip.forEach(item => {
        if (values.accessor[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    const handleBack = () => {
        if(needSize){
            dispatch(updateStage({stage: 2}));
        }else if(isExtra){
            dispatch(updateStage({stage: 1}));
        }else{
            dispatch(updateStage({stage: 0}));
        }
    }
    const canBook =  agreeTerms;
    const onBook = () => {
        if (canBook) {
            handleBook();
        }
    }

    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateNote({note:e.target.value}));
    }

    return (
        <div className="checkout">
            <div className="selected-block one">
                <div className="main-data">
                    <div className={"personal_info_item note_item"}>
                        <div className={"personal_info_item_label"}>{label('Note to order')}</div>
                        <MainInput name={"note"} error={false} value={values.note} onChange={handleChangeInput}
                                   placeHolder={label('Note to order')}/>
                    </div>
                </div>
                <div className="note-data">
                    <ReadTerms onChange={handleAgreeTerms} checked={agreeTerms}/>
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-allow " + (!canBook ? "not-allowed opacity-25" : "")}
                     onClick={onBook}>{label('Confirm')}</div>
            </div>
        </div>
    )
}