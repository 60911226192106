import React, {FC} from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {TourItemRule} from "../../types/tourItem";

interface rulesProps{
    rules:TourItemRule[]
}
export const TourInfoRules:FC<rulesProps> = ({rules}) => {

    if(!rules.length){return null;}

    const not_incl = rules.filter(rule=>+rule.kind === 1);
    const incl = rules.filter(rule=>+rule.kind === 2);
    const req = rules.filter(rule=>+rule.kind === 3);

    return (
        <div className={"tour_info_rules"}>
            <div className={"tour_info_rules_wrap"}>
                <div className={"tour_info_rules_area"}>
                    {incl.length > 0 &&  <div className={"tour_info_rules_column"}>
                        <div className={"tour_info_rules_name"}>{label('What Is Included')}:</div>
                        <ul className={"tour_info_rules_items"}>
                            {incl.map(rule=>{
                                return (
                                    <li className={"tour_info_rules_items_row"}
                                        key={rule.id}
                                    >{rule.name}</li>
                                )
                            })}
                        </ul>
                    </div>}
                    {not_incl.length > 0 &&  <div className={"tour_info_rules_column"}>
                        <div className={"tour_info_rules_name"}>{label('Not Included In Price')}:</div>
                        <ul className={"tour_info_rules_items"}>
                            {not_incl.map(rule=>{
                                return (
                                    <li className={"tour_info_rules_items_row"}
                                        key={rule.id}
                                    >{rule.name}</li>
                                )
                            })}
                        </ul>
                    </div>}
                    {req.length > 0 &&  <div className={"tour_info_rules_column"}>
                        <div className={"tour_info_rules_name"}>{label('Requirements')}:</div>
                        <ul className={"tour_info_rules_items"}>
                            {req.map(rule=>{
                                return (
                                    <li className={"tour_info_rules_items_row"}
                                        key={rule.id}
                                    >{rule.name}</li>
                                )
                            })}
                        </ul>
                    </div>}
                </div>
            </div>
        </div>
    )
}