import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {GalleryAlbumState} from "../../types/galleryAlbum";

export const fetchGalleryAlbum = createAsyncThunk(
    'fetchGalleryAlbum',
    async (id:number)  => {
        return await api.getGalleryAlbum(id) as GalleryAlbumState;

    }
)


const initialState:GalleryAlbumState ={
    album: {
        album_id: 0,
        sort: 0,
        name: "",
        desc: ""
    },
    photos:[],
    load:false
};

export const galleryAlbumSlice = createSlice({
    name:"galleryAlbum",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetAlbum: (state) => {
            return {...state,
                album: {
                    album_id: 0,
                    sort: 0,
                    name: "",
                    desc: ""
                },
                photos:[],
                load:false
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGalleryAlbum.fulfilled,(state,action)=>{
            state.album =  action.payload.album;
            state.photos =  action.payload.photos;
            state.load =  action.payload.load;
        })
    }
})

export const {resetAlbum} = galleryAlbumSlice.actions;
export const galleryAlbumReducer = galleryAlbumSlice.reducer;