import React, {Fragment, useEffect, useState} from "react";
import {useScrollTop} from "../hooks";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {label} from "../service";
import {fetchGalleryAlbum, resetAlbum} from "../features/gallery/galleryAlbumSlice";
import {useParams} from "react-router";
import Gallery from "react-photo-gallery";
import {DayImage} from "../types/tourItem";
import {showGallery} from "../features/galleryViewer/galleryViewerSlice";

type photoT = {
    src: string
    width: number
    height: number
}

const GalleryAlbumPhotos = () => {
    let {id} = useParams();
    const album = useAppSelector(state => state.galleryAlbum.album);
    const photos = useAppSelector(state => state.galleryAlbum.photos);
    const load = useAppSelector(state => state.galleryAlbum.load);

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchGalleryAlbum(Number(id)));
        return ()=>{
            dispatch(resetAlbum());
        }
    }, []);

    if(!load){
        return   <main className={"page gallery w-full"}/>;
    }

    const handleImage = (event:any, args:any) => {
        dispatch(showGallery({images:JSON.parse(JSON.stringify(photos)),current:args.index || 0}));
    }

    return (
        <main className={"page gallery w-full"}>
            <div className={"gallery_wrap w-wrap"}>
                <div className="page-header">
                    <h1 className={"title"}>{album.name}</h1>
                </div>
                <div className={"gallery_area"}>
                    {photos.length > 0 && <Gallery
                        photos={JSON.parse(JSON.stringify(photos))}
                        targetRowHeight={300}
                        onClick={handleImage}
                        margin={5}
                    />}
                </div>
            </div>
        </main>
    )
}


export default GalleryAlbumPhotos;