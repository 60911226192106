import React, {FC, useEffect, useState} from "react";
import {label} from "../../service";
import {FaPlus} from "react-icons/fa";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Image} from "../../types/certificate";
import {checkImage} from "../../features/certificate/certificateSlice";
import {ImCheckboxChecked} from "react-icons/im";

export const CheckoutImageBlock = () => {
    const images = useAppSelector(state => state.certificate.images);
    const values = useAppSelector(state => state.certificate.values);

    const dispatch = useAppDispatch();

    const [mode, setMode] = useState('gallery');

    const handleImage = (image_id: number) => {
        dispatch(checkImage({image_id}))
    }

    const handleNew = () => {
        setMode('new')
    }

    return (
        <div className="main-data">
            <div className={"accessor"}>
                <div className={"heading"}>{label('Voucher Image')}</div>
                <div className={"certificate_images"}>
                    {false && <div className={"certificate_images_item new_image"} onClick={handleNew}>
                        <FaPlus/>
                    </div>}
                    {images.map((image) => {
                        return <ImageItem
                            key={image.id}
                            image={image}
                            checked={image.id === values.image_id}
                            handleImage={handleImage}
                        />
                    })}
                </div>
            </div>
        </div>
    )
}

interface imageProps {
    image: Image
    checked: boolean
    handleImage: (image_id: number) => void
}

const ImageItem: FC<imageProps> = ({image, checked, handleImage}) => {

    const handleClick = () => {
        handleImage(image.id);
    }

    return (
        <div className={"certificate_images_item  " + (checked ? " checked_image " : "")} onClick={handleClick}>
            <img src={image.image_small}/>
            {checked && <div className={"certificate_images_item_check  "}><ImCheckboxChecked /></div>}
        </div>
    )
}