import React, {useEffect} from 'react';
import "./tours.scss";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {TourDaysFilter, ToursCards} from "../components/tours";
import {fetchTourList} from "../features/tours/toursSlice";
import {label} from "../service";
import {useScrollTop} from "../hooks";


const TourPromoText = () => {
    return <article className="promo">
        <p className={"promo_subtitle"}>
            {label('Tours are carefully created to provide versatility and uniqueness.')}
        </p>
        <p>
            {label('each_individual_tour')}
        </p>
        <p className={"promo_notes"}>
            *&nbsp;
            {label('all_of_the_tour')}
        </p>
    </article>
}

export const Tours = () => {
    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(fetchTourList());
    },[]);

    useScrollTop();

    return (
        <main className="page tours w-full">
            <div className={" tours_wrap w-wrap"}>
                <div className="page-header">
                    <h1 className={"title"}>{label('Moto Tours')}</h1>
                </div>
                 <ToursCards />
                <TourPromoText/>
            </div>
        </main>
    );
};
