import React from "react";
import {useAppSelector} from "../../app/hooks";
import {TourItemBike, TourItemCountry, TourItemDate, TourItemRule} from "../../types/tourItem";
import {Link} from "react-router-dom";
import StarRange from "../common/StarRange";
import {label} from "../../service";
import {useNavigate} from "react-router";
import {TooltipOverviewItem} from "./TooltipOverviewItem";

export const TourInfoMain = () => {

    const {rules,countries,info,bikes,dates} = useAppSelector(state => state.toursItem);

    let min_price = 0;
    bikes.forEach((bike: TourItemBike) => {
        if (+bike.price_day > 0) {
            let bike_price = (+bike.price_day) * (+info.days_number);
            if(min_price === 0){
                min_price = bike_price;
            }else{
                min_price = Math.min(min_price,bike_price);
            }
        }
    });

    rules.forEach((rule:TourItemRule)=>{
        if(+rule.kind === 2){
            if(+rule.one_time === 1){
                min_price += +rule.price;
            }else{
                min_price += (+rule.price) * (+info.days_number);
            }
        }
    });

    // const navigate = useNavigate();
    // const handleBook = () => {
    //     navigate('/tours/'+info.id+'/book');
    // }
    // const handleContact = () => {
    //     navigate('/contacts');
    // }

    if(!info.name){
        return null;
    }

    let canBook = false;
    dates.forEach((date:TourItemDate)=>{
        if(date.left_bikes > 0){
            canBook = true;
        }
    });

    return (
        <nav className={"tour_info_main"}>
            <div className={"tour_info_main_items"}>
                {countries.length > 0 && <div className={"tour_info_main_item"}>
                    <div className={"tour_info_main_item_data"}>
                        <small>{label('Countries')}</small>
                        <div className={"tour_info_main_item_data_name countries_name"}>
                            {countries.map((country: TourItemCountry) => {
                                return (
                                    <TooltipOverviewItem
                                        key={country.id}
                                        name={country.name}
                                        image={country.image}
                                        info={country.info}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>}
                {info.days_number > 0 && <div className={"tour_info_main_item"}>
                    <div className={"tour_info_main_item_data"}>
                        <small>{label('Duration')}</small>
                        <div className={"tour_info_main_item_data_name "}>
                            {`${info.days_number} ${label('Days')} `}
                        </div>
                    </div>
                </div>}
                {+info.difficulty > 0 && <div className={"tour_info_main_item"}>
                    <div className={"tour_info_main_item_data"}>
                        <small>{label('Difficulty')}</small>
                        <div className={"tour_info_main_item_data_name "}>
                            <StarRange max={5} value={+info.difficulty} canHover={false}/>
                        </div>
                    </div>
                </div>}
                {!!info.price  && +info.days_number > 0 && <div className={"tour_info_main_item"}>
                    <div className={"tour_info_main_item_data"}>
                        <small>{label('Price from')}</small>
                        <div className={"tour_info_main_item_data_name "}>
                            {`€${info.price}`}
                        </div>
                    </div>
                </div>}
            </div>
            <div className={"tour_info_main_button"}>
                {canBook ?
                    <Link className={"btn_more"}
                          to={'/tours/'+info.id+'/book'}
                          // onClick={handleBook}
                    >{label('Book Now')}
                    </Link>
                    :
                    <Link className={"btn_more"}
                          to={'/contacts'}
                          // onClick={handleContact}
                    >{label('Contact Us')}
                    </Link>
                }
                {false && <div className={"btn btn-danger not-allowed opacity-75"}>{label('Sold Out')}</div>}
            </div>
        </nav>
    )
}