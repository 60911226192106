import React, {Fragment} from "react";
import {label} from "../../service";
import "./orders.scss";
import {useAppSelector} from "../../app/hooks";
import {OrdersListItem} from "../../types/orders";
import OrderItem from "./OrderItem";

const OrdersContent = () => {
    const orders = useAppSelector(state => state.orders);
    if(!orders.load){return null;}
    return (
        <div className={"orders"}>
            <h1 className="profile_heading">{label('My Orders')}</h1>
            <ul className={"orders_list"}>
                {orders.list.length > 0 ?
                    <Fragment>
                        {orders.list.map((item:OrdersListItem)=>{
                                return <OrderItem
                                    key={item.id}
                                    order={item}
                                />
                        })}
                    </Fragment>
                    :
                    <div className={"orders_list_empty"}>{label('You have not orders')}</div>
                }
            </ul>
        </div>
    )
}

export default OrdersContent;