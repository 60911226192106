import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {MyPackagesState} from "../../types/myPackages";

export const fetchMyPackages = createAsyncThunk(
    'fetchMyPackages',
    async (thunkAPI)  => {
        return await api.getMyPackages() as MyPackagesState;
    }
)

const initialState:MyPackagesState ={
    list:[],
    load:false
};

export const myPackagesSlice = createSlice({
    name:"myPackages",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchMyPackages.fulfilled,(state,action)=>{
            state.list = action.payload.list;
            state.load = true;
        })
    }
})

export const {} = myPackagesSlice.actions;
export const myPackagesReducer = myPackagesSlice.reducer;