import React, {useEffect} from "react";
import {useScrollTop} from "../hooks";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {fetchOrdersList} from "../features/orders/ordersSlice";
import {fetchProfileData} from "../features/profile/profileSlice";
import ProfileSide from "../components/common/ProfileSide";
import OrdersContent from "../components/orders/OrdersContent";
import {useNavigate} from "react-router";

const Orders = () => {

    const profile = useAppSelector(state=>state.login.profile);

    useScrollTop();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(fetchProfileData('orders'));
        dispatch(fetchOrdersList());
    },[]);

    useEffect(()=>{
        if(+profile.user_id === 0){
            navigate('/');
        }
    },[profile]);

    return (
        <main className={"page"}>
            <div className={"profile_content w-wrap"}>
                <ProfileSide />
                <div className={"profile_main"}>
                    <OrdersContent />
                </div>
            </div>
        </main>
    )
}

export default Orders;