import React, {FC, useState} from "react";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import {Mousewheel, Navigation, Thumbs} from "swiper";
import {label} from "../../service";
import {useAppDispatch} from "../../app/hooks";
import {showGallery} from "../../features/galleryViewer/galleryViewerSlice";
import {Gallery} from "../../types/tourItem";

interface bikeItemProps {
    images: Gallery[]
}


export const TourInfoPicture: FC<bikeItemProps> = ({images}) => {
    const [imagesNavSlider, setImagesNavSlider] = useState(null);
    const [image, setImages] = useState({
        id: images[0] !== undefined ? images[0].id : 0,
        image: images[0] !== undefined ? images[0].image : '',
        image_medium: images[0] !== undefined ? images[0].image_medium : '',
        image_small: images[0] !== undefined ? images[0].image_small : '',
        index: 0,
    });

    const dispatch = useAppDispatch();

    let photos = [] as Gallery[];
    photos = images;

    const handleShowGalleryViewer = () => {
        dispatch(showGallery({images: photos, current: image.index}));
    }

    const handleShowImage = (item: Gallery, index: number) => {
        setImages(
            {
                id: item.id,
                image: item.image,
                image_medium: item.image_medium,
                image_small: item.image_small,
                index: index,
            }
        )
    }

    return (
        <div className={"tour_info_picture"}>
            <div className={"tour_info_picture_area"}>
                <div className={"tour_info_picture_main"}>
                    {false && <Swiper
                        thumbs={{swiper: imagesNavSlider}}
                        direction="horizontal"
                        slidesPerView={1}
                        spaceBetween={32}
                        mousewheel={true}
                        navigation={{
                            nextEl: ".slider__next",
                            prevEl: ".slider__prev"
                        }}
                        breakpoints={{
                            0: {
                                direction: "horizontal"
                            },
                            768: {
                                direction: "horizontal"
                            }
                        }}
                        className="swiper-container2"
                        modules={[Navigation, Thumbs, Mousewheel]}
                    >
                        {photos.map((slide, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="slider__image">
                                        <img src={slide.image_medium} alt=""
                                             onClick={() => {
                                                 handleShowGalleryViewer()
                                             }}
                                        />
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>}
                    <img src={image.image} alt={''}
                         onClick={() => {
                             handleShowGalleryViewer()
                         }}
                    />
                </div>
                {photos.length > 1 && <div className={"tour_info_picture_thumbnail"}>
                    <div className="slider__prev">{label('Prev')}</div>

                    <div className="slider__thumbs">
                        <Swiper
                            // @ts-ignore
                            /* _swiper={setImagesNavSlider}*/
                            direction="vertical"
                            spaceBetween={15}
                            slidesPerView={3}
                            navigation={{
                                nextEl: ".slider__next",
                                prevEl: ".slider__prev"
                            }}
                            mousewheel={true}
                            freeMode={true}
                            className="swiper-container1"
                            breakpoints={{
                                0: {
                                    direction: "horizontal"
                                },
                                768: {
                                    direction: "vertical"
                                }
                            }}
                            modules={[Navigation, Thumbs, Mousewheel]}
                        >
                            {photos.map((slide, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="slider__image">
                                            <img src={slide.image_small} alt=""
                                                 onClick={() => {
                                                     handleShowImage(slide, index)
                                                 }}
                                            />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                    <div className="slider__next">{label('Next')}</div>
                </div>}
            </div>
        </div>
    )
}