import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {api} from "../../service";
import {VacancyState} from "../../types/vacancy";


export const fetchVacancy = createAsyncThunk(
    'fetchVacancy',
    async (thunkAPI)  => {
        return await api.getVacancy() as VacancyState;
    }
)

const initialState:VacancyState ={
    info: '',
};

export const vacancySlice = createSlice({
    name:"vacancy",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchVacancy.fulfilled,(state,action)=>{
            state.info =  action.payload.info;
        })
    }
})

export const {} = vacancySlice.actions;
export const vacancyReducer = vacancySlice.reducer;