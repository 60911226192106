import React, {FC, useState} from "react";
import {BikeDetail} from "../../types";
import {useAppDispatch} from "../../app/hooks";
import {showGallery} from "../../features/galleryViewer/galleryViewerSlice";
import {Gallery} from "../../types/tourItem";
import {GallerySlider} from "../common/GallerySlider";
import {label} from "../../service";

interface bikeItemProps {
    item: BikeDetail
}

export const BikeItemPictures: FC<bikeItemProps> = ({item}) => {
    const [image, setImages] = useState({
        id: 0,
        image: item.img,
        image_medium: item.img,
        image_small: item.img,
        index: 0,
    });

    const dispatch = useAppDispatch();

    let photos = [] as Gallery[];
    photos = item.gallery;
    let row = {} as Gallery;
    row.id = 0;
    row.image_small = item.img;
    row.image_medium = item.img;
    row.image = item.img;
    photos = [row, ...photos];

    const handleShowGalleryViewer = () => {
        dispatch(showGallery({images: photos, current: image.index}));
    }

    const handleShowImage = (item: Gallery,index:number) => {
        setImages(
            {
                id: item.id,
                image: item.image,
                image_medium: item.image_medium,
                image_small: item.image_small,
                index: index,
            }
        )
    }
    const countPerLine = 4;

    return (
        <div className={"bike_details_item_picture"}>
            <div className={"bike_details_item_picture_main"}>
                <img src={image.image} alt={label('Rent a Motorcycle')+' '+item.name+' '+label('in Bratislava')} />
            </div>
            {item.gallery.length > 0 && <div className={"bike_details_item_picture_thumbnail"}>
                <div className="slider__thumbs">
                    <GallerySlider
                        slidesPerGroup={countPerLine}
                        slidesPerView={4}
                        photos={photos}
                        needGallery = {false}
                        // @ts-ignore
                        handleShowImage = {handleShowImage}
                        loop={photos.length > countPerLine}
                        navigation={photos.length > countPerLine}
                        className={"tour_info_days_item_images_slider"}
                    />
                </div>
            </div>}
        </div>
    )
}