import React, {FC} from "react";

interface textProps {
    color?:string
    text:string
}

export const PackageRulesHeaderMainColor:FC<textProps> = ( {text,color}) => {
    if(!color){
        color = 'red';
    }
    if (!text) {
        return null;
    }
    return (
        <div className={"packages_rules_item header_main_color"} style={{color:color}}>{text}</div>
    )
}