import React, {FC, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {
    DateCellItem,
    daysOfTheWeek, durFromTs, formatDate,
    getCurrentMothDays,
    getDaysAmountInAMonth, getNextMonthDays, getNumDaySelected,
    getPreviousMonthDays, isAvailableCell, isBiggerThanDate,
    isInRange, isPrevMonAv, isPrevYearAv, isSelectAvailable, isSmallerThanDate, isSutSun,
    isToday, isUsed, isWeekEnd,
    months
} from "./utils";
import {clsx} from "clsx";
import {label} from "../../../service";
import {CalType} from "../../../page/Bike";


type InitialValue ={
    start:string,
    end:string
}
interface DatePickerPopupContentProps {
    selectedValue: Date;
    onSelect: (value: string[], duration: number) => void,
    denyDays:Date[],
    usedDays?:Date[],
    type:CalType,
    minDay?:number,
    maxDay?:number,
    initialValue?:InitialValue
}

export const DataPickerContent: FC<DatePickerPopupContentProps> = ({selectedValue,
                                                                       onSelect,denyDays:recDenyDays,
                                                                       usedDays=[],type,
                                                                       minDay,maxDay,
                                                                      initialValue}) => {


    const denyDays = useMemo(()=>{
         if(!usedDays){
             return recDenyDays;
         }
         return [...recDenyDays,...usedDays]
    },[recDenyDays,usedDays])
    const [startSelect, setStartSelect] = useState(false);
    const [min, setMin] = useState<Date>();
    const [max, setMax] = useState<Date>();
    const [start,setStart] = useState<Date>();
    const [panelYear, setPanelYear] = useState(() => selectedValue.getFullYear());
    const [panelMonth, setPanelMonth] = useState(() => selectedValue.getMonth());
    const todayDate = useMemo(() => new Date(), []);
    const [year, month, day] = useMemo(() => {

        const currentYear = selectedValue.getFullYear();
        const currentDay = selectedValue.getDate();
        const currentMonth = selectedValue.getMonth();
        return [currentYear, currentMonth, currentDay];
    }, [selectedValue]);

    useEffect(()=>{

        setMin(undefined);
        setMax(undefined);
        setStart(undefined);
        setStartSelect(false);
    },[type])

    useEffect(()=>{
        if(initialValue){
            const start = new Date(initialValue.start);
            const end = new Date(initialValue.end);
            setPanelMonth(start.getMonth());
            setMin(start);
            setMax(end);

        }
    },[])

    const dateCells = useMemo(() => {
        const daysInAMonth = getDaysAmountInAMonth(panelYear, panelMonth);

        const currentMonthDays = getCurrentMothDays(
            panelYear,
            panelMonth,
            daysInAMonth
        );
        const prevMonthDays = getPreviousMonthDays(panelYear, panelMonth);
        const nextMonthDays = getNextMonthDays(panelYear, panelMonth);

        return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
    }, [panelYear, panelMonth]);

    const nextYear = () => {
        setPanelYear(panelYear + 1);
    };

    const prevYear = () => {
        setPanelYear(panelYear - 1);
    };

    const nextMonth = () => {
        if (panelMonth === 11) {
            setPanelMonth(0);
            setPanelYear(panelYear + 1);
        } else {
            setPanelMonth(panelMonth + 1);
        }
    };

    const prevMonth = () => {
        if (panelMonth === 0) {
            setPanelMonth(11);
            setPanelYear(panelYear - 1);
        } else {
            setPanelMonth(panelMonth - 1);
        }
    };



    const onMouseEnter = (item: DateCellItem) => {


        if ( startSelect && min && max) {
            const curEnter= new Date(item.year, item.month, item.date);

             if(!isInRange(
                curEnter,
                new Date(year, month, day ),
            )){
                 return;
             }


            if(start && isBiggerThanDate(curEnter,start)){

                if(isSelectAvailable(min,curEnter,denyDays)){
                    if(maxDay && maxDay<getNumDaySelected(min,curEnter)){
                        return;
                    }
                    setMax(curEnter);
                }
            }

            if(start && isSmallerThanDate(curEnter,start)){
               return;
               // isSelectAvailable(curEnter,max,denyDays) && setMin(curEnter);
            }

        }
    }
    const onDateSelect = (item: DateCellItem) => {


        if(isUsed(item,denyDays)){

            return ;
        }
        if(type==="half"){

            setMin(new Date(item.year, item.month, item.date));
            setMax(new Date(item.year, item.month, item.date));

            onSelect([
                    formatDate(new Date(item.year, item.month, item.date)),
                    formatDate(new Date(item.year, item.month, item.date)),

                ],
                1
            );
            return;
        }


        if(type==="weekend"){
            let isUsedCell = isUsed(item,denyDays,true);
            if(isUsedCell){
                return;
            }
           const cellDate = new Date(item.year,item.month,item.date);
           const day = cellDate.getDay();
           let localMin ,localMax;

           switch (day){
               case 5:

                   localMin =(new Date(item.year, item.month, item.date));
                   localMax= (new Date(item.year, item.month, item.date+3));

                   break;
               case 6:
                   localMin =(new Date(item.year, item.month, item.date-1));
                   localMax=(new Date(item.year, item.month, item.date+2));
                   break;
               case 0:
                   localMin =(new Date(item.year, item.month, item.date-2));
                   localMax=(new Date(item.year, item.month, item.date+1));
                   break;
           }
           if(localMin && localMax){
               setMin(localMin);
               setMax(localMax);
               onSelect([
                       formatDate(localMin),
                       formatDate(localMax),

                   ],
                   2//getNumDaySelected(localMin, localMax)
               );
           }
           return;
        }
        if (!startSelect) {
            if(isUsed(item,denyDays)){
                return ;
            }
            setStartSelect(true);
            setStart(new Date(item.year, item.month, item.date));
            setMin(new Date(item.year, item.month, item.date));
            setMax(new Date(item.year, item.month, item.date));

        } else {
            const maxCurrent = new Date(item.year, item.month, item.date);
            if(!min){
                return;
            }



            if(min && !isSmallerThanDate(  min,maxCurrent)){

                return;
            }


            if(minDay && minDay>getNumDaySelected(min,maxCurrent)){
                return;
            }

            if(maxDay && maxDay<getNumDaySelected(min,maxCurrent)){
                return;
            }


            setStartSelect(false);
            setMax(maxCurrent);

             if(min && maxCurrent){

                const diff = 0;//!!durFromTs(min.getTime(),max.getTime())?0:1 ;

                onSelect([
                        formatDate(min),
                        formatDate(new Date(maxCurrent.getFullYear(),maxCurrent.getMonth(),maxCurrent.getDate()+diff) ),

                    ],
                    getNumDaySelected(min, maxCurrent)
                );
            }


        }

    };

    let isPrevYear = isPrevYearAv(panelYear, year);
    let isPrevMon = isPrevMonAv(panelMonth, panelYear, year, month);
    return (
        <div className="CalendarPanel">
            <div className="CalendarPanel__header">
                <div className="CalendarPanel__buttons">
                    <div className="CalendarPanel__buttons-left">


                        <div className={"btn btn-secondary "+(!isPrevMon ? "disabled_button ":"")}
                            data-testid="date-picker-popup-prev-month"
                            onClick={prevMonth}
                        >
                            {"<"}
                        </div>
                    </div>

                    <div className="CalendarPanel__date" data-testid="date-picker-popup-month">
                        {label(months[panelMonth])} {panelYear}
                    </div>

                    <div className="CalendarPanel__buttons-right">
                        <div className={"btn btn-secondary"}
                            data-testid="date-picker-popup-next-month"
                            onClick={nextMonth}
                        >
                            {">"}
                        </div>

                    </div>
                </div>
            </div>
            <div className="CalendarPanel__content">
                {daysOfTheWeek.map(weekDay => (
                    <div key={weekDay} className="CalendarPanelItem dayWeekItem">
                        {label(weekDay)}
                    </div>
                ))}
                {dateCells.map(cell => {
                    const isSelectedDate =
                        cell.year === year && cell.month === month && cell.date === day;
                    const isTodayDate = isToday(cell, todayDate);
                    const isNotCurrent = cell.type !== 'current';

                    let isUsedCell = isUsed(cell,denyDays,type==="weekend") ;
                    if(type==="weekend" && !isUsedCell){
                        isUsedCell = !isWeekEnd(cell);
                    }
                    if(type==="half" && !isUsedCell){
                        isUsedCell = isSutSun(cell);
                    }

                    let isDateInRange = isInRange(
                        new Date(cell.year, cell.month, cell.date),
                        new Date(year, month, day ),
                    );

                    if(isDateInRange){
                        const cellDate = new Date(cell.year, cell.month, cell.date);
                        if(startSelect && min && max){
                            if(isBiggerThanDate(cellDate,max)){
                                isDateInRange =  isSelectAvailable(min,cellDate,denyDays);
                            }
                            if(isSmallerThanDate(cellDate,min)){
                                isDateInRange =  isSelectAvailable(cellDate,max,denyDays);
                            }
                        }

                    }
                    if(isUsedCell){
                        isDateInRange = false;
                    }
                    const isSelected = isUsedCell?false:isInRange(
                        new Date(cell.year, cell.month, cell.date),
                        min,
                        max
                    );


                    const isAvailableSelect = isAvailableCell(
                        startSelect,maxDay?maxDay:0,denyDays,cell,min);


                    const usedMe =  isUsed(cell,usedDays);
                    return (
                        <div
                            className={clsx(
                                'CalendarPanelItem',
                                isSelectedDate && 'CalendarPanelItem--selected',
                                isTodayDate && 'CalendarPanelItem--today',
                                isNotCurrent && 'CalendarPanelItem--not-current',
                                !usedMe && !isDateInRange && 'CalendarPanelItem--not-in-range',
                                isSelected && 'CalendarPanelItem--in',
                                isUsedCell &&  'CalendarPanelItem--used',
                                isAvailableSelect &&  'CalendarPanelItem--available',
                                usedMe && 'CalendarPanelItem--used-me'
                            )}
                            key={`${cell.date}-${cell.month}-${cell.year}`}
                            onClick={() => isDateInRange && onDateSelect(cell)}
                            onMouseEnter={() => {
                                onMouseEnter(cell)
                            }}

                        >
                            <div className="CalendarPanelItem__date">{cell.date}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );

};

