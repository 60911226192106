import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {TourItemState, TourWaitingData} from "../../types/tourItem";
import {TourBookData} from "../../types/tourBook";

export const fetchTourInfo = createAsyncThunk(
    'fetchTourInfo',
    async (id:number)  => {
        return await api.getTourItem(id) as any;

    }
)

export const fetchTourWaitingList = createAsyncThunk(
    'fetchTourWaitingList',
    async (data: TourWaitingData, thunkAPI) => {
        return await api.sendTourWaitingList(data);
    }
)

const initialState:TourItemState ={
    images: {main:'',gallery:[]},
    info: {
        id: 0,
        name: '',
        info: '',
        difficulty: 0,
        days_number: 0,
        bike_days_number: 0
    },
    dates: [],
    cities: [],
    attractions: [],
    countries: [],
    bikes: [],
    days: [],
    rules: [],
    reserves: {},
    waitSuccess:false
};

export const toursItemSlice = createSlice({
    name:"toursItem",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetTourItem:(state)=>{
            return {
                ...state,
                images: {main:'',gallery:[]},
                info: {
                    id: 0,
                    name: '',
                    info: '',
                    difficulty: 0,
                    days_number: 0,
                    bike_days_number: 0
                },
                dates: [],
                cities: [],
                attractions: [],
                countries: [],
                bikes: [],
                days: [],
                rules: [],
                reserves: {},
                waitSuccess:false
            }
        },
        resetWaitingSuccess:(state)=>{
            return {
                ...state,
                waitSuccess:false
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTourInfo.fulfilled,(state,action)=>{
            state.images =  action.payload.images;
            state.info =  action.payload.info;
            state.dates =  action.payload.dates;
            state.cities =  action.payload.cities;
            state.attractions =  action.payload.attractions;
            state.countries =  action.payload.countries;
            state.bikes =  action.payload.bikes;
            state.days =  action.payload.days;
            state.rules =  action.payload.rules;
            state.reserves =  action.payload.reserves;
        }).addCase(fetchTourWaitingList.fulfilled,(state,action)=>{
            state.waitSuccess = action.payload === 'success';
        })
    }
})

export const {resetTourItem,resetWaitingSuccess} = toursItemSlice.actions;
export const toursItemReducer = toursItemSlice.reducer;