import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {PhotosState} from "../../types/photos";

export const fetchPhotosList = createAsyncThunk(
    'fetchPhotosList',
    async (thunkAPI)  => {
        return await api.getPhotosList() as PhotosState;

    }
)


const initialState:PhotosState ={
    images: []
};

export const photosSlice = createSlice({
    name:"photos",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchPhotosList.fulfilled,(state,action)=>{
            state.images =  action.payload.images;
        })
    }
})

export const {} = photosSlice.actions;
export const photosReducer = photosSlice.reducer;