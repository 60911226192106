import React, {FC, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {DayImage, TourItemAttraction, TourItemCity, TourItemCountry} from "../../types/tourItem";
import {TooltipOverviewItem} from "./TooltipOverviewItem";
import {Link} from "react-router-dom";
import {showGallery} from "../../features/galleryViewer/galleryViewerSlice";

export const AttractionsOverview = () => {
    const attractions = useAppSelector(state => state.toursItem.attractions);

    if (!attractions.length) {
        return null;
    }

    return (
        <div className={"tour_info_overview_column"}>
            <h5>{label('Highlights')}</h5>
            <div className={"tour_info_overview_items"}>
                {
                    attractions.map((attraction: TourItemAttraction) => {
                        return (
                            <AttractionItem
                                key={attraction.id}
                                attraction={attraction}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

interface attractionItemProps {
    attraction: TourItemAttraction
}

const AttractionItem: FC<attractionItemProps> = ({attraction}) => {

    const dispatch = useAppDispatch();

    const handleImage = (images: Object[], index: number) => {
        dispatch(showGallery({images:images,current:index}));
    }
    // @ts-ignore
    const Image = new Object({id:0,image:attraction.image});

    return (

        <div className={"tour_info_overview_item"}>
            <div className={"tour_info_overview_item_desc_area"}>
                <div className={"tour_info_overview_item_desc"}>
                    <h3>{attraction.name}</h3>
                    <div className={"tour_info_overview_item_info"}>
                        {attraction.info}
                    </div>
                </div>
                <div className={"tour_info_overview_item_gallery"}>
                    <div className="image" style={{backgroundImage:`url('${attraction.image}')`}} onClick={()=>{handleImage(Array(Image),0)}}></div>
                </div>
            </div>
        </div>
    )
}