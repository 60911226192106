import React, {FC, useState} from "react";
import {ImStarEmpty, ImStarFull} from "react-icons/im";

interface starRangeProps {
    max:number
    value:number
    canHover:boolean
}

const StarRange:FC<starRangeProps> = ({max,  value,canHover = true}) => {
    const [hover,setHover] = useState(-1);

    let items = [];
    for (let i = 0; i < max; i++) {
        items.push(i);
    }

    const clearHover = () => {
        setHover(-1);
    }

    return (
        <div className={"stars"}
             onMouseLeave={clearHover}
        >
            {items.map(item=>{
                return (
                    <StarRangeItem
                        key = {item}
                        item = {item}
                        value = {value}
                        hover = {hover}
                        setHover = {setHover}
                        canHover = {canHover}
                    />
                )
            })}
        </div>
    )
}

interface starRangeItemProps {
    item:number
    setHover:(item:number)=>void
    hover:number
    value:number
    canHover:boolean
}
const StarRangeItem:FC<starRangeItemProps> = ({item,setHover,value,hover,canHover = true}) => {
    const handleHover = () =>{
        if(canHover){
            setHover(item);
        }
    }

    let empty = true;
    if(+value > item || +hover >= item){
        empty = false;
    }
    return (
        <div className={"stars-item "+(+value > item ? ' active_star ':'')+(+hover >= item ? ' hover_star ':'')}
             onMouseOver={handleHover}
             key = {item}
        >
            {empty ? <ImStarEmpty/> : <ImStarFull/>}
        </div>
    )
}

export default StarRange;