import React, {useEffect, useRef, useState} from "react";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {wrapText} from "../../utils/canvas";
import {checkImage, updateCanvas} from "../../features/certificate/certificateSlice";

export const CertificateVoucherCanvas = () => {
    const canvasRef = useRef(null);
    // const font = new FontFace("ProximaNova", "url(/www/fonts/ProximaNova1.woff)");
    // document.fonts.add(font);

    const [loaded, setLoaded] = useState({});

    const rules = useAppSelector(state => state.certificate.rules);
    const values = useAppSelector(state => state.certificate.values);
    const images = useAppSelector(state => state.certificate.images);
    const lang = useAppSelector(state => state.common.current_language);

    const image = images.find(im => +im.id === +values.image_id);

    const logo = "/www/images/logo.png";

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (images && images.length) {
            load();
        }
    }, []);

    useEffect(() => {
        if (!Object.keys(loaded).length && images.length) {
            load();
        }
    }, [images]);

    useEffect(() => {
        // @ts-ignore
        if (image && loaded[values.image_id] !== undefined && canvasRef && canvasRef.current) {
            // @ts-ignore
            updateCanvasElement(canvasRef.current,loaded[values.image_id]);
            // console.log('ctx', ctx);
        }
    }, [values]);

    useEffect(()=>{
        if(images.length > 0 && Object.keys(loaded).length === images.length){
              dispatch(checkImage({image_id:images[0].id}));
        }
    },[loaded,images]);

    // @ts-ignore
    const updateCanvasElement = (canvas: HTMLCanvasElement,img:Image) => {
        let ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.roundRect(0, 0, canvas.width, canvas.height, [10]);
            ctx.clip();

            ctx.drawImage(img, 0, 0);

            let logoImg = new Image();
            logoImg.src = logo;

            ctx.drawImage(logoImg, 5, 5, 66, 58);

            ctx.fillStyle = "#f60";
            ctx.fillRect(300, 0, 100, canvas.height - 80);

            ctx.font = "15px arial";
            ctx.fillStyle = '#fff';
            ctx.textAlign = 'center';
            let wrappedText = wrapText(ctx, label('Gift Voucher'), 350, 25, 90, 14);
            wrappedText.forEach(function (item) {
                // @ts-ignore
                ctx.fillText(item[0], item[1], item[2]);
            })

            if (values.price > 0) {
                ctx.font = "25px arial";
                ctx.fillStyle = '#fff';
                ctx.textAlign = 'center';
                ctx.fillText('€' + values.price, 350, rules.expire ? 100 : 120);
            }

            if (rules.expire) {
                ctx.font = "10px arial";
                ctx.fillStyle = '#fff';
                ctx.textAlign = 'center';
                let wrappedText = wrapText(ctx,label('Expires') + ' ' + rules.expire, 350, canvas.height - 100, 90, 14);
                wrappedText.forEach(function (item) {
                    // @ts-ignore
                    ctx.fillText(item[0], item[1], item[2]);
                })
            }

            if (values.text) {
                ctx.fillStyle = "#00000059";
                ctx.fillRect(0, canvas.height - 80, canvas.width - 100, 80);

                const isHeb = /[\u0590-\u05FF]/.test(values.text);

                // ctx.font = "14px ProximaNova";
                ctx.font = "14px arial";
                ctx.fillStyle = '#fff';
                ctx.textAlign = 'start';

                if(lang === 'he'){
                    ctx.direction = 'rtl';
                    let wrappedText = wrapText(ctx, values.text, canvas.width - 120, canvas.height - 55, 268, 14);
                    wrappedText.forEach(function (item) {
                        // @ts-ignore
                        ctx.fillText(item[0], item[1], item[2]);
                    })
                }else{
                    let wrappedText = wrapText(ctx, values.text, 20, canvas.height - 55, 268, 14);
                    wrappedText.forEach(function (item) {
                        // @ts-ignore
                        ctx.fillText(item[0], item[1], item[2]);
                    })
                }
            }

            ctx.fillStyle = "#000";
            ctx.fillRect(300, canvas.height - 80, 100, 80);

            ctx.font = "20px arial";
            ctx.fillStyle = '#fff';
            if (values.promo_code) {
                ctx.textAlign = 'center';
                ctx.fillText(values.promo_code, 350, canvas.height - 35);
                let src = canvas.toDataURL();

                if (src && !values.canvas) {
                    dispatch(updateCanvas({canvas: src}))
                }
            }
        }
    }

    const load = () => {
        let newLoaded = {};
        images.forEach(image => {
            let img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => {
                // @ts-ignore
                newLoaded[image.id] = img;
                if(Object.keys(newLoaded).length === images.length){
                    setLoaded(newLoaded);
                }
            };
            img.src = image.image_medium;
        });
    }

    let wrapStyle = {backgroundImage: 'unset'};
    let topStyle = {backgroundColor: '#fff'};
    if (image) {
        wrapStyle = {backgroundImage: `url(${image.image_medium})`};
        topStyle = {backgroundColor: 'transparent'};
    }

    let style = {display: 'none'};
    if (image) {
        style = {display: 'flex'}
    }

    return (
        <div className={"certificate_create_voucher "} style={style}>
            <canvas id={`voucher`} ref={canvasRef} width={'400px'} height={'275px'}/>
        </div>
    )
}