export const daysOfTheWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const VISIBLE_CELLS_AMOUNT = 7 * 6;
const sundayWeekToMondayWeekDayMap: Record<number, number> = {
    0: 6,
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
};

export interface DateCellItem {
    date: number;
    month: number;
    year: number;
    type: 'next' | 'prev' | 'current';
}

export const getDaysAmountInAMonth = (year: number, month: number) => {
    const nextMonthDate = new Date(year, month + 1, 1);
    // mutates the date object
    nextMonthDate.setMinutes(-1);
    return nextMonthDate.getDate();
};

const getDayOfTheWeek = (date: Date) => {
    const day = date.getDay();

    return sundayWeekToMondayWeekDayMap[day];
};

export const getPreviousMonthDays = (year: number, month: number) => {
    const currentMonthFirstDay = new Date(year, month, 1);
    const prevMonthCellsAmount = getDayOfTheWeek(currentMonthFirstDay);

    const daysAmountInPrevMonth = getDaysAmountInAMonth(year, month - 1);

    const dateCells: DateCellItem[] = [];

    const [cellYear, cellMonth] =
        month === 0 ? [year - 1, 11] : [year, month - 1];

    for (let i = prevMonthCellsAmount - 1; i >= 0; i--) {
        dateCells.push({
            year: cellYear,
            month: cellMonth,
            date: daysAmountInPrevMonth - i,
            type: 'prev',
        });
    }

    return dateCells;
};

export const getNextMonthDays = (year: number, month: number) => {
    const currentMonthFirstDay = new Date(year, month, 1);
    const prevMonthCellsAmount = getDayOfTheWeek(currentMonthFirstDay);

    const daysAmount = getDaysAmountInAMonth(year, month);

    const nextMonthDays =
        VISIBLE_CELLS_AMOUNT - daysAmount - prevMonthCellsAmount;

    const [cellYear, cellMonth] =
        month === 11 ? [year + 1, 0] : [year, month + 1];

    const dateCells: DateCellItem[] = [];

    for (let i = 1; i <= nextMonthDays; i++) {
        dateCells.push({
            year: cellYear,
            month: cellMonth,
            date: i,
            type: 'next',
        });
    }

    return dateCells;
};

export const getCurrentMothDays = (
    year: number,
    month: number,
    numberOfDays: number
) => {
    const dateCells: DateCellItem[] = [];

    for (let i = 1; i <= numberOfDays; i++) {
        dateCells.push({
            year,
            month,
            date: i,
            type: 'current',
        });
    }

    return dateCells;
};

const addLeadingZeroIfNeeded = (value: number) => {
    if (value > 9) {
        return value.toString();
    }

    return `0${value}`;
};

export const getInputValueFromDate = (value: Date) => {
    const date = addLeadingZeroIfNeeded(value.getDate());
    const month = addLeadingZeroIfNeeded(value.getMonth() + 1);
    const year = value.getFullYear();

    return `${date}-${month}-${year}`;
};

export const getDateFromInputValue = (inputValue: string) => {
    if (!isValidDateString(inputValue)) {
        return;
    }

    const [date, month, year] = inputValue.split('-').map(v => parseInt(v, 10));

    const dateObj = new Date(year, month - 1, date);

    return dateObj;
};

const validValueRegex = /^\d{2}-\d{2}-\d{4}$/;

export const isValidDateString = (value: string) => {
    if (!validValueRegex.test(value)) {
        return false;
    }
    const [date, month, year] = value.split('-').map(v => parseInt(v, 10));

    if (month < 1 || month > 12 || date < 1) {
        return false;
    }

    const maxDaysInAMonth = getDaysAmountInAMonth(year, month - 1);

    if (date > maxDaysInAMonth) {
        return false;
    }

    return true;
};

export function isToday(cell: DateCellItem, todayDate: Date) {
    return (
        todayDate.getFullYear() === cell.year &&
        todayDate.getMonth() === cell.month &&
        todayDate.getDate() === cell.date
    );
}

export function isSutSun(cell:DateCellItem){
    const  date = new Date(cell.year,cell.month,cell.date);

    return date.getDay()>5 || date.getDay()==0;
}

export function isWeekEnd(cell:DateCellItem){
   const  date = new Date(cell.year,cell.month,cell.date);

    return date.getDay()>4 || date.getDay()==0;
}




export function isInRange(value: Date, min?: Date, max?: Date) {


    if(!min && ! max){
        return false;
    }
    if (min && max) {
        return isSmallerThanDate(value, max) && isBiggerThanDate(value, min);
    }

    if (min) {

        return isSmallerThanDate( min,value);
    }

    if (max) {
        return isSmallerThanDate(value, max);
    }

    return true;
}

export function isUsed (cell:DateCellItem,useData:Date[],isWeek=false){

      let result =  useData.findIndex((item:Date)=>isToday(cell,item) );


      if(!isWeek){
          return result !== -1;
      }

      if(result !==-1){
          return true;
      }
      const curDate = new Date(cell.year,cell.month,cell.date);
      const curDay = curDate.getDay();
      if(curDay==5){

          const sutCell:DateCellItem =  getCelFromDate(getNextDateFromCell(cell));
          result = useData.findIndex((item:Date)=>isToday(sutCell,item) );
          if(result !==-1){
              return true;
          }
          const sunCell:DateCellItem =  getCelFromDate(getNextDateFromCell(sutCell));

          result = useData.findIndex((item:Date)=>isToday(sunCell,item) );
          return result !== -1;

      }
      if(curDay==6){
          if(!isSmallerThanDate(new Date(),getPrevDateFromCell(cell))){
              return  true;
          }
          const frCell:DateCellItem =  getCelFromDate(getPrevDateFromCell(cell));
          result = useData.findIndex((item:Date)=>isToday(frCell,item) );
          if(result !==-1){
              return true;
          }
          const sunCell:DateCellItem =  getCelFromDate(getNextDateFromCell(cell));
          result = useData.findIndex((item:Date)=>isToday(sunCell,item) );
          return result !== -1;
      }

      if(curDay==0){
           if(!isSmallerThanDate(new Date(),
              getPrevDateFromCell(
                  getCelFromDate(getPrevDateFromCell(cell))
                 )
              )
          )
          {
              return  true;
          }
          const sutCell:DateCellItem =  getCelFromDate(getPrevDateFromCell(cell));
          result = useData.findIndex((item:Date)=>isToday(sutCell,item) );
          if(result !==-1){
              return true;
          }
          const frCell:DateCellItem =  getCelFromDate(getPrevDateFromCell(sutCell));
          result = useData.findIndex((item:Date)=>isToday(frCell,item) );
          return result !== -1;
      }

      return false;
}

export function isSelectAvailable(min:Date,max:Date,useData:Date[]){

    let current = new Date(min);


    while ( isBiggerThanDate(max,current)){

        if(isUsed({
            year:current.getFullYear(),
            month:current.getMonth(),
            date:current.getDate(),
            type:"current"
        },useData)){

            return false;
        }
        current.setDate(current.getDate()+1);

    }
    return  true;
}

export function getCellFromDate(current:Date):DateCellItem{
   return  {
        year:current.getFullYear(),
        month:current.getMonth(),
        date:current.getDate(),
        type:"current"
    }
}

export function isAvailableCell(startSelect:boolean,maxDay:number,denyDays:any,cell:any,min?:Date){

    if (!startSelect || !min || maxDay==0){
        return  false;
    }


    const cellDate = getPrevDateFromCell(cell);
    if(isBiggerThanDate(cellDate,min)){
        const selectedDay = getNumDaySelected(min,cellDate);
        if(selectedDay>=maxDay){
            return  false;
        }
        return  true;
    }

    return false;
}


export function formatDate(date:Date){
    let day = String(date.getDate());

    if(+day < 10){
        day = '0'+day;
    }
    let month =  String(date.getMonth()+1) ;
    if( Number(month)  < 10){
        month = '0'+month;
    }
    return `${day}.${month}.${date.getFullYear()}`
}

export const isPrevYearAv=(panelYear:number,year:number)=>{

    return   panelYear-1>=year ;
}

export  const isPrevMonAv=(panelMonth:number,panelYear:number,year:number,month:number)=>{
    let prevDate ;
    if (panelMonth === 0) {
        prevDate = new Date(panelYear - 1,11)

    } else {
        prevDate = new Date(panelYear,panelMonth - 1)

    }
    return isBiggerThanDate(prevDate,new Date(year,month)) ;
}

export     const getNumDaySelected = (start: Date, end: Date) => {
    return (Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1);
}
export function isBiggerThanDate(value: Date, date: Date) {
    if (value.getFullYear() > date.getFullYear()) {
        return true;
    }

    if (value.getFullYear() < date.getFullYear()) {
        return false;
    }

    if (value.getMonth() > date.getMonth()) {
        return true;
    }

    if (value.getMonth() < date.getMonth()) {
        return false;
    }

    return value.getDate() >= date.getDate();
}

export function isSmallerThanDate(value: Date, date: Date) {
    if (value.getFullYear() > date.getFullYear()) {
        return false;
    }

    if (value.getFullYear() < date.getFullYear()) {
        return true;
    }

    if (value.getMonth() > date.getMonth()) {
        return false;
    }

    if (value.getMonth() < date.getMonth()) {
        return true;
    }

    return value.getDate() <= date.getDate();
}
/** date format  d.m.Y
 *  time h:i */
export function dateToTs(date:string,time:string,zone=':00Z'){
    const strDate = `${date.split('.').reverse().join('-')}T${time}${zone}`;
       const diff = window._common.offset||7200;
       return Date.parse(strDate)-diff*1000;

}


export function durFromTs(start_ts:number,end_ts:number){
    if(isNaN(start_ts)){
        return 1;
    }
    if(isNaN(end_ts)){
        return 1;
    }
     return Math.abs(Math.ceil((end_ts-start_ts)/(24*3600*1000)));
}

export function getNextDateFromCell(cell:DateCellItem){
    return  new Date(cell.year,cell.month,cell.date+1);
}
export function getPrevDateFromCell(cell:DateCellItem){
    return  new Date(cell.year,cell.month,cell.date-1);
}

export function getCelFromDate(date:Date,type="current"){
    return  {
        year:date.getFullYear(),
        month:date.getMonth(),
        date: date.getDate(),
        type
    }  as DateCellItem
}