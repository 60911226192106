import React from "react";
import {LangPanel} from "./LangPanel";
import {Menu} from "./Menu";
import UserProfile from "./UserProfile";
import RouletteButton from "./RouletteButton";

export const Header = () => {
    return (
        <header id="header" className="w-full">
            <div className="header_wrap w-wrap">
                <h1 className="logo__heading">
                    <a className="logo__heading-link" href={"/"}></a>
                </h1>
                <Menu/>
                <RouletteButton/>
                <LangPanel/>
                <UserProfile/>
            </div>
        </header>
    )
}