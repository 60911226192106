import React, {FC} from 'react';
import {FcInfo} from "react-icons/fc";
interface ToolTipProps{
    tooltip?:string
}
export const ToolTip:FC<ToolTipProps> = ({tooltip}) => {
    return <span className={"con-tooltip"}>
            <FcInfo/>
            <div className={"tooltip"}>{tooltip}</div>
        </span>
}
