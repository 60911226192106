import React, {FC} from "react";
import {BikeCard} from "../../types";
import {label} from "../../service";
import "./visitors.scss";
const Visitors:FC<{item:BikeCard}>=({item})=>{

   return null;
    const view_text = label('{%n} people are currently viewing this motorcycle')
        .replace("{%n}",String(item.visitors));

    return(
        <div className={"visitors"}>
            {!!item.visitors &&
                <div className={"view-count"}><span className={"view-count-title"}>{view_text}</span>
                </div>
            }
            {!!item.last_order &&
                <div className={"last-order"}><span className={"last-order-title"}>
                    {label('Last time this motorcycle was ordered')+" "}
                    {item.last_order}
                </span>
                     </div>
            }

            </div>
    );
}

export {Visitors}