import {BikeListItem, Equip, Extra, Overtime, PackageBikeDetailsValues} from "../types/packageBikeDetails";

export const getPackageOrderTotal = (
    values: PackageBikeDetailsValues,
    equip: Equip[],
    extra: Extra[],
    bikes_list: BikeListItem[],
    overtime: Overtime,
    days_number: number,
) => {
    let price = getBikePrice(values, bikes_list, days_number);

    equip
        .filter(item => item.price > 0 )
        .forEach(item => {
            if (values.accessor[item.id] !== undefined) {
                let count = values.accessor[item.id].count || 1;
                price += +item.price * count * days_number;
            }
        });

    extra
        .filter(item => +item.price > 0)
        .forEach(item => {
            if (values.extras.includes(item.id)) {
                price += +item.price;
            }
        });

    price += getOverTimePrice(overtime,values.overtime);

    return price;
}

export const getBikePrice = (
    values: PackageBikeDetailsValues,
    bikes_list: BikeListItem[],
    days_number: number,
) => {

    const bike = bikes_list.find(bike => +bike.id === +values.bike_id);
    if (bike === undefined) {
        return 0;
    }

    return Math.round(bike.diff_price * days_number * 100) / 100;
}

export const getOverTimePrice = (overtime:Overtime,overtime_value:string[]) => {
    let overtime_price = 0;
    if (overtime) {
        Object.entries(overtime).forEach(([key, value]) => {
            if (overtime_value.includes(key)) {
                overtime_price += value.price;
            }
        });
    }

    return Math.round(overtime_price * 100) / 100;
}

