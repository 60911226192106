import React from "react";
import {useNavigate} from "react-router";
import {label} from "../../service";

export const HomeMainInfo = () => {
    // const navigate = useNavigate();
    // const handleTour = () => {
    //         navigate('/tours/17')
    // }

    return (
        <div className="home_center">
            <div className="home_center_wrap w-wrap">
                <div className="home_center_item home_center_item_1">
                    <h1>{label('Drive the “Slovakia Tour”')}</h1>
                    <p>{label('home_page_slovakian_desc')}</p>
                </div>
                <div className="home_center_item home_center_item_2">
                    <div className="home_center_item_tour tour1"></div>
                </div>
                <div className="home_center_item home_center_item_3">
                    <div className="home_center_item_tour tour2"></div>
                </div>
                <div className="home_center_item home_center_item_4">
                    <h1>{label('One of the most exciting Slovakian driving itineraries!')}</h1>
                    <p>{label('home_page_drive_desc')}</p>
                </div>
            </div>
        </div>
    )
    /*
    <article className="home_main">
            <div className="home_main_wrap">
                <div className="home_main_body">
                    <div className="header">
                        <h1 className="page-header">Drive the “Slovakia Tour”</h1>
                        <h2 className="sub-header">One of the most exciting Slovakian driving itineraries!</h2>
                    </div>

                    <p><strong>Discover beautiful trip around Slovakia, great ways in the heart of the country. You will enjoy traditional foot in chalet. Relax in beautiful spa resort. Like shopping ? Don't worry big shopping center with many big brands will satisfy you.</strong></p>
                    <p>Our company offers a short-term rental service and allows you to choose from a wide range of
                        motorcycles and scooters of the best brands: Honda. Between curves and
                        breathtaking views, places rich in history and natural beauty, each stage will leave an
                        indelible mark on your heart.</p>
                </div>
                {false && <figure className="home_main_photo">
                    <img src="/www/images/home_map0.jpg" alt=""
                         onClick={handleTour}
                    />
                </figure>}
            </div>
        </article>*/


}