import React, {FC, Fragment} from 'react';
import {AccessoryItem, Equipments} from "../../types";
import {label} from "../../service";
import {CheckBoxCountable} from "../common/checkbox";

const fixture: AccessoryItem[] = [
    {
        id: 1,
        name: 'Lock - free of charge'
    },
    {
        id: 2,
        name: 'Motorcycle Cover - free of charge'
    }, {
        id: 3,
        name: 'Helmet +9 EUR/day'
    }, {
        id: 4,
        name: 'Topcase/Sidecase +9 EUR/day'
    },
];

interface AccessoriesProps {
    onAccessoryChange: (id: number, count: number) => void,
    onAccessoryChangeCount: (id: number, count: number) => void,
    equip: Equipments[]
    accessor: {}
    checkoutPrice: string
    duration: number
}


export const Accessories: FC<AccessoriesProps> = ({
                                                      onAccessoryChange,
                                                      onAccessoryChangeCount,
                                                      equip,
                                                      accessor,
                                                      checkoutPrice,
                                                      duration
                                                  }) => {

    const getEquipPrice = (item: Equipments) => {
        let formatString = "";
        if (+(item.price) > 0) {
            switch (+item.period) {
                case 2: /// for period
                    formatString = `€${Math.round(item.price * 100) / 100}`;
                    break;
                case 1: /// per day
                default:
                    switch (checkoutPrice) {
                        case 'total':
                            formatString = `€${Math.round(item.price * duration * 100) / 100}`;
                            break;
                        case 'day':
                        default:
                            formatString = `€${item.price} / ${label('Day')}`;
                    }
                    break;
            }

        } else {
            formatString = `${label('Free')}`;
        }

        return formatString;
    }

    return (
        <Fragment>
            {
                equip.map((item) => {

                    // @ts-ignore
                    let count = accessor[item.id] !== undefined ? accessor[item.id].count : 1;
                    return (
                        <CheckBoxCountable
                            key={item.id}
                            onChange={() => {
                                onAccessoryChange(item.id, item.count)
                            }}
                            changeCount={(count) => {
                                onAccessoryChangeCount(item.id, count);
                            }}
                            label={""}
                            name={item.name}
                            price={getEquipPrice(item)}
                            // @ts-ignore
                            checked={accessor[item.id] !== undefined}
                            count={count}
                            item={item}
                        />

                    )
                })
            }
        </Fragment>
    )
}