import React, {useState, useEffect, FC} from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

interface ModalPortalProps {
    children?: React.ReactNode
    addClass?:string
}

export const ModalPortal:FC<ModalPortalProps> = ({children, addClass=''}) => {
    const [el] = useState(document.createElement("div"));
    useEffect(() => {
        el.classList.add('modal-container')
        if (addClass){
            const classes  = addClass.split(' ');
            classes.forEach((item:string)=>{
                el.classList.add(item)
            })
        }
        document.body.append(el);
        return () => {
            document.body.removeChild(el);
        };
    }, [el]);

    return ReactDOM.createPortal(children, el);
};


