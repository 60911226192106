import React, {Fragment} from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";

export const CertificateVoucher = () => {
    const values = useAppSelector(state => state.certificate.values);
    const images = useAppSelector(state => state.certificate.images);

    const image = images.find(im => +im.id === +values.image_id);
    let wrapStyle = {backgroundImage: 'unset'};
    let topStyle = {backgroundColor: '#fff'};
    if (image) {
        wrapStyle = {backgroundImage: `url(${image.image_medium})`};
        topStyle = {backgroundColor: 'transparent'};
    }

    let style = {display:'flex'};
    if(image){
        style = {display:'none'}
    }

    return (
        <div className={"certificate_create_voucher "} style={style}>
            <div className={"certificate_create_voucher_wrap "} style={wrapStyle}>
                {image && <div className={"certificate_create_voucher_logo "}/>}
                <div className={"certificate_create_voucher_left "}>
                    <div className={"certificate_create_voucher_top "} style={topStyle}>
                        {!image &&
                            <div className={"certificate_create_voucher_top "}>{label('Please choose image')}</div>}
                    </div>
                    <div className={"certificate_create_voucher_bottom " + (values.text !== '' ? " with_text " : "")}>
                        <div className={"certificate_create_voucher_text "}>{values.text}</div>
                    </div>
                </div>
                <div className={"certificate_create_voucher_right " + (values.price > 0 ? " with_price " : "")}>
                    <div className={"certificate_create_voucher_price "}>
                        <div className={"certificate_create_voucher_price_text "}>{label('Gift Voucher')}</div>
                       <div className={"certificate_create_voucher_price_value "}>{values.price > 0 ? '€' + values.price : ''}</div>
                    </div>
                    <div className={"certificate_create_voucher_code "}>{'XXXXXX'}</div>
                </div>
            </div>
        </div>
    )
}