import React, {FC, Fragment, useState} from "react";
import {OrderDetail, OrderHistoryItem, OrderInvoice, OrdersListItem} from "../../types/orders";
import {label} from "../../service";
import {ModalPortal} from "../common";
import {ModalWrap} from "../common/ModalWrap";
import moment from "moment";
import {useAppSelector} from "../../app/hooks";

interface detailsProps {
    order: OrdersListItem
}

const OrderItemDetails: FC<detailsProps> = ({order}) => {
    return (
        <div className={"orders_list_item_details"}>
            <div className={"orders_list_item_details_summary"}>
                <div className="orders_list_item_details_label">{label('Order information')}</div>
                <OrderItemInformation
                    order={order}
                />
            </div>
            <div className={"orders_list_item_details_goods"}>
                <div className="orders_list_item_details_label">{label('Details')}</div>
                <OrderItemGoods
                    order={order}
                />
                <OrderItemPayments
                    order={order}
                />
            </div>
        </div>
    )
}

const OrderItemInformation: FC<{ order: OrdersListItem }> = ({order}) => {
    const [showHistory, setShow] = useState(false);

    const handleHistory = () => {
        setShow(true)
    }

    const handleInvoice = () => {
        let target = "_blank";
        let link = "/app/order-invoice?id=" + order.id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const invoiceView = () => {
        let target = "_blank";
        let link = "/app/invoice-view?id=" + order.invoice_id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const handleReceipt = () => {
        let target = "_blank";
        let link = "/app/receipt?id=" + order.id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const handleOnlineCard = () => {
        let target = "_blank";
        let link = "/app/online-card?id=" + order.id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const handleCloseHistory = () => {
        setShow(false)
    }

    const getHistory = () => {
        return (
            <ModalPortal addClass={"small-modal"}>
                <ModalWrap closeModal={handleCloseHistory}>
                    <div className={"modal-header"}>
                        {label('Order History')}
                    </div>
                    <div className={"orders_history "}>
                        <ul className={"orders_history_items "}>
                            {order.history.map((item: OrderHistoryItem) => {
                                return (
                                    <li className={"orders_history_item "}>
                                        <div className={"orders_history_item_decor " + (item.action)}/>
                                        {false && <time className="orders_history_item_date">{moment(item.create_at * 1000).format('HH:mm DD.MM.YY')}</time>}
                                        <time className="orders_history_item_date">{item.create_time}</time>
                                        <p className={"orders_history_item_status "}>{item.action_name}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </ModalWrap>
            </ModalPortal>
        )
    }

    let momentFormat = 'DD.MM.YYYY HH:mm';

    return (
        <Fragment>
            {showHistory && getHistory()}
            <section>
                {order.tour_id > 0 && order.tour_name && <div
                    className={"orders_list_item_details_summary_item"}>{label("Tour Booking")}:&nbsp;{order.tour_name}</div>}
                {+order.tour_id === 0 && order.start_ts > 0 && <div
                    className={"orders_list_item_details_summary_item"}>{label("Pick-up")}:&nbsp;{order.start_time}</div>}
                {+order.tour_id === 0 && order.end_ts > 0 && <div
                    className={"orders_list_item_details_summary_item"}>{label("Drop-off")}:&nbsp;{order.end_time}</div>}
                {+order.tour_id > 0 && order.start_ts > 0 && order.end_ts > 0 && <div
                    className={"orders_list_item_details_summary_item"}>{label("Tour Date")}:&nbsp;{order.start_time+' - '+order.end_time}</div>}
            </section>
            <section>
                {order.mail && <div className={"orders_list_item_details_summary_item"}>{order.mail}</div>}
                {order.name && <div className={"orders_list_item_details_summary_item"}>{order.name}</div>}
                {order.phone && <div className={"orders_list_item_details_summary_item"}>{order.phone}</div>}
            </section>
            <section className={"orders_list_item_details_summary_links"}>
                {order.history.length > 0 &&
                    <div className={"orders_list_item_details_summary_item link_item"}
                         onClick={handleHistory}
                    >{label('Order History')}</div>}
                {+order.status === 1 /*&& order.payment_method === "bank_transfer"*/ &&
                    <div className={"orders_list_item_details_summary_item link_item"}
                         onClick={handleInvoice}
                    >{label('Download Proforma Invoice')}</div>}
                {+order.status === 1 && (order.payment_method === "online_card" || order.payment_method === "bank_transfer") &&
                    <div className={"orders_list_item_details_summary_item link_item"}
                         onClick={handleOnlineCard}>
                        {label('Pay Online Card')}
                        <span>{' '+order.pay+'€'}</span>
                    </div>}
                {+order.status !== 5 && +order.invoice_id > 0 &&
                            <div className={"orders_list_item_details_summary_item link_item"}
                                 onClick={invoiceView}
                            >{label('Download Invoice')}</div>}
                {false && +order.status === 3 &&
                    <div className={"orders_list_item_details_summary_item link_item"}
                         onClick={handleReceipt}
                    >{label('Download Pick-up Receipt')}</div>}
                {false && order.invoice_id > 0 &&
                    <div className={"orders_list_item_details_summary_item link_item"}>{label('Show Invoice')}</div>}
            </section>
        </Fragment>

    )
}

const OrderItemGoods: FC<{ order: OrdersListItem }> = ({order}) => {
    return (
        <ul className={"orders_list_item_details_goods_items"}>
            {order.details.map((detail: OrderDetail) => {
                const getImage = () => {
                    switch (detail.type) {
                        case 'bike':
                            if (order.bike_img_medium) {
                                return (
                                    <img loading="lazy"
                                         src={order.bike_img_medium}
                                         alt={detail.name}/>
                                )
                            }
                            break;
                        case 'tour':
                            if (order.tour_img_medium) {
                                return (
                                    <img loading="lazy"
                                         src={order.tour_img_medium}
                                         alt={detail.name}/>
                                )
                            }
                            break;
                    }
                    return null;
                }
                const getImageClass = () => {
                    switch (detail.type) {
                        case 'bike':
                            if (order.bike_img_medium) {
                                return "";
                            }
                            break;
                        case 'tour':
                            if (order.tour_img_medium) {
                                return "";
                            }
                            break;
                    }
                    return " without_image";
                }
                return (
                    <li className={"orders_list_item_details_goods_tile"}>
                        <div className={"orders_list_item_details_goods_tile_title"}>
                            <figure className="orders_list_item_details_goods_tile_figure">
                                <span className={"orders_list_item_details_goods_tile_picture " + (getImageClass())}>
                                      {getImage()}
                                </span>
                                <figcaption>{detail.name}</figcaption>
                            </figure>
                        </div>
                        <div className={"orders_list_item_details_goods_tile_options"}>
                            <div className={"orders_list_item_details_goods_tile_option"}>
                                {detail.quantity > 1 && <>
                                    <div
                                        className={"orders_list_item_details_goods_tile_label"}>{label('Quantity')}</div>
                                    <div className={"orders_list_item_details_goods_tile_digit"}>{detail.quantity}</div>
                                </>}
                            </div>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

const OrderItemPayments: FC<{ order: OrdersListItem }> = ({order}) => {
    return (
        <ul className={"orders_list_item_details_payments"}>
            {order.type === 'package_pickup' && <li className={"orders_list_item_details_payments_row"}>
                <span className={"orders_list_item_details_payments_label"}>{label('Used Days')}</span>
                <span className={"orders_list_item_details_payments_value"}>{order.duration}</span>
            </li>}
            <li className={"orders_list_item_details_payments_row"}>
                <span className={"orders_list_item_details_payments_label"}>{label('Payment method')}</span>
                <span className={"orders_list_item_details_payments_value"}>{order.payment_method_name}</span>
            </li>
            {+order.deposit > 0 && <li className={"orders_list_item_details_payments_row"}>
                <span className={"orders_list_item_details_payments_label"}>{label('Deposit')}</span>
                <span className={"orders_list_item_details_payments_value"}>{order.deposit}€</span>
            </li>}
            {+order.status === 2 && <li className={"orders_list_item_details_payments_row"}>
                <span className={"orders_list_item_details_payments_label"}>{label('Total paid')}</span>
                <span className={"orders_list_item_details_payments_value sum_value"}>{order.paid}€</span>
            </li>}
            <li className={"orders_list_item_details_payments_row"}>
                <span className={"orders_list_item_details_payments_label"}>{label('Total price')}</span>
                <span className={"orders_list_item_details_payments_value sum_value"}>{order.total}€</span>
            </li>
        </ul>
    )
}

export default OrderItemDetails;
