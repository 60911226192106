import React, {FC} from 'react';
import {label} from "../../service";

export const MotorcycleRent:FC<{
     duration:number,
     price:{
         total:number,
         discount:number,
         real:number
     }}> = ({price,duration}) => {

    if(!( duration > 0)){
        return null
    }
    return (
        <div className="total-checked-rent">

                <>
                    <div className="total-checked-rent_item">
                    <div className="total-checked-rent_item_name">{label('Motorcycle Rent')}</div>
                    <div className="total-checked-rent_item_date">{price.discount?price.total:price.real}</div>
                </div>
                    {!!price.discount &&
                        <>
                            <div className="total-checked-rent_item" style={{color: "red"}}>
                                <div className="total-checked-rent_item_name">{label('Discount')}  </div>
                                <div className="total-checked-rent_item_date">{price.discount}</div>
                            </div>
                            <div className="total-checked-rent_item">
                                <div className="total-checked-rent_item_name"/>
                                <div className="total-checked-rent_item_date">{price.real}</div>
                            </div>
                        </>
                    }

                </>


        </div>


    );
};

