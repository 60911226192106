import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {TourBookCheckoutBikes} from "./TourBookCheckoutBikes";
import {TourBookCheckoutExtras} from "./TourBookCheckoutExtras";
import {TourBookCheckoutSizes} from "./TourBookCheckoutSizes";
import {TourBookCheckoutUser} from "./TourBookCheckoutUser";
import {fetchTourOrder} from "../../features/tours/tourBookSlice";
import {TourBookData} from "../../types/tourBook";
import {TourBookCalc} from "./TourBookCalc";
import {getPromoDiscount, getTourBookTotal} from "../../utils/tourOrder";
import {label} from "../../service";
import {TourBookCheckoutPayment} from "./TourBookCheckoutPayment";
import {processSuccess,resetSuccess} from "../../features/tours/tourBookSlice";
import {PaymentMethod} from "../../types";
import {useLocation} from "react-router-dom";
import {OrderModal} from "../common/OrderModal";
import {TourBookCheckoutHotels} from "./TourBookCheckoutHotels";

export const TourBookCheckout = () => {
    const stage = useAppSelector(state => state.tourBook.stage);
    const values = useAppSelector(state => state.tourBook.values);
    const user = useAppSelector(state => state.tourBook.user);
    const equip = useAppSelector(state => state.tourBook.equip);
    const extra = useAppSelector(state => state.tourBook.extra);
    const rules = useAppSelector(state => state.tourBook.rules);
    const hotels = useAppSelector(state => state.tourBook.hotels);
    const insurances = useAppSelector(state => state.tourBook.insurances);
    const bikes = useAppSelector(state => state.tourBook.bikes);
    const info = useAppSelector(state => state.tourBook.info);
    const promo = useAppSelector(state => state.tourBook.promo);
    const success = useAppSelector(state => state.tourBook.success);
    const invoiceUrl = useAppSelector(state => state.tourBook.invoiceUrl);
    const payment_methods = useAppSelector(state => state.tourBook.payment_methods);
    const profile = useAppSelector(state => state.login.profile);

    const dispatch = useAppDispatch();

    const location = useLocation();

    const total = getTourBookTotal(values, equip, extra, rules, bikes, hotels, insurances, info.days_number,promo);
    const promoDiscount = getPromoDiscount(values,promo,bikes,info.days_number);
    const method = payment_methods.find((method: PaymentMethod) => method.id === values.method);

    const [paid, setPaid] = useState<boolean>(false);

    useEffect(() => {
        if (+success === 2 && invoiceUrl !== '' && values.method) {
            let target = "_self";
            if (method && method.target) {
                target = method.target;
            }
            // 👇 Open link in new tab programmatically
            window.open(invoiceUrl, target, 'noreferrer');
        }
    }, [success, invoiceUrl]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('order')) {
            setPaid(true);
        }
    }, [location.search]);

    const closePaid = () => {
        setPaid(false);
    }

    const bookTour = () => {
        let values_rules = values.rules;
        if (values.bike_id===-1) {
            values_rules = values.rules.filter(val=>{
                const find = rules.find((t)=>{
                    return (t.id === val.id && (t.type!==0 || t.subtype!=1));
                })
                return find!==undefined;
            })
        } else {

        }
        const bookData: TourBookData = {
            bike_id: values.bike_id,
            tour_id: values.tour_id,
            promo_id: +promoDiscount !==0 ? promo.promo_id : 0,
            user: user,
            extra: values.extras,
            accessor: values.accessories,
            rules: [...values_rules,...values.hotels,...values.insurances],
            amount: total,
            percent: values.percent,
            method: values.method,
            passenger: values.passenger,
            user_id:+profile.user_id
        }
        /*console.log('values',values);
        console.log('values_rules',values_rules);
        console.log('bookData',bookData);
        console.log('rules',rules);
        return;*/
        // @ts-ignore
        dispatch(processSuccess());
        dispatch(fetchTourOrder(bookData));
    }

    const getCheckoutPage = () => {
        switch (stage) {
            case 0:
                return <TourBookCheckoutBikes/>;
            case 1:
                return <TourBookCheckoutExtras/>;
            case 2:
                return <TourBookCheckoutSizes/>;
            case 3:
                return <TourBookCheckoutHotels/>;
            case 4:
                return <TourBookCheckoutUser/>;
            case 5:
                return <TourBookCheckoutPayment
                    bookTour={bookTour}
                />

        }
        return null;
    }

    const getSuccess = () => {
        switch(+success){
            case -1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Something wrong.')}</h1>
                        <span>{label('Please try again later.')}</span>
                        <div className={"btn btn-secondary"} onClick={resetErrorLayer}>OK</div>
                    </div>
                )
            case 1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order in progress.')}</h1>
                        <span className="loader"/>
                    </div>
                )
            case 2:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order successfully accepted.')}</h1>
                        <span>{label('We will contact you shortly..')}</span>
                    </div>
                )
        }
    }

    const resetErrorLayer = () => {
        dispatch(resetSuccess());
    }

    return (
        <div className={"tour_book_checkout"}>
            {paid && <OrderModal
                onClose={closePaid}
            />}
            <div className={"tour_book_checkout_layer " + (!success ? "disable_layer" : "")}>
                {getSuccess()}
            </div>
            <div className={"checkout_page"}>
                {getCheckoutPage()}
                <div className={"date-select"}>
                    <div className="date-select-item">
                        <TourBookCalc/>
                    </div>
                </div>
            </div>
        </div>
    );
}