import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {validateEmail, validateName, validatePhone} from "../../utils";
import {label} from "../../service";
import {useLocation} from "react-router-dom";
import {OrderPackage, PaymentMethod, UserError} from "../../types/packages";
import {fetchPackageOrder, processSuccess, resetSuccess, updateUser} from "../../features/packages/packagesSlice";
import {CheckoutPackageUser} from "./CheckoutPackageUser";
import {CheckoutPackagePercent} from "./CheckoutPackagePercent";
import {CheckoutPackageMethod} from "./CheckoutPackageMethod";
import {CheckoutPackageTerms} from "./CheckoutPackageTerms";
import {CheckoutPackageCalc} from "./CheckoutPackageCalc";

export const PackagesCheckout = () => {
    const [error, setError] = useState<UserError>({
        email: false,
        phone: false,
        name: false,
        surname: false,
        zipcode: false,
        city: false,
        password: false,
        date: false,
        birthdate: false,
        country: false,
        street: false,
        is_firm: false,
        firm_name: false,
        ICO: false,
        DIC: false,
        IC_DPH: false,
        percent: false,
        method: false,
        terms: false,
    });

    const values = useAppSelector(state => state.packages.values);
    const user = useAppSelector(state => state.packages.user);
    const profile = useAppSelector(state=>state.login.profile);
    const states = useAppSelector(state => state.common.states);
    const checkEmailError = useAppSelector(state=>state.packages.checkEmailError);
    const agreeTerms = useAppSelector(state => state.packages.agreeTerms);

    const dispatch = useAppDispatch();

    const location = useLocation();

    const validateData = (needSetError = false) => {
        const new_errors: UserError = {
            email: false,
            phone: false,
            name: false,
            surname: false,
            zipcode: false,
            city: false,
            password: false,
            date: false,
            birthdate: false,
            country: false,
            street: false,
            is_firm: false,
            firm_name: false,
            ICO: false,
            DIC: false,
            IC_DPH: false,
            percent: false,
            method: false,
            terms: false,
        }
        let result = true;
        if (!validateEmail(user.email)) {
            new_errors.email = true;
            result = false;
        }
        if (!validatePhone(user.phone)) {
            new_errors.phone = true;
            result = false;
        }
        if (!validateName(user.name)) {
            new_errors.name = true;
            result = false;
        }
        if (+user.country_id === 0) {
            new_errors.country = true;
            result = false;
        }
        if(user.city === ''){
            new_errors.city = true;
            result = false;
        }
        if (user.street === '') {
            new_errors.street = true;
            result = false;
        }
        if (user.is_firm > 0) {
            if (user.firm_name === '') {
                new_errors.firm_name = true;
                result = false;
            }
            if (user.ICO === '') {
                new_errors.ICO = true;
                result = false;
            }
            if (user.DIC === '') {
                new_errors.DIC = true;
                result = false;
            }
            if (user.IC_DPH === '') {
                new_errors.IC_DPH = true;
                result = false;
            }
        }
        if (+values.percent === 0) {
            new_errors.percent = true;
            result = false;
        }
        if (!values.method) {
            new_errors.method = true;
            result = false;
        }
        if (!agreeTerms) {
            new_errors.terms = true;
            result = false;
        }
        if (needSetError) {
            setError(new_errors);
        }
        return result;
    }

    const handleConfirm = () => {
        if(!validateData(true)){
            return;
        }
        const orderData: OrderPackage = {
            package_id: values.package_id,
            user: user,
            percent : values.percent,
            method : values.method,
            user_id: +profile.user_id
        }

        dispatch(processSuccess());
        dispatch(fetchPackageOrder(orderData));
    }

    let canBook = true;
    if(!validateData()){
      //  canBook = false;
    }

    return (
        <div className={"packages_checkout "}>
            <div className={"packages_checkout_page"}>
                <div className={"checkout"}>
                    <div className={"selected-block bikes-block"}>
                        <CheckoutPackageUser error={error} profile={profile} states={states} checkEmailError={checkEmailError}/>
                        {false && <CheckoutPackagePercent error={error}/>}
                        <CheckoutPackageMethod error={error}/>
                        <CheckoutPackageTerms error={error}/>
                    </div>
                    <div className="booking-block two">
                        <div className={"btn btn-allow "+(!canBook ? " not-allowed opacity-50":"")}
                             onClick={handleConfirm}>{label('Confirm')}</div>
                    </div>
                </div>
                <div className="date-select">
                    <div className="date-select-item">
                        <CheckoutPackageCalc />
                    </div>
                </div>
            </div>
        </div>
    )
}