import React from "react";
import {label} from "../../service";

export const HomeBooking = () => {
        return (
            <div className={"home_slider_booking"}>
                <div className={"home_slider_booking_area"}>
                    <div className={"home_slider_booking_header"}>{label('Quick Motorcycle Booking')}</div>
                    <div className={"home_slider_booking_form"}>

                    </div>
                </div>
            </div>
        )
}