import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {Accessories} from "./Accessories";
import {Extra} from "./Extra";
import {Equipments, Extras} from "../../types";
import {label} from "../../service";
import {Insurances} from "./Insurances";
import ItemsArea from "../common/ItemsArea";
import {getCurrent} from "../../utils/itemsArea";
import {useSet} from "../../hooks";
import {SetT} from "../../hooks/use-map";
import {ExtraKm} from "./extra_km/inde";
interface CheckoutPageOneProp {
    equip: Equipments[],
    extra: Extras[],
    insurances: any[],
    onAccessoryChange: (id: number, count: number) => void,
    onAccessoryChangeCount: (id: number, count: number) => void,
    onExtraChange: (id: number) => void,
    onInsurancesChange: (id: number) => void,
    nextPage: (page: number) => void
    accessor: {}
    extras: SetT<number>
    insurances_values: SetT<number>
    date: { start: string, end: string, duration: number }
    needSize: boolean
    checkoutPrice: string
    duration: number,
    few:1|0
}

export const CheckoutPageOne: FC<CheckoutPageOneProp> = ({
                                                             equip,
                                                             extra,
                                                             insurances,
                                                             insurances_values,
                                                             onAccessoryChange,
                                                             onAccessoryChangeCount,
                                                             onExtraChange,
                                                             onInsurancesChange,
                                                             nextPage,
                                                             date,
                                                             accessor,
                                                             extras,
                                                             needSize,
                                                             checkoutPrice,
                                                             duration,
    few
                                                         }) => {

    const validate = () => {
        if (date.start === '' || date.end === '') {
            return false;
        }
        nextPage(needSize ? 1 : 2);
    }

    const [current, setCurrent] = useState("");

    useLayoutEffect(() => {
        let newCurrent = getCurrent([
                {type: 'equip', items: equip},
                {type: 'extra', items: extra},
                {type: 'insurance', items: insurances},
            ]
            , current);

        if(current!==newCurrent){
            setCurrent(newCurrent);
        }
    }, [equip, extra, insurances]);

    const handleType = (type: string) => {
        setCurrent(type);
    }

    return (
        <div className="checkout">
            {!!few && <div className={"low-available"}>{label("Low Availability!")}</div>}
            <div className="selected-block">
                {equip.length > 0 && <div className="main-data">
                    <ItemsArea
                        label={label('Accessories')}
                        type={'equip'}
                        canOpen = {true}
                        opened={current === "equip"}
                        changeType={handleType}
                        lengthItems = {equip.length}
                        children={
                            <Accessories equip={equip} accessor={accessor} onAccessoryChange={onAccessoryChange}
                                         checkoutPrice = {checkoutPrice}
                                         duration = {duration}
                                         onAccessoryChangeCount={onAccessoryChangeCount}/>
                        }
                    />
                </div>}
                {extra.length > 0 && <div className="main-data">
                    <ItemsArea
                        label={label('Extra')}
                        type={'extra'}
                        canOpen = {true}
                        opened={current === "extra"}
                        changeType={handleType}
                        rentalClass={" radio_values "}
                        lengthItems = {extra.length}
                        children={
                            <Extra extra={extra} extras={extras} onChange={onExtraChange}/>
                        }
                    />
                </div>}
                {insurances.length > 0 && <div className="main-data">
                    <ItemsArea
                        label={label('Insurances')}
                        type={'insurance'}
                        canOpen = {true}
                        opened={current === "insurance"}
                        changeType={handleType}
                        rentalClass={" radio_values "}
                        lengthItems = {insurances.length}
                        children={
                            <Insurances insurances={insurances} onChange={onInsurancesChange}
                                        checkoutPrice = {checkoutPrice}
                                        duration = {duration}
                                        values={insurances_values}/>
                        }
                    />
                </div>}
            </div>
            <div className="booking-block">
                {date.start && date.end && <div className={"btn  " + " btn-secondary"}
                                                onClick={validate}
                >{label('Next')}</div>}
            </div>


        </div>
    );
};
