import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useScrollTop} from "../hooks";
import {fetchFaqList} from "../features/faq/faqSlice";
import FaqMenu from "../components/faq/FaqMenu";
import FaqList from "../components/faq/FaqList";
import {label} from "../service";
import "./faq.scss";

export const Faq = () => {
    const [focus,setFocus] = useState(0);

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchFaqList());
    }, []);

    const handleMenu = (category_id:number) => {
        setFocus(category_id);
    }
    // console.log('f',focus);
    return (
        <main className={"faq page page w-full"}>
            <div className={"faq_wrap w-wrap"}>
                <h1 className="title">{label('FAQ')}</h1>
                    <FaqList
                        focus = {focus}
                    />
            </div>
        </main>
    )
}