import React, {FC, Fragment} from 'react';
import {CityItem, TourItem} from "../../types/tour";
import {useNavigate} from "react-router";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {Link} from "react-router-dom";

interface TourCardProps {
    card: TourItem
}

export const TourCardItem: FC<TourCardProps> = ({card}) => {
    // const navigate = useNavigate();
    // const handleMore = () => {
    //     navigate(card.link);
    // }

    return (
        <div className="tours_item">
            <div className="tours_item_picture">
                <Link className={"tour-pict " + (!card.image ? " empty-pict " : "")} style={{
                    backgroundImage: `url("${card.image}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}
                      to={card.link}
                    //  onClick={handleMore}
                >
                    <div className={"tours_item_picture_gradient"}>
                        <div className="tours_item_title">
                            <h2>{card.alias}</h2>
                        </div>
                        <div className={"tours_item_body"}>
                            <div className={"tours_item_info"}>
                                <div className="tours_item_countries">
                                    <h3>{card.countries.join(', ')}</h3>
                                </div>
                                <div className="tours_item_days">
                                    <h3>{`${card.days_number} ` + (+card.days_number > 1 ? label('Days') : label('Day'))}</h3>
                                </div>
                                <div className="tours_item_price">
                                    <h3>€ {card.price}</h3>
                                </div>
                            </div>
                            <div className={"tours_item_button"}>
                                <Link className={"btn_more"}
                                      to={card.link}
                                    // onClick={handleMore}
                                >{label('More')}
                                </Link>
                            </div>
                        </div>
                    </div>


                </Link>
            </div>
        </div>
    );
};

interface TourCardLocations {
    cities_ids: number[]
}

const TourCardLocations: FC<TourCardLocations> = ({cities_ids}) => {
    const cities = useAppSelector(state => state.tours.cities);
    let show_cities = [] as string[];
    cities_ids.forEach((city_id: number) => {
        if (cities[city_id] !== undefined) {
            show_cities.push(cities[city_id].name);
        }
    });

    if (!cities_ids.length) {
        return <div className="tours_item_location"/>
    }
    return (
        <div className="tours_item_location">
            <label>{label('Location')}:&nbsp;</label>
            {show_cities.map((city: string) => {
                return (
                    <Fragment key={city}>
                        <div className="tours_item_location_name">{city}</div>
                        <div className="tours_item_location_separator">-</div>
                    </Fragment>
                )
            })}
        </div>
    )
}

