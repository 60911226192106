import React, {FC, useEffect} from "react";

interface rouletteProps {
    exitHandler:()=>void
    mail:string
    sound:boolean
}
export const Roulette:FC<rouletteProps> = ({exitHandler,mail,sound}) => {

    useEffect(()=> {
        window.addEventListener('message', handleIframeTask);
        return () => {
            document.removeEventListener("message", handleIframeTask);
        };
    },[]);

    // @ts-ignore
    const handleIframeTask =(e)=> {
        if (e.data === 'close') {
            exitHandler();
        }
    }

    const soundName= sound ? 'onn':'off';
    return (
        <iframe src={`/roulette/main?mail=${mail}&sound=${soundName}`} name={"roulette"}/>
    )
}