import React, {useState} from 'react';
import {useAppSelector} from "../../app/hooks";
import {LanguageItem} from "../../types/common";
import "./language.scss";
import {BsChevronDown} from "react-icons/bs";
import {useLocation} from "react-router-dom";

export const LangPanel = () => {
    const [hover, setHover] = useState(false);

    const languages = useAppSelector(state => state.common.languages);
    const current_language = useAppSelector(state => state.common.current_language);
    const lang_domain = useAppSelector(state => state.common.lang_domain);

    const location = useLocation();

    const handleStartHover = () => {
        setHover(true);
    }

    const handleEndHover = () => {
        setHover(false);
    }


    let style = {};
    if (hover) {
        style = {height: (languages.length * 66) + 'px'}
    }

    const prepareLanguageUrl = (code:string) => {
        let url = '';
        if(lang_domain){
            url += 'https://'+code+'.'+document.location.hostname.substring(3)+document.location.pathname;
        }else{
            url += 'https://'+document.location.hostname+document.location.pathname;
            const searchParams = new URLSearchParams(document.location.search);
            searchParams.set('lang',code);
            url += '?'+searchParams.toString();
        }

        return url;
    }

    return (
        <div className="language">
            <div className="language_string">
                {
                    languages.map((language: LanguageItem) => {
                        let imageSrc = language.image ? language.image : "/www/images/" + language.code + ".jpg";
                        const active = language.code === current_language;
                        return (
                            <div className={"language_string_item " + (active ? ' active_language' : '')}
                                 key={language.code}
                            >
                                <a
                                   // href={"/?lang=" + language.code}
                                    href={prepareLanguageUrl(language.code)}
                                   className="language_string_item_link"
                                   title={language.name}>
                                    <img src={imageSrc} alt={""}/>
                                </a>
                            </div>
                        )
                    })
                }
            </div>
            {false && <div className="language__widget"
                           onMouseOver={handleStartHover} onMouseOut={handleEndHover}
            >
                <div className="language__list"
                     style={style}
                >
                    {
                        languages.map((language: LanguageItem) => {
                            let imageSrc = language.image ? language.image : "/www/images/" + language.code + ".jpg";
                            const active = language.code === current_language;
                            return (
                                <div className={"language__list-item " + (active ? ' active_language' : '')}
                                     key={language.code}
                                >
                                    <a href={"/?lang=" + language.code}
                                       className="language__list-link"
                                       style={{
                                           backgroundImage: "url(" + imageSrc + ")",
                                           backgroundPosition: 'center',
                                           backgroundSize: 'cover'
                                       }}
                                       title={language.name}/>
                                    {false && active && <BsChevronDown/>}
                                </div>
                            )
                        })
                    }
                </div>
            </div>}
        </div>
    );
};

