import {useCallback, useMemo, useReducer, useState} from "react";
export type SetT<T>={
    add:(key:T)=>void,
    has:(key:T)=>boolean,
    delete:(key:T)=>void,
    clear:()=>void,
    forEach:(callback:(item:T)=>void)=>void,
    arrayFrom:()=>T[],
    size:number ,
    __version__: number,
}
export function useSet<T>(values?: Iterable<T>| readonly  T[]) {
    const [count, increment] = useReducer(v => v + 1, 0);
    const set = useMemo(() => new Set(values), []);

    const add = useCallback(
        (value:T) => {
            set.add(value);
            increment();
        },
        [set]
    );

    const clear = useCallback(() => {
        set.clear();
        increment();
    }, [set]);

    const remove = useCallback(
        (value:T) => {
            set.delete(value);
            increment();
        },
        [set]
    );

    const forEach = useCallback(
        (callback:any) => {
            set.forEach(callback);
        },
        [set]
    );

    const has = useCallback (
        (value:T) => {
            return set.has(value);
        },
        [set]
    );

    const size = useCallback(() => {
        return set.size;
    }, [set]);


    const arrayFrom =  useCallback(() => {
        return Array.from(set);
    }, [set]);
    const resultSet = useMemo(() => {
        return {
            add,
            has,
            delete: remove,
            clear,
            forEach,
            arrayFrom,
            get size() {
                return size();
            },
            __version__: count,
        };
    }, [count]);

    return resultSet;
}
/*
export function useSet<T>(intialValue?: Iterable<T>) {
    const [set] = useState(() => new Set(intialValue));
    const [size, setSize] = useState(set.size);

    const componentSet = useMemo(() => {
        return {
            add(value: T) {
                const result = set.add(value);
                setSize(set.size);
                return result;
            },

            has(value: T) {
                return set.has(value);
            },

            delete(value: T) {
                const result = set.delete(value);
                setSize(set.size);
                return result;
            },

            clear() {
                set.clear();
                setSize(0);
            },

            map<R>(mapper: (value: T) => R) {
                const result: R[] = [];

                set.forEach((item) => {
                    result.push(mapper(item));
                });

                return result;
            },

            get size() {
                return size;
            }
        };
    }, [set, size]);

    return componentSet;
}
*/
export  function useMap<K, V>(
    intialValue?: ReadonlyArray<readonly [K, V]>
) {
    const [map] = useState(() => new Map(intialValue));
    const [size, setSize] = useState(map.size);

    const componentMap = useMemo(() => {
        return {
            set(key: K, value: V) {
                const result = map.set(key, value);
                setSize(map.size);
                return result;
            },

            has(key: K) {
                return map.has(key);
            },

            delete(key: K) {
                const result = map.delete(key);
                setSize(map.size);
                return result;
            },

            clear() {
                map.clear();
                setSize(0);
            },

            map<R>(mapper: (value: V, key: K) => R) {
                const result: R[] = [];

                map.forEach((value, key) => {
                    result.push(mapper(value, key));
                });

                return result;
            },

            get size() {
                return size;
            }
        };
    }, [map, size]);

    return componentMap;
}