import React from "react";
import {useAppSelector} from "../../app/hooks";
import {TourItemAttraction, TourItemCity, TourItemDate} from "../../types/tourItem";
import moment from "moment";
import {Link} from "react-router-dom";
import {label} from "../../service";
import {useNavigate} from "react-router";
import {CountriesOverview} from "./CountriesOverview";
import {CitiesOverview} from "./CitiesOverview";
import {AttractionsOverview} from "./AttractionsOverview";
import {DatesOverview, TourInfoOverviewDate} from "./TourInfoOverviewDate";

export const TourInfoOverview = () => {
    const info = useAppSelector(state => state.toursItem.info);
    if (!info.name) {
        return null;
    }
    return (
        <div className={"tour_info_overview"}>
            <div className={"tour_info_overview_header"}>
                <h2>{label('Tour Overview')}</h2>
            </div>
            <CitiesOverview/>
            <AttractionsOverview/>
            <DatesOverview/>
        </div>
    );
}

