import React, {FC, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {DayImage, Gallery, TourItemAttraction, TourItemCity, TourItemDay} from "../../types/tourItem";
import {label} from "../../service";
import {GallerySlider} from "../common/GallerySlider";
import {showGallery} from "../../features/galleryViewer/galleryViewerSlice";

export const TourInfoDays = () => {
    const days = useAppSelector(state => state.toursItem.days);

    if (!days.length) {
        return null;
    }
    return (
        <div className={"tour_info_days"}>
            <div className={"tour_info_days_wrap"}>
                <div className={"tour_info_days_items"}>
                    {days.map((day: TourItemDay) => {
                        return (
                            <TourInfoDay
                                key={day.day_numbers}
                                day={day}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

interface dayProps {
    day: TourItemDay
}

const TourInfoDay: FC<dayProps> = ({day}) => {
    const cities = useAppSelector(state => state.toursItem.cities);
    const attractions = useAppSelector(state => state.toursItem.attractions);
    const [showMore, setShowMore] = useState(false);
    // @ts-ignore
    const itemRef = React.useRef(React.MutableRefObject<null>);

    const prepareDayHeader = () => {
        let out = '';
        day.city_ids.forEach((id: number) => {
            const city = cities.find((city: TourItemCity) => +city.id === +id);
            if (city) {
                if (out) {
                    out += ' - ';
                }
                out += city.name;
            }
        });

        return out;
    }

    const prepareHighlights = () => {
        let out = '';
        {
            day.attraction_ids.forEach((id: number) => {
                const attraction = attractions.find((attraction: TourItemAttraction) => +attraction.id === +id);
                if (attraction) {
                    if (out) {
                        out += ', ';
                    }
                    out += attraction.name;
                }
            })
        }

        return out;
    }

    const handleMore = () => {
        console.log('more', showMore);
        setShowMore(!showMore);
    }

    const dispatch = useAppDispatch();

    const handleImage = (images: DayImage[], index: number) => {
        dispatch(showGallery({images:images,current:index}));
    }

    const needMore = (day.images.length>1);

    const arrowUp = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.001 0 34 34" width="34" height="34">
        <rect x="0.00146484" width="34" height="34" fill="white"/>
        <path d="M17.3584 14.1462L21.8538 18.6431C22.0507 18.8405 22.0507 19.1602 21.8538 19.358C21.657 19.5553 21.3373 19.5553 21.1405 19.358L17.0017 15.2177L12.863 19.3575C12.6661 19.5548 12.3464 19.5548 12.1491 19.3575C11.9523 19.1602 11.9523 18.84 12.1491 18.6427L16.6446 14.1457C16.8394 13.9514 17.1641 13.9514 17.3584 14.1462Z" fill="#363637"/>
    </svg>;
    const arrowDown = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.001 0 34 34" width="34" height="34">
        <rect x="0.00146484" width="34" height="34" fill="white"/>
        <path d="M17.3584 19.3598L21.8538 14.8628C22.0507 14.6655 22.0507 14.3458 21.8538 14.148C21.657 13.9507 21.3373 13.9507 21.1405 14.148L17.0017 18.2882L12.863 14.1485C12.6661 13.9512 12.3464 13.9512 12.1491 14.1485C11.9523 14.3458 11.9523 14.666 12.1491 14.8633L16.6446 19.3603C16.8394 19.5546 17.1641 19.5546 17.3584 19.3598Z" fill="#363637"/>
    </svg>;

    const imageDay = (day.images.length > 0)?`url('${day.images[0].image_medium}')`:'none';

    /*React.useEffect(() => {
        console.log('useEffect',itemRef.current.offsetHeight);
    });*/
    const isMobile = window._common.mobile;

    return (
        <div className={"tour_info_days_item "+(showMore?'show_more':'')} key={day.day_numbers} ref={itemRef}>
            <div className={"tour_info_days_item_desc_area "}>
                <div className={"tour_info_days_item_desc"}>
                    <div className={"tour_info_days_item_number"}>{`${day.day_numbers} ${label('Day')}`}</div>
                    <h3>{prepareDayHeader()}</h3>
                    {day.attraction_ids.length > 0 && <div className={"tour_info_days_item_highlights"}>
                        <label>{prepareHighlights()}</label>
                    </div>}
                    {(day.mileage>0 || day.duration) && <div className={"tour_info_days_item_highlights"}>
                        {day.mileage>0 && <>
                            <label>{label('Distance')}:&nbsp; </label>
                            <div className={"tour_info_days_item_highlights_value"}>
                                {day.mileage+' '+label('km')}
                            </div>
                        </>}
                        {day.duration && <>
                            <label>{label('Duration')}:&nbsp; </label>
                            <div className={"tour_info_days_item_highlights_value"}>
                                {day.duration+' '+label('hours')}
                            </div>
                        </>}
                    </div>}
                    {day.info && <div className="tour_info_days_item_info">{day.info}</div>}
                </div>
                {!isMobile && <div className={"tour_info_days_item_gallery"}>
                    {
                        day.images.map((image,index)=>{
                            return <div className="image" key={index} style={{backgroundImage:`url('${image.image_medium}')`}} onClick={()=>{handleImage(day.images,index)}}></div>
                        })
                    }
                </div>}
            </div>
            {isMobile && <div className={"tour_info_days_item_mobile_gallery"}>
                {
                    day.images.map((image,index)=>{
                        // @ts-ignore
                        return <div key={index}>
                                <img src={image.image_medium} />
                            </div>
                    })
                }
            </div>}
        </div>
    )
}

interface dayGalleryProps {
    images: Gallery[]
}

const TourDayGallery: FC<dayGalleryProps> = ({images}) => {
    let countPerLine = 3;
    return <div className={"tour_info_days_item_images"}>
        <GallerySlider
            slidesPerGroup={countPerLine}
            slidesPerView={countPerLine}
            photos={images}
            loop={images.length > countPerLine}
            navigation={images.length > countPerLine}
            className={"tour_info_days_item_images_slider"}
        />
    </div>

}

