import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {GalleryState} from "../../types/gallery";

export const fetchGalleryList = createAsyncThunk(
    'fetchGalleryList',
    async (thunkAPI)  => {
        return await api.getGalleryList() as GalleryState;

    }
)


const initialState:GalleryState ={
    albums: []
};

export const gallerySlice = createSlice({
    name:"gallery",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchGalleryList.fulfilled,(state,action)=>{
            state.albums =  action.payload.albums;
        })
    }
})

export const {} = gallerySlice.actions;
export const galleryReducer = gallerySlice.reducer;