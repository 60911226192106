import React from "react";
import {label} from "../../service";
import {useAppSelector} from "../../app/hooks";
import {
    PackageRulesHeaderMain, PackageRulesHeaderMainColor, PackageRulesMultiTable,
    PackageRulesTable,
    PackageRulesTextMain,
    PackageRulesTextName,
    PackageRulesTextNormal
} from "./rules";

export const PackagesRules = () => {

    const rules = useAppSelector(state => state.packages.rules);
    const {items} = rules;

    if(!items  || !items.length){
        return null;
    }

    return (
        <div className={"packages_rules"}>
            {items.map((item:any,i:number)=>{
                switch(item.type){
                    case "header_main_color":
                        return <PackageRulesHeaderMainColor text={item.text || ''} key={i}/>
                    case "header_main":
                        return <PackageRulesHeaderMain text={item.text || ''} key={i}/>
                    case "text_main":
                        return <PackageRulesTextMain text={item.text || ''} key={i}/>
                    case "text_normal":
                        return <PackageRulesTextNormal text={item.text || ''} key={i}/>
                    case "text_name":
                        return <PackageRulesTextName text={item.text || ''} name={item.name || ''} key={i}/>
                    case "table":
                        if(!item.table){return null;}
                        return <PackageRulesTable table={item.table} key={i}/>
                    case "multitable":
                        if(!item.table || !item.table.length){return null;}
                        return <PackageRulesMultiTable table={item.table} key={i}/>
                    default:
                        return null;
                }
            })}
        </div>
    )
}