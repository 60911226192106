import "./info_panel.scss";
import { IoClose } from "react-icons/io5";
import {useEffect, useState} from "react";
import {api} from "../../service";

const message = [
    "2 people are currently viewing this motorcycle HONDA NC750X DCT",
    "1 people are currently viewing this motorcycle HONDA NT1100 DCT",
];


const InfoPanel=()=>{


    const [show,setShow] = useState("");

    const [message,setMessage] = useState([])


    const loadData=()=>{

        api.getSeoInfo(0).then((data:any)=>{
            if(data){
                setMessage(data)
            }


        })
    }

    useEffect(()=>{
        if(message.length==0){
            return;
        }
        const startQueue=()=>{
            const showMessage = message.shift();

            console.log("startQueue")
            if(showMessage){
                setShow(showMessage);
                setTimeout(()=>{
                    setShow("")
                },10000);
                setTimeout(()=>{
                    startQueue();

                },20000);
            }else {
                loadData();
            }

        }
        setTimeout(()=>{
            startQueue()
        },2000);

    },[message]);


    useEffect(()=>{
     loadData();
    },[]);


    const close=()=>{
        setShow("")
    }

    return (
        <div className={`fixedMsgHolder ${!!show?"":"hide"}`} >
             <div id="recentBookings" className="hidden-xs hidden-sm">
                <div id="r3" className="alert panel-yellow text-sm"  >
                    <IoClose onClick={close} size={"20px"} className="close"  />
                    <span>{show}</span>
                </div>
            </div>
        </div>
    );
};

export {InfoPanel}