import React, {FC} from "react";
import {ModalPortal} from "./ModalPortel";
import {ModalWrap} from "./ModalWrap";
import {label} from "../../service";
interface orderProps {
    onClose: () => void
    certificate ?: boolean
}
export const OrderModal:FC<orderProps> = ({onClose,certificate}) => {

    return (
        <ModalPortal addClass={"medium-modal"}>
            <ModalWrap closeModal={onClose}>
                <div className={"order_data"}>
                    <h2>{label('Payment Successful.')}</h2>
                    {certificate && <h4>{label('A gift voucher has been sent to your email.')}</h4>}
                    <div className={"order_footer"}>
                        <div className={"btn btn-secondary"}
                             onClick={onClose}>{label('Close')}</div>
                    </div>
                </div>
            </ModalWrap>
        </ModalPortal>
    )
}