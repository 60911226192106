import React from "react";
import {useAppSelector} from "../../app/hooks";

export const TourBookHeader = () => {
    const info = useAppSelector(state => state.tourBook.info);
    const image = useAppSelector(state => state.tourBook.image);

    if(!info.name){
        return null;
    }

    return (
        <section className={"tour_info_header"}
                 style={{
                     backgroundImage: `url("${image}")`,
                     backgroundPosition: "center",
                     backgroundSize: "cover"
                 }}
        >
            <div className={"tour_info_header_gradient"}>
                <div className={"tour_info_caption"}>
                    <h1 className="animated fadeInDown title">{info.name}</h1>
                </div>
            </div>
        </section>
    )
}