import React, {FC} from 'react';
import "./checkbox.scss"
import {ToolTip} from "../ToolTip";
interface CheckBoxInterface{
    onChange:()=>void,
    label?:string
    name:string
    price:string
    checked:boolean
    tooltip?:string
}


export const CheckBox:FC<CheckBoxInterface>= ({ label,onChange,name,price,checked,tooltip}) => {

    return (
        <div className="checkbox-wrapper-12"
             onClick={onChange}
        >
            <div className="cbx">
                <input checked={checked} onChange={()=>{}} type="checkbox"/>
                <label></label>
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                </svg>
            </div>
            <div className="label">
                {name}
                {tooltip && <ToolTip tooltip={tooltip}/>}
            </div>
            <div className="value">{price}</div>
        </div>
    );
};

