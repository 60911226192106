import React, {FC, useMemo} from 'react';
import {clsx} from "clsx";
import {label} from "../../../service";
import {SelectedBook} from "../Checkout";
import {TimeSelectRow} from "./TimeSelectRow";
import { Schedule} from "../../../types";
import {isWorkTime} from "../../../utils";
import {SetT} from "../../../hooks/use-map";

export const RegularTime:FC<{date:SelectedBook,
    timeSelectHandler :(e:React.ChangeEvent<HTMLSelectElement>)=>void
    schedule:Schedule[],
    overtime:SetT<string>
}> = ({date,timeSelectHandler,schedule,overtime}) => {
    const notWorkTime = useMemo(()=>{
        const pickOver =!isWorkTime(date.start,date.optionStart,schedule);
        const dropOver = !isWorkTime(date.end,date.optionEnd,schedule);

        return (pickOver || dropOver)
    },[date]);


    return (
   <> <TimeSelectRow name={"optionStart"}
                     onSelect={timeSelectHandler}
                     value={date.optionStart}
                     label={label("Pick-up")}
                     day={date.start}
                     schedule={schedule}

   />
    <TimeSelectRow  name={"optionEnd"}
                    onSelect={timeSelectHandler}
                    value={date.optionEnd}
                    label={label("Drop-off")}
                    day={date.end}
                    schedule={schedule}
                    filter={date.end===date.start?date.optionStart:undefined}
    />
        <div className={clsx("time-s-desc",!notWorkTime && "invisible")}>* {label('Additional charges may apply for pick-up or drop-off outside business hours')}</div>
   </>
    );
};

