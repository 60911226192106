import React, {FC} from "react";
import {OrdersItem} from "../../types/myPackages";
import {label} from "../../service";
import OrderItem from "../orders/OrderItem";

interface childrenProps{
    children:OrdersItem[]
}
export const MyPackageItemChildren:FC<childrenProps> = ({children}) => {
        return (
            <div className={"my_packages_list_children"}>
                <div className={"my_packages_list_children_header"}>{label('Package Orders')}</div>
                <ul className={"my_packages_list_children_list"}>
                    {children.map((item:OrdersItem)=>{
                        return <OrderItem
                            key={item.id}
                            order={item}
                        />
                    })}
                </ul>
            </div>
        )
}