import React from "react";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import moment from "moment";
import {
    getHotelsPrice,
    getInsurancesPrice, getPromoDiscount,
    getRulesPrice,
    getTourBookPrice,
    getTourBookTotal
} from "../../utils/tourOrder";
import {TourItemDate, TourItemHotel, TourItemInsurance} from "../../types/tourItem";
import {TourBookRuleValue} from "../../types/tourBook";
import {MainInput} from "../tools/MainInput";
import Promo from "./Promo";
import {
    fetchCheckPromo,
    fetchResetPromo,
    processCheckPromo,
    resetCheckPromoError
} from "../../features/tours/tourBookSlice";

export const TourBookCalc = () => {
    const values = useAppSelector(state => state.tourBook.values);
    const equip = useAppSelector(state => state.tourBook.equip);
    const extra = useAppSelector(state => state.tourBook.extra);
    const rules = useAppSelector(state => state.tourBook.rules);
    const hotels = useAppSelector(state => state.tourBook.hotels);
    const insurances = useAppSelector(state => state.tourBook.insurances);
    const dates = useAppSelector(state => state.tourBook.dates);
    const bikes = useAppSelector(state => state.tourBook.bikes);
    const info = useAppSelector(state => state.tourBook.info);
    const promo = useAppSelector(state => state.tourBook.promo);
    const checkError = useAppSelector(state => state.tourBook.checkPromoError);
    const checkingProcess = useAppSelector(state => state.tourBook.checkPromo);

    const dispatch = useAppDispatch();

    if (!info.id) {
        return null;
    }

    const tour = dates.find(date => +date.id === +values.tour_id);
    const tourBikes = bikes[+values.tour_id] || [];
    const bike = tourBikes.find(bike => +bike.id === +values.bike_id);

    const total = getTourBookTotal(values, equip, extra, rules, bikes, hotels, insurances, info.days_number, promo);
    const totalTour = getTourBookPrice(values, rules, bikes, info.days_number);
    const totalRules = getRulesPrice(values, rules, info.days_number);
    const totalHotel = getHotelsPrice(values, hotels, info.days_number);
    const totalInsurances = getInsurancesPrice(values, insurances, info.days_number);
    const totalPromoDiscount = getPromoDiscount(values, promo, bikes, info.days_number);

    let canBook = false;
    const chosenDate = dates.find((date: TourItemDate) => date.id === +values.tour_id);
    if (chosenDate && chosenDate.left_bikes > 0) {
        canBook = true;
    }

    const isHotel = values.hotels.find((rule: TourBookRuleValue) => {
        return rule.status === 2
    });

    const isInsurance = values.insurances.find((rule: TourBookRuleValue) => {
        return rule.status === 2
    });

    const handleCheckPromo = (code: string) => {
        if(code){
            // @ts-ignore
            dispatch(processCheckPromo());
            dispatch(fetchCheckPromo(code));
        }
    }

    const handleRemovePromo = (promo_id: number) => {
        dispatch(fetchResetPromo(promo_id));
    }

    const resetError = () => {
        // @ts-ignore
        dispatch(resetCheckPromoError());
    }

    return (
        <div className="order-calc">
            <div className={"heading"}>{label('Total')}</div>
            {canBook ? <div className="total-checked">
                    {tour && <div className="total-checked-date">
                        {tour.start_ts && <div className="total-checked-date_item">
                            <div className="total-checked-date_item_name">{label('Pick-up')}</div>
                            <div
                                className="total-checked-date_item_date">{moment(tour.start_ts * 1000).format('DD.MM.YYYY')}</div>
                        </div>}
                        {tour.end_ts && <div className="total-checked-date_item">
                            <div className="total-checked-date_item_name">{label('Drop-off')}</div>
                            <div
                                className="total-checked-date_item_date">{moment(tour.end_ts * 1000).format('DD.MM.YYYY')}</div>
                        </div>}
                    </div>}
                    <div className="total-checked-rent">
                        <div className="total-checked-rent_item">
                            <div className="total-checked-rent_item_name">{label('Tour Price')}</div>
                            <div className="total-checked-rent_item_date">{totalTour}</div>
                        </div>
                    </div>
                    {values.passenger > 0 && <div className="total-checked-passenger">
                        <div className="total-checked-passenger_item">
                            <div className="total-checked-passenger_item_name">{label('Passenger')}</div>
                            <div className="total-checked-passenger_item_date">{totalRules}</div>
                        </div>
                    </div>}
                    {isHotel && <div className="total-checked-hotels">
                        {hotels.map((hotel: TourItemHotel, index) => {
                            if (hotel.id === isHotel.id) {
                                return (
                                    <div className="total-checked-hotels_item" key={index}>
                                        <div className="total-checked-hotels_item_name">{hotel.name}</div>
                                        <div className="total-checked-hotels_item_date">{totalHotel}</div>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>}
                    {isInsurance && <div className="total-checked-hotels">
                        {insurances.map((insurance: TourItemInsurance, index) => {
                            if (insurance.id === isInsurance.id) {
                                return (
                                    <div className="total-checked-hotels_item" key={index}>
                                        <div className="total-checked-hotels_item_name">{insurance.name}</div>
                                        <div className="total-checked-hotels_item_date">{totalInsurances}</div>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>}
                    {Object.keys(values.accessories).length > 0 && <div className="total-checked-accessories">
                        {equip.map(item => {
                            if (values.accessories[item.id] !== undefined) {
                                let count = values.accessories[item.id].count || 1;
                                return <div className="total-checked-accessories_item"
                                            key={item.id}
                                >
                                    <div className="total-checked-accessories_item_name">
                                        {count > 1 && <div
                                            className="total-checked-accessories_item_name_count">{count + 'x '}&nbsp;</div>}
                                        <div className="total-checked-accessories_item_name_value">{item.name}</div>
                                    </div>
                                    <div className="total-checked-accessories_item_price">
                                        {!!item.count ? (item.price > 0 ? (+item.period === 1 ? +item.price * count * info.days_number : +item.price * count) : label('Free')) :
                                            <span className={"for-request"}>{label('For request')}</span>}
                                    </div>
                                </div>
                            } else {
                                return null;
                            }
                        })}
                    </div>}
                    {values.extras.length > 0 && <div className="total-checked-extras">
                        {extra.map(item => {
                            if (values.extras.includes(item.id)) {
                                return <div className="total-checked-extras_item"
                                            key={item.id}
                                >
                                    <div className="total-checked-extras_item_name">
                                        <div className="total-checked-extras_item_name_value">{item.name}</div>
                                    </div>
                                    <div className="total-checked-extras_item_price">
                                        {+item.price > 0 ? 1 * +item.price : label('Free')}
                                    </div>
                                </div>
                            } else {
                                return null;
                            }
                        })}
                    </div>}
                    {+totalPromoDiscount !== 0 && <div className="total-checked-rent">
                        <div className="total-checked-rent_item">
                            <div className="total-checked-rent_item_name">{label('Promo Discount')}</div>
                            <div className="total-checked-rent_item_date">{totalPromoDiscount}</div>
                        </div>
                    </div>}
                </div>
                :
                <div className="total-checked">
                    <div className="sold_out_calc btn btn-danger not-allowed opacity-75">{label('Sold Out')}</div>
                </div>
            }
            {canBook && <div className="total-amount">
                <div className="total-amount_name">{label('Total price')}</div>
                <div className="total-amount_value"><span>{total}€</span></div>
            </div>}
            {false && <Promo
                type={promo.type || ""}
                number={promo.number || ""}
                code={promo.code || ""}
                text={promo.text || ""}
                mode={promo.text || "promo"}
                mail={promo.text || ""}
                promo_id={promo.promo_id}
                start_rental={promo.start_rental}
                end_rental={promo.end_rental}
                checkPromo={handleCheckPromo}
                removePromo={handleRemovePromo}
                checkError = {checkError}
                resetError = {resetError}
                checkingProcess = {checkingProcess}
                promoDiscount = {+totalPromoDiscount}
            />}
        </div>
    );
}