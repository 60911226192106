import React, {FC} from "react";
import {PackageItem} from "../../types/packages";
import {Checkbox} from "@mui/material";
import {useAppDispatch} from "../../app/hooks";
import {checkPackage} from "../../features/packages/packagesSlice";
import {label} from "../../service";
import {sendUserAction} from "../../features/bike/bikeSlice";

interface itemProps{
    item:PackageItem
    checked:boolean
}
export const PackageListItem:FC<itemProps> = ({item,checked}) => {

    const dispatch = useAppDispatch();

    const handleCheck = () => {
        dispatch(checkPackage({package_id:item.id}));
        dispatch(sendUserAction('checkPackage'));
    }

    return (
        <div className={"packages_list_item "}>
            <div className={"packages_list_image "}>
                <img src={item.image_small} alt={""}/>
            </div>
            <div className={"packages_list_package "}>{item.name}</div>
            <div className={"packages_list_name "}>{item.bike_name}</div>
            <div className={"packages_list_count "}>{item.count_days + " " + label('Days')}</div>
            <div className={"packages_list_price "}>{Math.round(+item.price*100)/100}€</div>
            <div className={"packages_list_check "}>
                <Checkbox
                    checked={checked}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleCheck}
                    size={undefined}
                />
            </div>
            
        </div>
    )
}