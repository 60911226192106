import React, {FC, Fragment} from "react";
import {PackageTable} from "../../../types/packages";

interface tableProps {
    table: PackageTable
}

export const PackageRulesTable: FC<tableProps> = ({table}) => {
    return (
        <div className={"packages_rules_item table_item"}>
            {table.name && <div className={"table_item_name"}>{table.name}</div>}
            <table className={"table_item_table"}>
                {table.head.length > 0 && <thead>
                <tr>
                    {table.head.map((i: string,j:number) => {
                        return <th key={j}>{i}</th>
                    })}
                </tr>
                </thead>}
                {table.rows.length > 0 && <tbody>
                    {table.rows.map((r: string[],j:number) => {
                        if (!r.length) {
                            return null;
                        }
                        return <tr  key={j}>
                            {r.map((s: string,k:number) => {
                                return <td key={k}>{s}</td>
                            })}
                        </tr>
                    })}
                </tbody>}
                {table.foot.length > 0 && <tfoot>
                <tr>
                    {table.foot.map((i: string,j:number) => {
                        return <th  key={j}>{i}</th>
                    })}
                </tr>
                </tfoot>}
            </table>
            {table.note.length > 0 &&  <Fragment>
                {table.note.map((n:string) => {
                      return <div className={"table_item_note"}>{'* '+n}</div>
                })}
            </Fragment>}
        </div>
    )
}