import React, {FC} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import "./ErrorPanel.scss";
import {IoClose} from "react-icons/io5";
import {close_error} from "../../features/error/ErrorSlice";

const   ErrorItem:FC<{message:string,index:number}>=({message,index})=>{
    const dispatch = useAppDispatch();
    const close=()=>{
        dispatch(close_error(index))
    }
    return (
        <div className="error-item alert alert-danger" >
            {message}
            <IoClose onClick={close}/>
        </div>
    )
}


function ErrorPanel() {

    const list = useAppSelector((state)=>{
        return state.error.errorList;
    })
    return (
        <div id="error_panel">
            {list.map((item:any,index:number)=>{
               return <ErrorItem key={index} message={item.message} index={index}/>
            })}

        </div>
    );
}

export  {ErrorPanel};