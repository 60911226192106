import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {api} from "../../service";
import {OrderPackage, PackagesState} from "../../types/packages";

export const fetchPackages = createAsyncThunk(
    'fetchPackages',
    async (thunkAPI)  => {
        return await api.getPackages() as PackagesState;
    }
)

export const fetchPackageOrder = createAsyncThunk(
    'fetchPackageOrder',
    async (order: OrderPackage, thunkAPI)  => {
        return await api.sendPackageOrder(order) as PackagesState;
    }
)


const initialState:PackagesState ={
    packages: [],
    payment_methods: [],
    payment_percents: [],
    values: {
        package_id: 0,
        percent: 0,
        method: ""
    },
    rules:{
      items: []
    },
    user: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        zipcode: '',
        city: '',
        password:'',
        note:'',
        birthdate:'',
        country:'',
        country_id:0,
        street:'',
        is_firm:0,
        firm_name:'',
        ICO:'',
        DIC:'',
        IC_DPH:'',
    },
    stage: 0,
    success:0,
    invoiceUrl: '',
    load: false,
    agreeTerms: false,
    checkEmail: false,
    checkEmailError: false,
};

export const packagesSlice = createSlice({
    name:"packages",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        checkPackage: (state, action: PayloadAction<{ package_id: number }>) => {
            let newValues = {...state.values};
            if(newValues.package_id === action.payload.package_id){
                newValues.package_id = 0;
            }else{
                newValues.package_id = action.payload.package_id;
            }
            return {...state, values: newValues};
        },
        checkMethod: (state, action: PayloadAction<{ method: string }>) => {
            let newValues = {...state.values};
            newValues.method = action.payload.method;
            return {...state, values: newValues};
        },
        checkPercent: (state, action: PayloadAction<{ percent: number }>) => {
            let newValues = {...state.values};
            newValues.percent = action.payload.percent;
            return {...state, values: newValues};
        },
        checkTerms: (state, action: PayloadAction<{ agreeTerms: boolean }>) => {
            return {...state, agreeTerms: action.payload.agreeTerms};
        },
        updateUser: (state, action: PayloadAction<{ name:string,value:any }>) => {
            let newUser = {...state.user};
            // @ts-ignore
            if(newUser[action.payload.name]!==undefined){
                // @ts-ignore
                newUser[action.payload.name] = action.payload.value;
            }
            return {...state, user: newUser};
        },
        processSuccess: (state) => {
            return {...state, success: 1};
        },
        resetSuccess: (state) => {
            return {...state, success: 0};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPackages.fulfilled,(state,action)=>{
            state.packages =  action.payload.packages;
            state.rules =  action.payload.rules;
            state.payment_methods =  action.payload.payment_methods;
            state.payment_percents =  action.payload.payment_percents;
            state.values =  action.payload.values;
            state.load =  true;
            // console.log('st',{...state.user});
            if(!state.user.email){
                state.user =  action.payload.user;
            }
        }).addCase(fetchPackageOrder.fulfilled, (state, action) => {
            // @ts-ignore
            state.success = action.payload.answer === 'success' ? 2 : -1;
            // @ts-ignore
            state.invoiceUrl = action.payload.invoiceUrl;
        }).addCase(fetchPackageOrder.rejected, (state, action) => {
            // @ts-ignore
            state.success = -1;
        })
    }
})

export const {
    updateUser,
    checkPackage,
    checkMethod,
    checkPercent,
    checkTerms,
    processSuccess,
    resetSuccess,
} = packagesSlice.actions;
export const packagesReducer = packagesSlice.reducer;