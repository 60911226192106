import React, {useEffect} from "react";
import ProfileSide from "../components/common/ProfileSide";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useScrollTop} from "../hooks";
import {useNavigate} from "react-router";
import {fetchProfileData} from "../features/profile/profileSlice";
import {fetchMyPackages} from "../features/myPackages/myPackagesSlice";
import {MyPackagesContent} from "../components/myPackages";

export const MyPackages = () => {
    const profile = useAppSelector(state=>state.login.profile);

    useScrollTop();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(fetchProfileData('packages'));
        dispatch(fetchMyPackages());
    },[]);

    useEffect(()=>{
        if(+profile.user_id === 0){
            navigate('/');
        }
    },[profile]);


    return (
        <main className={"page"}>
            <div className={"profile_content w-wrap"}>
                <ProfileSide />
                <div className={"profile_main"}>
                    <MyPackagesContent />
                </div>
            </div>
        </main>
    )
}