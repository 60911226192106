import React, {FC} from "react";
import {label} from "../../service";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

export const HomeBikeInfo:FC<{item:{model:string,price:number,id:number}}> = ({item}) => {
    // const navigate = useNavigate();
    // const handleBike = () => {
    //     navigate(`/p/${item.id}`);
    // }

    return (
        <div className="home_moto">
            <div className="home_moto_wrap w-wrap">
                <div className="home_moto_info">
                    <div className="home_moto_info_title">{label('Most Popular Motorcycle')}</div>
                    <div className="home_moto_info_model">{item.model}</div>
                </div>
                <div className="home_moto_price">
                    <div className="home_moto_price_amount">{`€${item.price} / ${label('Day')}`}</div>
                    <div className="home_moto_price_more">
                        <Link className="btn_more"
                              to={`/p/${item.id}`}
                              // onClick={handleBike}
                        >{label('Details')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )

}