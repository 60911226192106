import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/he';
import 'moment/locale/en-gb';
import 'moment/locale/cs';
import 'moment/locale/sk';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';

export const prepareDate = (start_ts: number, end_ts: number, language: string) => {
    const lang = getMomentOurLocale(language);

    const year1 = moment(start_ts * 1000).format('YYYY');
    const year2 = moment(end_ts * 1000).format('YYYY');
    const month1 = moment(start_ts * 1000).locale(lang).format('MMM');
    const month2 = moment(end_ts * 1000).locale(lang).format('MMM');
    const day1 = moment(start_ts * 1000).format('DD');
    const day2 = moment(end_ts * 1000).format('DD');
    let string = '';
    if (year1 === year2) {
        if (month1 === month2) {
            if (day1 === day2) {
                string = month1 + ' ' + day1 + ', ' + year1;
            } else {
                string = month1 + ' ' + day1 + ' - ' + ' ' + day2 + ', ' + year1;
            }
        } else {
            string = month1 + ' ' + day1 + ' - ' + month2 + ' ' + day2 + ', ' + year1;
        }
    } else {
        string = month1 + ' ' + day1 + ', ' + year1 + ' - ' + month2 + ' ' + day2 + ', ' + year2;
    }
    return string;
}

export const getMomentOurLocale = (language:string) => {
    switch (language) {
        case 'en':
            return 'en-gb';
        case 'cz':
            return 'cs';
        default:
            return language;
    }
}