import React, {useEffect, useState} from "react";
import {label} from "../../service";
import "./edit.scss";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {UserProfile, UserProfileErrors} from "../../types/login";
import {MainInput} from "../tools/MainInput";
import {validateEmail, validateName, validatePhone} from "../../utils";
import {clearStatus, fetchEditProfile} from "../../features/login/loginSlice";
import {CountryItem} from "../../types/common";
import {clsx} from "clsx";

const EditProfileContent = () => {
    const profile = useAppSelector(state => state.login.profile);
    const status = useAppSelector(state => state.login.status);
    const states = useAppSelector(state => state.common.states);

    const [edit, setEdit] = useState({
        user_id: 0,
        name: "",
        surname: "",
        phone: "",
        mail: "",
        pass: "",
        birthdate: "",
        zipcode: "",
        country_id: 0,
        city: "",
        street: "",
    } as UserProfile);

    const [errors, setError] = useState({
        name: false,
        surname: false,
        phone: false,
        mail: false,
        birthdate: false,
        zipcode: false,
        country_id: false,
        city: false,
        street: false
    } as UserProfileErrors);

    const [enabled, setEnabled] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setEdit({...profile});
    }, [profile]);

    useEffect(() => {
        checkEnabled();
    }, [edit]);

    useEffect(() => {
        return () => {   // @ts-ignore
            dispatch(clearStatus());
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let new_edit = {...edit};
        let new_error = {...errors};
        // @ts-ignore
        new_edit[e.target.name] = e.target.value;
        // @ts-ignore
        if (new_error[e.target.name] !== undefined) {
            // @ts-ignore
            new_error[e.target.name] = false;
            setError(new_error);
        }
        setEdit(new_edit);
        // @ts-ignore
        dispatch(clearStatus());
    }

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let new_edit = {...edit};
        let new_error = {...errors};
        // @ts-ignore
        new_edit[e.target.name] = e.target.value;
        // @ts-ignore
        if (new_error[e.target.name] !== undefined) {
            // @ts-ignore
            new_error[e.target.name] = false;
            setError(new_error);
        }
        setEdit(new_edit);
        // @ts-ignore
        dispatch(clearStatus());
    }

    const cancelEdit = () => {
        setEdit({...profile});
    }

    const saveEdit = () => {
        if (!enabled) {
            return;
        }
        if (validateForm()) {
            dispatch(fetchEditProfile(edit));
        }
    }

    const validateForm = () => {
        let new_error = {...errors};
        let result = true;
        if (!validateEmail(edit.mail)) {
            new_error.mail = true;
            result = false;
        }
        if (!validatePhone(edit.phone)) {
            new_error.phone = true;
            result = false;
        }
        if (!validateName(edit.name)) {
            new_error.name = true;
            result = false;
        }
        if (!validateName(edit.surname)) {
            new_error.name = true;
            result = false;
        }
        if (edit.birthdate === '') {
            new_error.birthdate = true;
            result = false;
        }
        if (+edit.country_id === 0) {
            new_error.country_id = true;
            result = false;
        }
        if (edit.city === '') {
            new_error.city = true;
            result = false;
        }
        if (edit.street === '') {
            new_error.street = true;
            result = false;
        }
        if (edit.zipcode === '') {
            new_error.zipcode = true;
            result = false;
        }
        setError(new_error);
        return result;
    }

    const checkEnabled = () => {
        let new_enabled = false;
        for (const i in edit) {
            // @ts-ignore
            if (profile.hasOwnProperty(i) && edit[i] !== profile[i]) {
                new_enabled = true;
            }
        }
        if (new_enabled !== enabled) {
            setEnabled(new_enabled);
        }
    }

    // @ts-ignore
    return (
        <div className={"edit_profile"}>
            <h1 className="profile_heading">{label('Edit Profile')}</h1>
            {status === 'ok' &&
                <div className={"edit_profile_message good_message"}>{label('Personal info edited')}</div>}
            {status === 'fail' && <div className={"edit_profile_message"}>{label('Error')}</div>}
            <details className={"edit_profile_section"} open>
                <summary className={"edit_profile_header"}>{label('Personal Info')}</summary>
                <div className={"edit_profile_body"}>
                    <ul className={"edit_profile_list"}>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Email')}*</label>
                            <MainInput error={errors.mail} value={edit.mail} onChange={handleChange} name={'mail'}
                                       placeHolder={label('Email')} className={"edit_profile_value"}/>
                        </li>
                        <li className={"edit_profile_list_item input-phone"}>
                            <label className={"edit_profile_label"}>{label('Phone')}*</label>
                            <MainInput error={errors.phone} value={edit.phone} onChange={handleChange} name={'phone'}
                                       placeHolder={label('Phone')} className={"edit_profile_value"}/>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Your Name')}*</label>
                            <MainInput error={errors.name} value={edit.name} onChange={handleChange} name={'name'}
                                       placeHolder={label('Name')} className={"edit_profile_value"}/>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Your Surname')}*</label>
                            <MainInput error={errors.surname} value={edit.surname} onChange={handleChange} name={'surname'}
                                       placeHolder={label('Surname')} className={"edit_profile_value"}/>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Birthday')}*</label>
                            <div className={clsx(
                                "input-item",
                                errors.birthdate && "error",
                                "edit_profile_value"
                            )}>
                                <input
                                    type={'date'}
                                    value={edit.birthdate}
                                    name={'birthdate'}
                                    onChange={handleChange}
                                    placeholder={""}
                                    min={"1900-01-01"}
                                    max={"2050-01-01"}
                                />
                            </div>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Country')}*</label>
                            <div className={"edit_profile_value "+(errors.country_id ? " error " : "")}>
                                <select
                                    value={edit.country_id}
                                    onChange={(e) => {
                                        handleSelect(e)
                                    }}
                                    name={'country_id'}
                                >
                                    {states.map((country: CountryItem) => {
                                        return <option
                                            key={country.id}
                                            value={country.id}
                                        >{country.value}
                                        </option>
                                    })}
                                </select>
                            </div>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('City')}*</label>
                            <MainInput error={errors.city} value={edit.city} onChange={handleChange} name={'city'}
                                       placeHolder={label('City')} className={"edit_profile_value"}/>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Zip Code')}*</label>
                            <MainInput error={errors.zipcode} value={edit.zipcode} onChange={handleChange}
                                       name={'zipcode'}
                                       placeHolder={label('Zip Code')} className={"edit_profile_value"}/>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Address')}*</label>
                            <MainInput error={errors.street} value={edit.street} onChange={handleChange} name={'street'}
                                       placeHolder={label('Address')} className={"edit_profile_value"}/>
                        </li>
                        <li className={"edit_profile_list_item"}>
                            <label className={"edit_profile_label"}>{label('Password')}</label>
                            <MainInput error={false} value={edit.pass} onChange={handleChange} name={'pass'}
                                       placeHolder={label('Password')} className={"edit_profile_value"}/>
                        </li>
                    </ul>
                    <div className={"edit_profile_footer"}>
                        <div className={"edit_profile_button btn btn-success " + (!enabled ? " disabled " : "")}
                             onClick={saveEdit}
                        >{label('Save')}</div>
                        <div className={"edit_profile_button btn btn-link"}
                             onClick={cancelEdit}
                        >{label('Cancel')}</div>
                    </div>
                </div>
            </details>
        </div>
    )
}

export default EditProfileContent;