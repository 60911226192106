import React from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {ImLocation, ImPhone} from "react-icons/im";
import {MdAlternateEmail} from "react-icons/md";

export const ContactsData = () => {
    const contacts = useAppSelector(state => state.common.contacts);

    return (
        <div className={"contacts_data"}>
            <div className={"contacts_data_items"}>
                <div className={"contacts_data_items_row"}>
                    <div className={"contacts_data_items_row_name"}>{label('Address')}</div>
                    <div className={"contacts_data_items_row_value"}>
                        <span>{contacts.address}</span>&nbsp;,&nbsp;
                        <span>{contacts.city}</span>
                    </div>
                </div>
                <div className={"contacts_data_items_row"}>
                    <div className={"contacts_data_items_row_name"}>{label('Email')}</div>
                    <div className={"contacts_data_items_row_value"}><span>{contacts.email}</span></div>
                </div>
                <div className={"contacts_data_items_row"}>
                    <div className={"contacts_data_items_row_name"}>{label('Phone')}</div>
                    <div className={"contacts_data_items_row_value"}><span>{contacts.phone}</span></div>
                </div>
            </div>
        </div>
    )
}