import React, {FC} from "react";

interface overviewItemProps{
    name:string
    image:string
    info:string
}

export const TooltipOverviewItem:FC<overviewItemProps> = ({name,image,info}) => {
    return (
        <div className={"tour_info_overview_column_data_item"}>
            <div className={"tour_info_overview_column_data_value "}>{name}</div>
        </div>
    )
}