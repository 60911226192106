import React from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {BikeListItem} from "../../types/packageBikeDetails";
import {fetchPackageBikeDetails} from "../../features/packageBikeDetails/packageBikeDetailsSlice";

export const PackageOrderCheckoutBike = () => {
    const bike_list = useAppSelector(state=>state.packageBikeDetails.bike_list);
    const values = useAppSelector(state=>state.packageBikeDetails.values);

    const dispatch = useAppDispatch();

    const handleBikeChange = (bike_id:number) => {
        if(+values.bike_id !== bike_id){
            dispatch(fetchPackageBikeDetails({order_id:values.parent_id,bike_id:bike_id}));
        }
    }

    const getDiffPrice = (diff: number) => {
        return `+ €${diff} / ${label('Day')}`;
    }

    return (
        <div className={"checkout_bikes"}>
            <div className="heading">
                {label('Motorcycle')}
            </div>
            <div className="checkout_bikes_rows">
                {bike_list.map((bike: BikeListItem) => {
                    const checked = +values.bike_id === +bike.id;
                    const handleBike = () => {
                        handleBikeChange(+bike.id);
                    }
                    let diff_price = bike.diff_price;

                    return (
                        <div className={"checkout_bikes_row " + (checked ? "checked_bike" : "")}
                             key={bike.id}
                             onClick={handleBike}
                        >
                            <div className="checkout_bikes_row_name">{bike.alias}</div>
                            <div className="checkout_bikes_row_image ">
                                <img src={bike.img_small} alt={bike.alias}/>
                            </div>
                            {+diff_price !== 0 &&
                                <div className="checkout_bikes_row_count">{getDiffPrice(diff_price)}</div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}