import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useScrollTop} from "../hooks";
import {label} from "../service";
import "./privacy.scss";
import {fetchTerms} from "../features/terms/termsSlice";

export const Terms = () => {

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTerms());
    }, []);

    const terms = useAppSelector(state=>state.terms);

    // console.log('f',focus);
    return (
        <main className={"privacy page w-full"}>
            <div className={"privacy_wrap ql-editor w-wrap"}>
                <h1 className="title">{label('Terms and Conditions')}</h1>
                <div className={"privacy_text"} dangerouslySetInnerHTML={{__html:terms.info}}/>
            </div>
        </main>
    )
}