import React from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";

export const PackageOrderCheckoutInfo = () => {
    const count_days = useAppSelector(state => state.packageBikeDetails.count_days);
    const count_left = useAppSelector(state => state.packageBikeDetails.count_left);
    const min_days = useAppSelector(state => state.packageBikeDetails.min_days);
    const max_days = useAppSelector(state => state.packageBikeDetails.max_days);

    return (
        <div className={"package_checkout_info"}>
            <div className={"package_checkout_info_line"}>
                <div className={"package_checkout_info_item"}>
                    <div className={"package_checkout_info_item_name"}>{label('Count Days')}&nbsp;:&nbsp;</div>
                    <div className={"package_checkout_info_item_value"}>{count_days}</div>
                </div>
                <div className={"package_checkout_info_item"}>
                    <div className={"package_checkout_info_item_name"}>{label('Days Left')}&nbsp;:&nbsp;</div>
                    <div className={"package_checkout_info_item_value"}>{count_left}</div>
                </div>
            </div>
            <div className={"package_checkout_info_line second_line"}>
                <div className={"package_checkout_info_item"}>
                    <div className={"package_checkout_info_item_name"}>{label('Min Days')}&nbsp;:&nbsp;</div>
                    <div className={"package_checkout_info_item_value"}>{min_days}</div>
                </div>
                <div className={"package_checkout_info_item"}>
                    <div className={"package_checkout_info_item_name"}>{label('Max Days')}&nbsp;:&nbsp;</div>
                    <div className={"package_checkout_info_item_value"}>{max_days}</div>
                </div>
            </div>
        </div>
    )
}