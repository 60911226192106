import React, {FC} from "react";
import {GalleryAlbum} from "../../types/gallery";
import {Link} from "react-router-dom";
import {label} from "../../service";

interface albumProps {
    album: GalleryAlbum
}

const GalleryAlbumCard: FC<albumProps> = ({album}) => {
    if (+album.photo.id === 0) {
        return null;
    }
    const album_link = '/gallery/album/' + album.album_id;
    return (
        <div className="gallery_albums_item">
            <div className="gallery_albums_item_picture">
                <Link className={"gallery_albums-pict " + (!album.photo.image ? " empty-pict " : "")} style={{
                    backgroundImage: `url("${album.photo.image}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}
                      to={album_link}
                    //  onClick={handleMore}
                >
                    <div className={"gallery_albums_item_picture_gradient"}>
                        <div className="gallery_albums_item_title">
                            <h2>{album.name}</h2>
                        </div>
                        <div className={"gallery_albums_item_body"}>
                            <div className={"gallery_albums_item_button"}>
                                <Link className={"btn_more"}
                                      to={album_link}
                                    // onClick={handleMore}
                                >{label('More')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default GalleryAlbumCard;