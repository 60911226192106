import React, {FC} from "react";

interface ruleProps {
    text: string
}

export const PackageRulesTextMain: FC<ruleProps> = ({text}) => {
    if (!text) {
        return null;
    }
    return (
        <div className={"packages_rules_item text_main"}>{text}</div>
    )
}