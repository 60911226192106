import React, {FC} from 'react';
import {label} from "../../service";
import {Equipments, Extras, OvertimeT} from "../../types";
import {MotorcycleRent} from "./MotorcycleRent";
import {SelectedBook} from "./Checkout";
import Promo from "../tours/Promo";
import {TourBookPromo} from "../../types/tourBook";
import {useAppDispatch} from "../../app/hooks";
import {
    fetchCheckBikePromo,
    fetchResetBikePromo,
    processCheckBikePromo,
    resetCheckPromoBikeError
} from "../../features/bike/bikeSlice";
import {SetT} from "../../hooks/use-map";
import {CalType} from "../../page/Bike";

interface OrderCalcProps {
    equip:Equipments[],
    extra:Extras[] ,
    insurances:any[],
    overtime:OvertimeT,
    overtime_value:SetT<string>,
    total: number,
    price: {
        total:number,
        discount:number,
        real:number
    }
    accessor:{}
    extras:SetT<number>
    insurances_values:SetT<number>
    date:SelectedBook
    promo:TourBookPromo
    promoDiscount:number
    checkPromoError:boolean
    checkPromo:boolean,
    mileage: number
    calType: CalType
}

export const OrderCalc: FC<OrderCalcProps> = (
    {
        total,
        price,
        accessor,
        extras,
        date,
        equip,
        extra,
        insurances,
        insurances_values,
        promo,
        promoDiscount,
        checkPromoError,
        checkPromo,
        overtime,
        overtime_value,
        mileage,
        calType
    }) => {
    const duration = Math.max(1, date.duration);

    const dispatch = useAppDispatch();

    const handleCheckPromo = (code: string) => {
        if(code){
            dispatch(processCheckBikePromo());
            dispatch(fetchCheckBikePromo(code));
        }
    }

    const handleRemovePromo = (promo_id: number) => {
        dispatch(fetchResetBikePromo(promo_id));
    }

    const resetError = () => {
        dispatch(resetCheckPromoBikeError());
    }

    const getMileage = () => {
        if (calType=='half') {
            return 150;
        } else {
            return date.duration * mileage;
        }
    }

    return (
        <div className="order-calc">
            <div className={"heading"}>{label('Total')}</div>
            <div className="total-checked">
                {(date.start || date.end) && <div className="total-checked-date">
                    {date.start && <div className="total-checked-date_item">
                        <div className="total-checked-date_item_name">{label('Pick-up')}</div>
                        <div className="total-checked-date_item_date">{date.start} {date.optionStart}</div>
                    </div>}
                    {date.end && <div className="total-checked-date_item">
                        <div className="total-checked-date_item_name">{label('Drop-off')}</div>
                        <div className="total-checked-date_item_date">{date.end} {date.optionEnd}</div>
                    </div>}
                    <div className="total-checked-date_item">
                        <div className="total-checked-date_item_name">{label('Include')}</div>
                        <div className="total-checked-date_item_date">{getMileage()+' '+label('km')}</div>
                    </div>
                </div>}
                <MotorcycleRent duration={date.duration} price= {price} />
                {Object.keys(accessor).length > 0 && <div className="total-checked-accessories">
                    {equip.map(item=>{
                        // @ts-ignore
                        if(accessor[item.id]!==undefined){
                            // @ts-ignore
                            let count = accessor[item.id].count || 1;
                            return   <div className="total-checked-accessories_item"
                                          key={item.id}
                            >
                                <div className="total-checked-accessories_item_name">
                                    <div className="total-checked-accessories_item_name_value">{item.name}</div>
                                    {count >1 && <div className="total-checked-accessories_item_name_count">{'x '+count}&nbsp;
                                    </div>}
                                    {!(item.count>0) && <span className={"for-request"}>{label('For request')}</span>}
                                </div>
                                <div className="total-checked-accessories_item_price">
                                    {  (item.price > 0 ? (+item.period === 1 ? +item.price * count * duration : +item.price * count) : label('Free')) }

                                </div>
                            </div>
                        }else{
                            return null;
                        }
                    })}
                </div>}
                {extras.size > 0 && <div className="total-checked-extras">
                    {extra.map(item=>{
                        // @ts-ignore
                        if(extras.has(item.id)){
                            // @ts-ignore
                            return   <div className="total-checked-extras_item"
                                          key={item.id}
                            >
                                <div className="total-checked-extras_item_name">
                                    <div className="total-checked-extras_item_name_value">{item.name}</div>
                                </div>
                                <div className="total-checked-extras_item_price">
                                    {item.price > 0 ? 1*item.price : label('Free')}
                                </div>
                            </div>
                        }else{
                            return null;
                        }
                    })}
                </div>}
                {insurances && insurances.length > 0 && insurances_values && <div className={"total-checked-extras"}>
                    {insurances.map(item=>{
                        // @ts-ignore
                        if (insurances_values.has(item.id)) {
                            return   <div className="total-checked-extras_item"
                                          key={item.id}
                            >
                                <div className="total-checked-extras_item_name">
                                    <div className="total-checked-extras_item_name_value">{item.name}</div>
                                </div>
                                <div className="total-checked-extras_item_price">
                                    {item.price > 0 ? item.one_time?1*item.price:item.price*duration : label('Free')}
                                </div>
                            </div>
                        }
                        return null;
                    })}
                </div>}
                { overtime &&  <div className={"total-checked-extras"}>
                    {Object.entries(overtime).map(([key,item]:any)=> {
                        if (overtime_value.has(key)) {
                            return (<div className="total-checked-extras_item"
                                         key={item.id}
                            >
                                <div className="total-checked-extras_item_name">
                                    <div className="total-checked-extras_item_name_value">{item.name}</div>
                                </div>
                                <div className="total-checked-extras_item_price">
                                    {item.price > 0 ?  item.price : label('Free')}
                                </div>
                            </div>)
                        } else {
                            return null
                        }



                })
                    }
                </div>
                }
                {+promoDiscount !== 0 && <div className="total-checked-rent">
                    <div className="total-checked-rent_item">
                        <div className="total-checked-rent_item_name">{promo.mode === 'voucher' ? label('Gift Voucher') : label('Promo Discount')}</div>
                        <div className="total-checked-rent_item_date">{promoDiscount}</div>
                    </div>
                </div>}
            </div>
            <div className="total-amount">
                <div className="total-amount_name">{label('Total price')}</div>
                <div className="total-amount_value">{total}€</div>
            </div>
            <Promo
                type={promo.type || ""}
                number={promo.number || ""}
                code={promo.code || ""}
                mode={promo.mode || "promo"}
                mail={promo.mail || ""}
                text={promo.text || ""}
                promo_id={promo.promo_id}
                start_rental={promo.start_rental}
                end_rental={promo.end_rental}
                checkPromo={handleCheckPromo}
                removePromo={handleRemovePromo}
                checkError = {checkPromoError}
                resetError = {resetError}
                checkingProcess = {checkPromo}
                promoDiscount = {+promoDiscount}
            />
        </div>
    );
};

