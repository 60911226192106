import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useScrollTop} from "../hooks";
import {label} from "../service";
import "./privacy.scss";
import {fetchVacancy} from "../features/vacancy/vacancySlice";

export const Vacancy = () => {

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchVacancy());
    }, []);

    const vacancy = useAppSelector(state=>state.vacancy);

    // console.log('f',focus);
    return (
        <main className={"privacy page w-full"}>
            <div className={"privacy_wrap ql-editor w-wrap"}>
                <h1 className={"title"}>{label('Vacancies')}</h1>
                <div className={"privacy_text "} dangerouslySetInnerHTML={{__html:vacancy.info}}/>
            </div>
        </main>
    )
}