import React from "react";
import {PackageOrderCheckoutBike} from "./PackageOrderCheckoutBike";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {PackageOrderCheckoutDate} from "./PackageOrderCheckoutDate";
import {PackageOrderCheckoutEquip} from "./PackageOrderCheckoutEquip";
import {PackageOrderCheckoutEquipSize} from "./PackageOrderCheckoutEquipSize";
import {PackageOrderCheckoutFinal} from "./PackageOrderCheckoutFinal";
import {PackageOrderCheckoutCalc} from "./PackageOrderCheckoutCalc";
import {getPackageOrderTotal} from "../../utils/packageOrder";
import {
    processStatus, resetState,
    resetStatus,
    sendPackagePickupOrder
} from "../../features/packageBikeDetails/packageBikeDetailsSlice";
import {label} from "../../service";
import {PackageOrderCheckoutInfo} from "./PackageOrderCheckoutInfo";

export const PackageOrderCheckout = () => {
    const stage = useAppSelector(state=>state.packageBikeDetails.stage);
    const values = useAppSelector(state=>state.packageBikeDetails.values);
    const equip = useAppSelector(state=>state.packageBikeDetails.equip);
    const extra = useAppSelector(state=>state.packageBikeDetails.extra);
    const bike_list = useAppSelector(state=>state.packageBikeDetails.bike_list);
    const overtime = useAppSelector(state=>state.packageBikeDetails.overtime);
    const status = useAppSelector(state => state.packageBikeDetails.status);
    const info = useAppSelector(state => state.packageBikeDetails.info);
    const {date} = values;

    const dispatch = useAppDispatch();

    const getCheckoutPage = () => {
        switch (stage) {
            case 0:
                return <PackageOrderCheckoutDate/>;
            case 1:
                return <PackageOrderCheckoutEquip/>;
            case 2:
                return <PackageOrderCheckoutEquipSize/>;
            case 3:
                return <PackageOrderCheckoutFinal handleBook={handleBook}/>;
        }
        return null;
    }

    const handleBook = () => {
        const total = getPackageOrderTotal(values,equip,extra,bike_list,overtime,date.duration);
        let orderData = {...values};
        orderData.amount = total;
        dispatch(processStatus());
        dispatch(sendPackagePickupOrder(orderData));
    }

    const getSuccess = () => {
        switch(+status){
            case -1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Something wrong.')}</h1>
                        <span>{label('Please try again later.')}</span>
                        {info.map((i:any)=>{
                            return (
                                <span style={{marginTop:"5px",marginBottom:"5px",color:'red'}}>{i.message}</span>
                            )
                        })}
                        <div className={"btn btn-secondary"} onClick={resetErrorLayer}>OK</div>
                    </div>
                )
            case 1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order in progress.')}</h1>
                        <span className="loader"/>
                    </div>
                )
            case 2:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order successfully accepted.')}</h1>
                        <span>{label('We will contact you shortly..')}</span>
                        <div className={"btn btn-secondary"} onClick={handleClose}>OK</div>
                    </div>
                )
        }
    }

    const resetErrorLayer = () => {
        dispatch(resetStatus());
    }

    const handleClose = () => {
        // @ts-ignore
        dispatch(resetState());
    }

    return (
        <div className={"package_checkout"}>
            <div className={"package_checkout_layer " + (!status ? "disable_layer" : "")}>
                {getSuccess()}
            </div>
            <PackageOrderCheckoutInfo />
            <PackageOrderCheckoutBike />
            <div className={"checkout_page"}>
                {getCheckoutPage()}
                {date.start && date.end && date.optionStart && date.optionEnd && <div className={"date-select"}>
                    <div className="date-select-item">
                        <PackageOrderCheckoutCalc />
                    </div>
                </div>}
            </div>
        </div>
    )
}