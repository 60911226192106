import React, {useEffect} from "react";
import {useScrollTop} from "../hooks";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {label} from "../service";
import {fetchCertificate, resetSuccess} from "../features/certificate/certificateSlice";
import {CertificateCreate} from "../components/certificate";
import "./certificate.scss";

export const Certificate = () => {
    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchCertificate());
        return () => {
            dispatch(resetSuccess());
        }
    }, []);

    // console.log('f',focus);
    return (
        <main className={"certificate page w-full"}>
            <div className={"certificate_wrap w-wrap"}>
                <div className={"page-header"}>
                    <h1 className={"title"}>{label('Gift Vouchers')}</h1>
                </div>
                <div className={"certificate_area "}>
                    {false && <div className={"certificate_area_header "}>{label('Create Gift Voucher')}</div>}
                    <CertificateCreate/>
                </div>
            </div>
        </main>
    )
}