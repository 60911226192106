import React from "react";
import {ModalPortal} from "./ModalPortel";
import {ModalWrap} from "./ModalWrap";
// @ts-ignore
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {hideGallery} from "../../features/galleryViewer/galleryViewerSlice";
import {GalleryViewerImage} from "../../types/galleryViewer";
import {useScrollTop} from "../../hooks";

export const GalleryViewer = () => {
    const galleryViewer = useAppSelector(state=>state.galleryViewer);

    const dispatch = useAppDispatch();
    useScrollTop();

    const onClose = () => {
        dispatch(hideGallery());
    }

    if(!galleryViewer.show || !galleryViewer.images.length){
        return null;
    }

    const show_images = [] as GalleryViewerImage[];
    galleryViewer.images.forEach(image=>{
        show_images.push({
            original: image.image,
            thumbnail: image.image_small,
            originalTitle : image.title || '',
            thumbnailTitle : image.title || '',
            thumbnailLabel : image.title || '',
            description : image.desc || '',
            loading : "lazy"
        });
    })


    return (
        <ModalPortal addClass={"gallery-viewer-modal"}>
            <ModalWrap closeModal={onClose}>
                <ImageGallery
                    items={show_images}
                    startIndex = {galleryViewer.current}
                    thumbnailPosition = {'left'}
                />
            </ModalWrap>
        </ModalPortal>
    )
}