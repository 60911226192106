import React from "react";
import {useAppSelector} from "../../app/hooks";
import {TourCardItem} from "./TourCardItem";
import {TourItem} from "../../types/tour";

export const ToursCards = () => {
    const cards = useAppSelector(state => {
        return state.tours.cards
    })
    const dayFilter = useAppSelector(state => state.tours.dayFilter);
    let show_cards = cards;
    if(+dayFilter > 0){
        show_cards = cards.filter((card:TourItem)=>{return +card.days_number === +dayFilter})
    }

    return (
        <section className="tours_items">
            {
                show_cards.map((card, index) => {
                    return <TourCardItem key={index} card={card}/>
                })
            }
        </section>
    )
}