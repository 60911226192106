import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {api} from "../../service";
import {TourBookData, TourBookState} from "../../types/tourBook";
import {fetchCheckUserEmail} from "../bike/bikeSlice";

export const fetchTourBook = createAsyncThunk(
    'fetchTourBook',
    async (formData: { id: number, search: any }) => {
        return await api.getTourBook(formData.id, formData.search) as any;

    }
)

export const fetchTourOrder = createAsyncThunk(
    'fetchTourOrder',
    async (order: TourBookData, thunkAPI) => {
        return await api.sendTourOrder(order);
    }
)

export const fetchCheckPromo = createAsyncThunk(
    'fetchCheckPromo',
    async (promo_code: string, thunkAPI) => {
        return await api.checkPromoCode(promo_code);
    }
)

export const fetchResetPromo = createAsyncThunk(
    'fetchResetPromo',
    async (promo_id: number, thunkAPI) => {
        return await api.resetPromoCode(promo_id);
    }
)

export const fetchCheckTourUserEmail = createAsyncThunk(
    'fetchCheckTourUserEmail',
    async (mail: string, thunkAPI) => {
        return await api.checkUserTourEmail(mail);
    }
)

const initialState: TourBookState = {
    image: "",
    info: {
        id: 0,
        name: '',
        days_number: 0,
        bike_days_number: 0
    },
    dates: [],
    bikes: {},
    rules: [],
    hotels: [],
    insurances: [],
    extra: [],
    equip: [],
    values: {
        tour_id: 0,
        bike_id: 0,
        promo_id: 0,
        accessories: {},
        extras: [],
        rules: [],
        hotels: [],
        insurances: [],
        percent: 0,
        method: "",
        passenger: 0
    },
    user: {
        name: '',
        surname: '',
        email: '',
        password: '',
        phone: '',
        note: '',
        birthdate: "",
        zipcode: "",
        country: "",
        country_id: 0,
        city: "",
        street: "",
        is_firm: 0,
        firm_name:"",
        ICO:"",
        DIC:"",
        IC_DPH:"",
    },
    stage: 0,
    success: 0,
    checkPromo: false,
    checkPromoError: false,
    checkEmail: false,
    checkEmailError: false,
    invoiceUrl: '',
    payment_methods: [],
    payment_percents: [],
    promo: {
        type: '',
        number: '',
        code: '',
        target: '',
        mail: '',
        mode: '',
        target_id: 0,
        promo_id: 0,
        start_rental: 0,
        end_rental: 0,
        text: '',
    }
};

export const toursBookSlice = createSlice({
    name: "toursBook",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetTourBook: (state) => {
            return {
                ...state,
                image: "",
                info: {
                    id: 0,
                    name: '',
                    days_number: 0,
                    bike_days_number: 0
                },
                dates: [],
                bikes: {},
                rules: [],
                hotels: [],
                extra: [],
                equip: [],
                values: {
                    tour_id: 0,
                    bike_id: 0,
                    promo_id: 0,
                    accessories: {},
                    extras: [],
                    rules: [],
                    hotels: [],
                    insurances: [],
                    percent: 0,
                    method: "",
                    passenger: 0
                },
                user: {
                    name: '',
                    surname: '',
                    email: '',
                    password: '',
                    phone: '',
                    note: '',
                    birthdate: "",
                    zipcode: "",
                    country: "",
                    country_id: 0,
                    city: "",
                    street: "",
                    is_firm: 0,
                    firm_name:"",
                    ICO:"",
                    DIC:"",
                    IC_DPH:"",
                },
                stage: 0,
                success: 0,
                checkPromo: false,
                checkPromoError: false,
                checkEmail: false,
                checkEmailError: false,
                invoiceUrl: '',
                payment_methods: [],
                payment_percents: [],
                promo: {
                    type: '',
                    number: '',
                    code: '',
                    target: '',
                    mode: '',
                    mail: '',
                    target_id: 0,
                    promo_id: 0,
                    start_rental: 0,
                    end_rental: 0,
                    text: '',
                }
            }
        },
        updateBookTour: (state, action: PayloadAction<{ tour_id: number }>) => {
            const values = {...state.values, tour_id: action.payload.tour_id};
            return {...state, values};
        },
        updateBookBike: (state, action: PayloadAction<{ bike_id: number }>) => {
            const values = {...state.values, bike_id: action.payload.bike_id};
            return {...state, values};
        },
        updateStage: (state, action: PayloadAction<{ stage: number }>) => {
            return {...state, stage: action.payload.stage};
        },
        updateExtra: (state, action: PayloadAction<{ id: number }>) => {
            let extras = [];
            if (!state.values.extras.includes(action.payload.id)) {
                extras.push(action.payload.id)
            }
            const values = {...state.values, extras};
            return {...state, values};
        },
        updateAccessories: (state, action: PayloadAction<{ id: number, count: number }>) => {
            let accessories = Object.assign({}, state.values.accessories);
            if (accessories[action.payload.id] !== undefined) {
                delete (accessories[action.payload.id]);
            } else {
                accessories[action.payload.id] = {
                    count: 1,
                    sizes: {
                        1: !!action.payload.count ? 'XL' : '-1'
                    }
                };
            }
            const values = {...state.values, accessories};
            return {...state, values};
        },
        updateAccessoriesCount: (state, action: PayloadAction<{ id: number, count: number }>) => {
            if (state.values.accessories[action.payload.id] !== undefined) {
                const digit = {...state.values.accessories[action.payload.id]};
                digit.count = action.payload.count;
                digit.sizes = {};
                for (let i = 0; i < action.payload.count; i++) {
                    digit.sizes[i + 1] = 'XL';
                }

                const accessories = {...state.values.accessories, [action.payload.id]: digit};
                return {...state, values: {...state.values, accessories}};
            }
            return state
        },
        updateAccessoriesSize: (state, action: PayloadAction<{ id: number, number: number, size: string }>) => {
            if (state.values.accessories[action.payload.id] !== undefined && state.values.accessories[action.payload.id].sizes[action.payload.number] !== undefined) {
                const digit = {...state.values.accessories[action.payload.id]};
                const sizes = {...digit.sizes};
                sizes[action.payload.number] = action.payload.size;
                digit.sizes = sizes;
                const accessories = {...state.values.accessories, [action.payload.id]: digit};
                return {...state, values: {...state.values, accessories}};
            }
            return state
        },
        updateUser: (state, action: PayloadAction<{ value: string, name: string }>) => {
            let user = Object.assign({}, state.user);
            // @ts-ignore
            if (user[action.payload.name] !== undefined) {
                // @ts-ignore
                user[action.payload.name] = action.payload.value;
            }
            return {...state, user};
        },
        updatePercent: (state, action: PayloadAction<{ percent: number }>) => {
            const values = {...state.values, percent: action.payload.percent};
            return {...state, values};
        },
        updateMethod: (state, action: PayloadAction<{ method: string }>) => {
            const values = {...state.values, method: action.payload.method};
            return {...state, values};
        },
        updatePassenger: (state, action: PayloadAction<{ passenger: number }>) => {
            const values = {...state.values, passenger: action.payload.passenger, hotels: []};
            console.log('updatePassenger', values);
            return {...state, values};
        },
        processSuccess: (state) => {
            return {...state, success: 1};
        },
        resetSuccess: (state) => {
            return {...state, success: 0};
        },
        processCheckPromo: (state) => {
            return {...state, checkPromo: true};
        },
        resetCheckPromoError: (state) => {
            return {...state, checkPromoError: false};
        },
        updateHotel: (state, action: PayloadAction<{ id: number }>) => {
            let hotels = [];
            let isHotel = false;
            for (const i in state.values.hotels) {
                const rule = state.values.hotels[i];
                if (+rule.id === action.payload.id) {
                    isHotel = true;
                }
            }
            if (!isHotel) {
                hotels.push({id: action.payload.id, status: 2})
            }
            const values = {...state.values, hotels};
            return {...state, values};
        },
        updateInsurance: (state, action: PayloadAction<{ id: number }>) => {
            let insurances = [];
            let isInsurance = false;
            for (const i in state.values.insurances) {
                const rule = state.values.insurances[i];
                if (+rule.id === action.payload.id) {
                    isInsurance = true;
                }
            }
            if (!isInsurance) {
                insurances.push({id: action.payload.id, status: 2});
            }
            const values = {...state.values, insurances};
            return {...state, values};
        },
        resetCheckTourEmail: (state) => {
            return {...state, checkEmail: false, checkEmailError: false};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTourBook.fulfilled, (state, action) => {
            state.image = action.payload.image;
            state.info = action.payload.info;
            state.dates = action.payload.dates;
            state.bikes = action.payload.bikes;
            state.rules = action.payload.rules;
            state.hotels = action.payload.hotels;
            state.insurances = action.payload.insurances;
            state.extra = action.payload.extra;
            state.equip = action.payload.equip;
            state.values = action.payload.values;
            state.payment_methods = action.payload.payment_methods;
            state.payment_percents = action.payload.payment_percents;
            state.promo = action.payload.promo;
        }).addCase(fetchTourOrder.fulfilled, (state, action) => {
            // @ts-ignore
            state.success = action.payload.answer === 'success' ? 2 : -1;
            // @ts-ignore
            state.invoiceUrl = action.payload.invoiceUrl;
        }).addCase(fetchTourOrder.rejected, (state, action) => {
            // @ts-ignore
            state.success = -1;
        }).addCase(fetchCheckPromo.fulfilled, (state, action) => {
            // @ts-ignore
            if (!action.payload.promo || !action.payload.promo.code) {
                state.checkPromoError = true;
            } else {
                // @ts-ignore
                state.promo = action.payload.promo;
                // @ts-ignore
                state.values.promo_id = action.payload.promo.promo_id || 0;
            }
            state.checkPromo = false;
        }).addCase(fetchResetPromo.fulfilled, (state, action) => {
            // @ts-ignore
            if (action.payload.answer === 'ok') {
                state.promo = {
                    type: '',
                    number: '',
                    code: '',
                    target: '',
                    mode: '',
                    mail: '',
                    target_id: 0,
                    promo_id: 0,
                    start_rental: 0,
                    end_rental: 0,
                    text: '',
                };
                state.values.promo_id = 0;
            }
        }).addCase(fetchCheckTourUserEmail.pending, (state, action) => {
            state.checkEmail = false;
        }).addCase(fetchCheckTourUserEmail.fulfilled, (state, action) => {
            //  console.log('ac',action);
            // @ts-ignore
            if (action.payload.checkEmail !== undefined) {
                // @ts-ignore
                if (action.payload.checkEmail) {
                    state.checkEmail = true;
                } else {
                    state.checkEmailError = true;
                }
            }
        })
    }
})

export const {
    resetTourBook,
    updateBookTour,
    updateBookBike,
    updateStage,
    updateExtra,
    updateAccessories,
    updateAccessoriesCount,
    updateAccessoriesSize,
    updateUser,
    updatePercent,
    updateMethod,
    processSuccess,
    updatePassenger,
    updateHotel,
    updateInsurance,
    processCheckPromo,
    resetCheckPromoError,
    resetCheckTourEmail,
    resetSuccess,
} = toursBookSlice.actions;
export const toursBookReducer = toursBookSlice.reducer;