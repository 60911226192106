import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useScrollTop} from "../../hooks";
import {ModalPortal} from "../common";
import {ModalWrap} from "../common/ModalWrap";
import {PackageOrderCheckout} from "./PackageOrderCheckout";
import {resetState} from "../../features/packageBikeDetails/packageBikeDetailsSlice";
import {label} from "../../service";
import "./packageOrder.scss";
import {ImCross} from "react-icons/im";
import {fetchMyPackages} from "../../features/myPackages/myPackagesSlice";

export const PackageOrder = () => {
    const packageBikeDetails = useAppSelector(state=>state.packageBikeDetails);
    const {load,bike_id,order_id,status } = packageBikeDetails;

    const dispatch = useAppDispatch();

    useScrollTop();

    const handleClose = () => {
        // @ts-ignore
        dispatch(resetState());
    }

    useEffect(()=>{
        if(+status == 2){
            dispatch(fetchMyPackages());
        }
    },[status]);

    if(!load || +bike_id === 0 || +order_id === 0){
        return null;
    }

    return (
        <ModalPortal addClass={"package-order-modal"}>
            <ModalWrap closeModal={handleClose}>
                <div className={"modal-header"}>
                    {label('Package Order')}
                    <div className={"modal-header-close"} onClick={handleClose}>
                        <ImCross />
                    </div>
                </div>
                <PackageOrderCheckout />
            </ModalWrap>
        </ModalPortal>
    )
}