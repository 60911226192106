import React, {FC} from "react";
import {PackageItem} from "../../types/packages";
import {useAppDispatch} from "../../app/hooks";
import {checkPackage} from "../../features/packages/packagesSlice";
import {label} from "../../service";
import {Checkbox} from "@mui/material";

interface itemProps {
    item: PackageItem
    checked: boolean
}

export const PackageListItemMobile: FC<itemProps> = ({item, checked}) => {
    const dispatch = useAppDispatch();

    const handleCheck = () => {
        dispatch(checkPackage({package_id: item.id}));
    }

    return (
        <div className={"packages_list_item_mobile "} onClick={handleCheck}>
            <div className={"packages_list_item_mobile_header "}>
                <div className={"packages_list_item_mobile_info "}>
                    <div className={"packages_list_item_mobile_name "}>{item.bike_name}</div>
                    <div className={"packages_list_item_mobile_desc "}>{item.count_days + " " + label('Days')}&nbsp;/&nbsp;{item.price}€
                    </div>
                </div>
                {checked && <div className={"packages_list_item_mobile_checked btn_more"}>{label('Checked')}</div>}
            </div>
            <div className={"packages_list_image_mobile "}>
                <img src={item.image_medium} alt={""}/>
            </div>
        </div>
    )
}