import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {ProfileState} from "../../types/profile";

export const fetchProfileData = createAsyncThunk(
    'fetchProfileData',
    async (mode:string,thunkAPI)  => {
        return await api.getProfileData(mode) as ProfileState;

    }
)


const initialState:ProfileState ={
    menu:[]
};

export const profileSlice = createSlice({
    name:"profile",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfileData.fulfilled,(state,action)=>{
            state.menu = action.payload.menu;
        })
    }
})

export const {} = profileSlice.actions;
export const profileReducer = profileSlice.reducer;