import React, {FC} from 'react';
 interface ModalWrapProps{
     children?:React.ReactNode,
     closeModal:()=>void
 }
 export const ModalWrap:FC<ModalWrapProps> = ({children,closeModal}) => {
    return (
        <>
            <div className={"modal-layer"}
                 onClick={closeModal}
            />
            <div className={"modal-wrap"}>
                {children}
            </div>
        </>
    );
};

