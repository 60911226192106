import React, {FC, useEffect, useState} from "react";
import {ModalPortal} from "../ModalPortel";
import {ModalWrap} from "../ModalWrap";
import {Roulette} from "./Roulette";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import "./roulette.scss";
import {MailStage} from "./MailStage";
import {Cookies, withCookies} from "react-cookie";
import {
    changeSound,
    changeStage,
    fetchRoulette,
    resetCheckError,
    resetRoulette
} from "../../../features/roulette/roulette";
import {SetCookie} from "../../../utils/cookie";

interface modalProps{
    onClose:()=>void
    cookies:Cookies
}
const ModalRoulette:FC<modalProps> = ({onClose,cookies}) => {

    const roulette = useAppSelector(state=>state.roulette);
    const {mail,stage,sound,status} = roulette;

    const roulette_sound = cookies.get('roulette_sound');

    const dispatch = useAppDispatch();

    useEffect(()=>{
        if(roulette_sound){
            dispatch(changeSound({sound : roulette_sound === 'on'}))
        }
        dispatch(fetchRoulette());
        return ()=>{
            dispatch(resetRoulette({}));
        }
    },[]);

    useEffect(()=>{
        if(+status === 2){
            dispatch(changeStage({stage: 'roulette'}))
        }
    },[status]);

    const handleHideModal = () => {
       onClose();
    }

    const handleSoundOn = () => {
        dispatch(changeSound({sound:true}));
        SetCookie('roulette_sound','on');
    }

    const handleSoundOff = () => {
        dispatch(changeSound({sound:false}));
        SetCookie('roulette_sound','off');
    }

    const getStageData = () => {
        switch(stage){
            case 'mail':
                return <MailStage handleHideModal={handleHideModal} soundOn={handleSoundOn} soundOff = {handleSoundOff}/>
            case 'roulette':
                return <Roulette exitHandler = {handleHideModal} mail={mail} sound={sound}/>;
        }
    }

    return (
        <ModalPortal addClass={"medium-modal"}>
            <ModalWrap closeModal={onClose}>
                <div className={"roulette_data"}>
                    <div className={'roulette_data_body '+stage+'_stage'}>
                        {getStageData()}
                    </div>
                </div>
            </ModalWrap>
        </ModalPortal>
    )
}

export default withCookies(ModalRoulette);