import {BikeDetail} from "../../types";
import {CalType} from "../../page/Bike";

function getSeason(sets:number[],start:string){
    const day = Number(start.slice(0,2))<16?0:1;
    const month = Number(start.slice(3,5))-1;
    return  sets[month*2+day] || 1 ;

}

function getDiscountPercent(details:BikeDetail,duration:number){

    if(duration<2){
        return 0
    }
    if(duration==2){
        return  Number(details.calc.price.days['2_day']) || 0;
    }
    if(duration==3){
        return   Number(details.calc.price.days['3_day']) || 0;
    }
    if(duration==4){
        return   Number(details.calc.price.days['4_day'] )|| 0;
    }
    if(duration==5){
        return   Number(details.calc.price.days['5_day']) || 0;
    }
    if(duration==6){
        return   Number(details.calc.price.days['6_day']) || 0;
    }
    if(duration<14){
        return   Number(details.calc.price.days['1_week'] )|| 0;
    }
    if(duration<21){
        return   Number(details.calc.price.days['2_week'] )|| 0;
    }
    if(duration<21){
        return   Number(details.calc.price.days['2_week'] )|| 0;
    }
    if(duration<28){
        return   Number(details.calc.price.days['3_week'] )|| 0;
    }

        return  Number(details.calc.price.days['4_week']) || 0;

}


  function roundPrice(price:number){
     return Math.round(price/5)*5
  }

  function getPrice(details:BikeDetail,season:number,type:CalType){
    const price = type==="normal"?details.price:(type==="half"?details.price_ht:details.price_weekend)

    if(season===1){
        return Number(price);
    }
    if (season===2){
        return  roundPrice((Number(price)/100)*(100-details.calc.price.low));
    }
    if (season===3){
        return  roundPrice((Number(price)/100)*(100+Number(details.calc.price.high)));
    }
    return  0;
}
export function priceCalc(details:BikeDetail,data: {
    start: string,
    end: string,
    duration: number,
},type:CalType="normal"){
    const season =  getSeason(details.calc.season,data.start);
    const price = getPrice(details,season,type );

    let total = 0;
    let real = 0;
    let discount =0;

    if(type==="normal"){
        total = price*data.duration;
        const discountPercent =getDiscountPercent(details,data.duration);
        discount= Math.round((total/100)*discountPercent);
        real = total-discount;
    }else {
        total = real = price;
    }


    return {price,total:{total,discount,real}};
}