import React, {FC} from "react";
import {label} from "../../service";
import {Equipments, Extras} from "../../types";
import {AccessoriesSizes} from "./AccessoriesSizes";

interface checkoutPageSizeProps{
    equip: Equipments[]
    nextPage: (page: number) => void
    onBack: (page:number) => void
    onAccessoryChangeSize: (id:number,number:number,size:string) => void
    accessor: {}
}

export const CheckoutPageSize:FC<checkoutPageSizeProps> = ({
                                                               equip,
                                                               nextPage,
                                                               onBack,
                                                               accessor,
                                                               onAccessoryChangeSize
                                                           }) => {

    const handleBack = () => {
        onBack(0)
    }

    const handleNext = () => {
        nextPage(2);
    }

    return (
        <div className="checkout">
            <div className="selected-block">
                <div className="main-data">
                    {<AccessoriesSizes
                        equip = {equip}
                        accessor = {accessor}
                        onAccessoryChangeSize = {onAccessoryChangeSize}
                    />}
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-secondary"}
                     onClick={handleNext}>{label('Next')}</div>
            </div>
        </div>
    )
}