import React, {useEffect, useState} from "react";
import {UserData} from "../bike/UserData";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    fetchCheckTourUserEmail,
    resetCheckTourEmail,
    updateStage,
    updateUser
} from "../../features/tours/tourBookSlice";
import {CheckoutError} from "../bike/Checkout";
import {User} from "../../types";
import {validateEmail, validateName, validatePhone} from "../../utils";

export const TourBookCheckoutUser = ({}) => {
    const [error, setError] = useState<CheckoutError>({
        email: false,
        phone: false,
        name: false,
        surname: false,
        password: false,
        date: false,
        birthdate:false,
        zipcode:false,
        country:false,
        city:false,
        street:false,
        is_firm:false,
        firm_name:false,
        ICO:false,
        DIC:false,
        IC_DPH:false,
    });

    const user = useAppSelector(state => state.tourBook.user);
    const equip = useAppSelector(state => state.tourBook.equip);
    const extra = useAppSelector(state => state.tourBook.extra);
    const values = useAppSelector(state => state.tourBook.values);
    const checkEmail = useAppSelector(state => state.tourBook.checkEmail);
    const checkEmailError = useAppSelector(state => state.tourBook.checkEmailError);
    const profile = useAppSelector(state => state.login.profile);
    const states = useAppSelector(state => state.common.states);

    const dispatch = useAppDispatch();
    let needSize = false;
    equip.forEach(item => {
        if (values.accessories[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    useEffect(()=> {
        if(+profile.user_id > 0){
            dispatch(updateUser({name:'name',value:profile.name}));
            dispatch(updateUser({name:'email',value:profile.mail}));
            dispatch(updateUser({name:'phone',value:profile.phone}));
            dispatch(updateUser({name:'street',value:profile.street}));
            dispatch(updateUser({name:'city',value:profile.city}));
            dispatch(updateUser({name:'country',value:profile.country}));
            dispatch(updateUser({name:'country_id',value:profile.country_id}));
            dispatch(updateUser({name:'zipcode',value:profile.zipcode}));
            dispatch(updateUser({name:'birthdate',value:profile.birthdate}));
        }
    },[profile]);

    useEffect(()=>{
        return () => {
            // @ts-ignore
            dispatch(resetCheckTourEmail());
        }
    },[]);

    const isExtra = +values.bike_id>0 && (equip.length > 0 || extra.length > 0);
    const handleBack = () => {
        /*if(needHotels){
            dispatch(updateStage({stage:3}))
        } */
        if (needSize){
            dispatch(updateStage({stage:2}))
        }else if (isExtra){
            dispatch(updateStage({stage:1}))
        }else{
            dispatch(updateStage({stage:0}))
        }
    }

    useEffect(()=>{
        if(checkEmail){
            handleBook();
        }
    },[checkEmail]);

    const handleNext = () => {
        if(+profile.user_id > 0){
            handleBook();
        }else{
            dispatch(fetchCheckTourUserEmail(user.email));
        }
    }

    const handleBook = () => {
        // console.log('handleBook',error,user);
        if(!validate(user)){
            return
        }
        dispatch(updateStage({stage: 5}));
    }

    const handleUserChange = (name:string,value:any) => {
        if(name === "email"){
            // @ts-ignore
            dispatch(resetCheckTourEmail())
        }
        dispatch(updateUser({name:name,value:value}));
    }

    const validate = (user:User) => {
        const error: CheckoutError = {
            email: false,
            phone: false,
            name: false,
            surname: false,
            password: false,
            date: false,
            birthdate:false,
            zipcode:false,
            country:false,
            city:false,
            street:false,
            is_firm:false,
            firm_name:false,
            ICO:false,
            DIC:false,
            IC_DPH:false,
        }
        let result = true;
        if (!validateEmail(user.email)) {
            error.email = true;
            result = false;
        }
        if (!validatePhone(user.phone)) {
            error.phone = true;
            result = false;
        }
        if (!validateName(user.name)) {
            error.name = true;
            result = false;
        }
        if (!validateName(user.surname)) {
            error.surname = true;
            result = false;
        }
        /*if(+profile.user_id === 0 && user.password === ''){
            error.password = true;
            result = false;
        }*/
       /* if(user.birthdate === ''){
            error.birthdate = true;
            result = false;
        }*/
        if(+user.country_id === 0){
            error.country = true;
            result = false;
        }
        if(user.city === ''){
            error.city = true;
            result = false;
        }
        if(user.street === ''){
            error.street = true;
            result = false;
        }
       /* if(user.zipcode === ''){
            error.zipcode = true;
            result = false;
        }*/
        setError(error);
        return result;
    }

    return (
        <div className="checkout">
            <div className="selected-block one">
                <div className="main-data">
                    <UserData error={error} form={user} onFormChange={handleUserChange} profile={profile} states={states} checkEmailError={checkEmailError}/>
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-secondary"}
                     onClick={handleNext}>{label('Next')}</div>
            </div>
        </div>
    )
}