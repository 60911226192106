import React, {useState, MouseEvent, FC, useEffect} from "react";
import "swiper/css/bundle";
// @ts-ignore;
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import Gallery from "react-photo-gallery";
import "./photos.scss";
import {label} from "../service";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {fetchPhotosList} from "../features/photos/photosSlice";
import {useScrollTop} from "../hooks";

export const Photos = () => {
    const [items, setItems] = useState([] as photoT[]);

    const images = useAppSelector(state=>state.photos.images);

    useScrollTop();

    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(fetchPhotosList());
    },[])

    useEffect(()=>{
        setItems(JSON.parse(JSON.stringify(images)));
    },[images])

    // @ts-ignore
    const onSortEnd = ({oldIndex, newIndex}) => {
        setItems(arrayMove(items, oldIndex, newIndex));
    };

    // console.log('i',items);

    return (
        <main className={"page"}>
            <div className={"photos w-full"}>
                <div className={"w-wrap"}>
                    <div className="page-header">
                        <h1 className={"title"}>{label('Photos')}</h1>
                    </div>
                    {items.length > 0 && <div className={"photos_area"}>
                        <SortableGallery
                            // @ts-ignore
                            items={items}
                            onSortEnd={onSortEnd}
                            axis={"xy"}/>
                    </div>}
                </div>
            </div>
        </main>
    );
}

const imgWithClick = {cursor: "pointer"};

// @ts-ignore
const SortableGallery = SortableContainer(({items}) => (
    <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />}/>
));

// @ts-ignore
const SortablePhoto = SortableElement(item => <Photo {...item}/>);

type imgStyleT = {
    margin: number
    position?: string
    left?: number
    top?: number
}

type photoT = {
    src: string
    width: number
    height: number
}

interface photoProps {
    index: number
    onClick?: (event: React.MouseEvent<HTMLImageElement>, p: { photo: photoT; index: number }) => void
    photo: photoT
    margin: number
    direction: string
    top: number
    left: number
}

const Photo: FC<photoProps> = ({index, onClick, photo, margin, direction, top, left}) => {
    const imgStyle: imgStyleT = {margin: margin};

    if (direction === "column") {
        imgStyle.position = "absolute";
        imgStyle.left = left;
        imgStyle.top = top;
    }

    const handleClick = (event: MouseEvent<HTMLImageElement>) => {
        if (onClick) {
            onClick(event, {photo, index});
        }
    };

    return (
        <img
            // @ts-ignore
            style={onClick ? {...imgStyle, ...imgWithClick} : imgStyle}
            {...photo}
            // @ts-ignore
            onClick={onClick ? (e) => {
                handleClick(e)
            } : null}
            alt="img"
        />
    );
};

