import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {api} from "../../service";
import {FaqState} from "../../types/faq";


export const fetchFaqList = createAsyncThunk(
    'fetchFaqList',
    async (thunkAPI)  => {
        return await api.getFaqList() as FaqState;

    }
)


const initialState:FaqState ={
    categories: [],
    list: [],
};

export const faqSlice = createSlice({
    name:"faq",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchFaqList.fulfilled,(state,action)=>{
            state.categories =  action.payload.categories;
            state.list =  action.payload.list;
        })
    }
})

export const {} = faqSlice.actions;
export const faqReducer = faqSlice.reducer;