import React from "react";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {useAppDispatch} from "../../app/hooks";
import {showGallery} from "../../features/galleryViewer/galleryViewerSlice";
import {Gallery} from "../../types/tourItem";

export const GallerySlider = ({
                                  photos,
                                  slidesPerView = 4,
                                  slidesPerGroup = 4,
                                  loop = true,
                                  loopFillGroupWithBlank = true,
                                  paginationClickable = true,
                                  spaceBetween = 15,
                                  navigation = true,
                                  className = "mySwiper",
                                  handleShowImage = null,
                              }) => {

    const dispatch = useAppDispatch();

    const handleShowGalleryViewer = (index,photo) => {
        if (handleShowImage){
            handleShowImage(photo,index)
        } else {
            dispatch(showGallery({images:photos,current:index}));
        }
    }

    return (
        <div className={"photos_gallery"}>
            <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                slidesPerGroup={slidesPerGroup}
                loop={loop}
                loopFillGroupWithBlank={loopFillGroupWithBlank}
                pagination={{
                    clickable: paginationClickable,
                }}
                navigation={navigation}
                modules={[Pagination, Navigation]}
                className={className}
            >
                {photos.map((photo, i) => {
                    return (
                        <SwiperSlide
                            key={i}
                        >
                            <img src={photo.image_medium}
                                 alt={''}
                                 onClick={()=>handleShowGalleryViewer(i,photo)}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}