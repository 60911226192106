import React, {FC, useEffect, useState} from "react";
import {label} from "../../service";
import {GetCookie, SetCookie} from "../../utils/cookie";
import {Cookies,withCookies} from "react-cookie";
import {useAppSelector} from "../../app/hooks";

interface buttonProps{
    cookies: Cookies
}
const RouletteButton:FC<buttonProps> = ({cookies}) => {
    const [show,setShow] = useState(false);
    const rouletteMail = cookies.get('roulette_mail') || '';
    const rouletteHide = cookies.get('roulette_hide') || '';

    const roulette_enabled = useAppSelector(state=>state.common.params.roulette_enabled);

    useEffect(()=>{
    //    console.log('rB','rouletteMail: '+rouletteMail,'rouletteHide: '+rouletteHide);
        if(+roulette_enabled > 0 && !rouletteMail && rouletteHide && !show){
            setShow(true);
        }
        if((rouletteMail || !rouletteHide) && show){
            setShow(false);
        }
    },[rouletteMail,rouletteHide,roulette_enabled]);

    const handleRoulette = () => {
        if(!rouletteMail){
            SetCookie('roulette_hide','')
        }
        setShow(false);
    }

    if(!show){return null;}
    return (
        <div className={"header_roulette"}>
            <img src={'/images/wheel.jpg'} alt={label('First Order Bonus')} onClick={handleRoulette}/>
        </div>
    )
}

export default  withCookies(RouletteButton);