import React, {FC, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {MainInput} from "../../tools/MainInput";
import {label} from "../../../service";
import {
    changeMail,
    changeSound,
    changeStage,
    checkRouletteMail,
    resetCheckError,
    setError
} from "../../../features/roulette/roulette";
import {RouletteError} from "../../../types/roulette";
import {validateEmail} from "../../../utils";
import {SetCookie} from "../../../utils/cookie";

interface stageProps {
    handleHideModal: () => void
    soundOn: () => void
    soundOff: () => void
}

export const MailStage: FC<stageProps> = ({handleHideModal, soundOn, soundOff}) => {

    const mail = useAppSelector(state => state.roulette.mail);
    const error = useAppSelector(state => state.roulette.error);
    const sound = useAppSelector(state => state.roulette.sound);
    const checkError = useAppSelector(state => state.roulette.checkError);
    const message = useAppSelector(state => state.roulette.message);
    const rules = useAppSelector(state => state.roulette.rules);

    const dispatch = useAppDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeMail({mail: e.target.value}));
    }

    const handlePlay = () => {
        if (!validate()) {
            return;
        }
        dispatch(checkRouletteMail(mail));
    }

    const handleBack = () => {
        // @ts-ignore
        dispatch(resetCheckError());
    }

    const validate = () => {
        let newError: RouletteError = {
            mail: false
        }
        let result = true;
        if (!validateEmail(mail)) {
            newError.mail = true;
            result = false;
        }
        if (!result) {
            dispatch(setError({error: newError}))
        }
        return result;
    }


    return (
        <div className={"roulette_data_body_mail"}>
            <div className={"roulette_data_body_mail_header"}>
                <div className="page-header">
                    <h1 className={"title"}>{label('First Order Bonus')}</h1>
                </div>
                <div className={"roulette_data_body_mail_header_sound"}>
                    {sound ? <img src={"/images/sound_off.png"} onClick={soundOff}/> :
                        <img src={"/images/sound_on.png"} onClick={soundOn}/>}
                </div>
                <div className={"roulette_data_body_mail_header_exit"}>
                    <img src={"/images/but_exit_cr.png"} onClick={handleHideModal}/>
                </div>
            </div>
            {checkError ?
                <div className={"roulette_data_body_mail_body"}>
                    <div className={"roulette_data_body_mail_error"}>
                        <div className={"roulette_data_body_mail_error_message"}>{message}</div>
                    </div>
                    <div className={"roulette_data_body_mail_footer"}>
                        <div className={"roulette_data_body_mail_footer_button button_item"}>
                            <div className={"btn_more secondary_btn"} onClick={handleBack}>{label('Back')}</div>
                        </div>
                    </div>
                </div>
                :
                <div className={"roulette_data_body_mail_body"}>
                    {rules.header && rules.items.length > 0 && <div className={"roulette_data_body_mail_rules"}>
                            {rules.header && <div className={"roulette_data_body_mail_rules_header"}>{rules.header}</div>}
                            {rules.items.length > 0 &&  <ul className={"roulette_data_body_mail_rules_items"}>
                                {rules.items.map(item=>{
                                    return (
                                        <li className={"roulette_data_body_mail_rules_item "}>
                                            {item.name && <div className={"roulette_data_body_mail_rules_name "}>{item.name}</div>}
                                            <div className={"roulette_data_body_mail_rules_text "}>{item.text}</div>
                                        </li>
                                    )
                                })}
                            </ul>}
                    </div>}
                    <div className={"roulette_data_body_mail_input"}>
                        <div
                            className={"roulette_data_body_mail_input_label"}>{label('Please enter your email first to receive your order bonus')}</div>
                        <MainInput error={error.mail} value={mail} onChange={handleChange} name={'mail'}
                                   placeHolder={label('Email')}/>
                    </div>
                    <div className={"roulette_data_body_mail_footer"}>
                        <div className={"roulette_data_body_mail_footer_button button_item"}>
                            <div className={"btn_more"} onClick={handlePlay}>{label('Play')}</div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}