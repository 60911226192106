import React, {Fragment} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {PackageItem} from "../../types/packages";
import {PackageListItem} from "./PackageListItem";
import {checkPackage} from "../../features/packages/packagesSlice";
import {PackageListItemMobile} from "./PackageListItemMobile";

export const PackagesList = () => {
    const packages = useAppSelector(state => state.packages.packages);
    const values = useAppSelector(state => state.packages.values);
    const mobile = useAppSelector(state => state.common.mobile);

    const dispatch = useAppDispatch();

    const handleChange = ()  => {
        dispatch(checkPackage({package_id:0}));
    }

    return (
        <div className={"packages_list "}>
            <div className={"packages_list_items "}>
                {packages.length > 0 ? <Fragment>
                        {packages.map((item: PackageItem) => {
                            if(+values.package_id > 0 && +item.id!==+values.package_id){
                                return null;
                            }
                            if(mobile){
                                return (
                                    <PackageListItemMobile key={item.id}
                                                     item={item}
                                                     checked={+item.id===+values.package_id}
                                    />
                                )
                            }
                            return (
                                <PackageListItem key={item.id}
                                                 item={item}
                                                 checked={+item.id===+values.package_id}
                                />
                            )
                        })}
                    </Fragment> :
                    <div className={"packages_list_empty "}>{label('No Packages Available')}</div>
                }
            </div>
            {+values.package_id > 0 && packages.length > 1 && <div className={"packages_list_change "}>
                <div className={"btn  " + " btn-secondary"}
                     onClick={handleChange}
                >{label('Change')}</div>
            </div>}
        </div>
    )
}