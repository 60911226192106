import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {api} from "../../service";
import {RouletteError, RouletteState} from "../../types/roulette";

export const fetchRoulette = createAsyncThunk(
    'fetchRoulette',
    async (thunkAPI)  => {
        return await api.fetchRoulette();
    }
)

export const checkRouletteMail = createAsyncThunk(
    'checkRouletteMail',
    async (mail:string,thunkAPI)  => {
        return await api.checkRouletteMail(mail);
    }
)

const initialState:RouletteState ={
    mail: '',
    stage: 'mail',
    sound: false,
    error: {
        mail:false
    },
    status:0,
    checkError:false,
    message:'',
    rules:{
        header:'',
        items:[]
    }
};

export const rouletteSlice = createSlice({
    name:"roulette",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeMail: (state, action: PayloadAction<{ mail: string }>) => {
            return {...state, mail: action.payload.mail};
        },
        changeStage: (state, action: PayloadAction<{ stage: string }>) => {
            return {...state, stage: action.payload.stage};
        },
        setError:  (state, action: PayloadAction<{ error: RouletteError }>) => {
            return {...state, error: action.payload.error};
        },
        changeSound: (state, action: PayloadAction<{ sound: boolean }>) => {
            return {...state, sound: action.payload.sound};
        },
        resetCheckError: (state, action: PayloadAction<any>) => {
            return {...state, status: 0,checkError:false,message:''};
        },
        resetRoulette: (state, action: PayloadAction<any>) => {
            return {...state, status: 0,checkError:false,message:'',stage:'mail'};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkRouletteMail.pending,(state,action)=>{
            state.status = 1;
        }).addCase(checkRouletteMail.fulfilled,(state,action:PayloadAction<any>)=>{
            if(action.payload.error){
                state.checkError = true;
                state.message = action.payload.message;
            }else{
                state.status = 2;
            }
        }).addCase(fetchRoulette.fulfilled,(state,action:PayloadAction<any>)=>{
            if(action.payload.rules){
                state.rules = action.payload.rules;
            }
        })
    }
})

export const {changeMail,setError,changeStage,changeSound,resetCheckError,resetRoulette} = rouletteSlice.actions;
export const rouletteReducer = rouletteSlice.reducer;