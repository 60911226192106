import React, {FC, Fragment, useState} from "react";
import {OrderDetail, OrderHistoryItem, PackageItem} from "../../types/myPackages";
import {label} from "../../service";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {ModalPortal} from "../common";
import {ModalWrap} from "../common/ModalWrap";
import moment from "moment";
import {fetchPackageBikeDetails} from "../../features/packageBikeDetails/packageBikeDetailsSlice";

interface detailsProps {
    item: PackageItem
}
export const MyPackageItemDetails:FC<detailsProps> = ({item}) => {
    return (
        <div className={"my_packages_list_item_details"}>
            <div className={"my_packages_list_item_details_summary"}>
                <div className="my_packages_list_item_details_label">{label('Order information')}</div>
                <MyPackageItemInformation
                    item={item}
                />
            </div>
            <div className={"my_packages_list_item_details_goods"}>
                <div className="my_packages_list_item_details_label">{label('Details')}</div>
                <MyPackageItemGoods
                    item={item}
                />
                <MyPackageItemPayments
                    item={item}
                />
            </div>
        </div>
    )
}

const MyPackageItemInformation: FC<{ item: PackageItem }> = ({item}) => {
    const [showHistory, setShow] = useState(false);

    const current_language = useAppSelector(state => state.common.current_language);

    const dispatch = useAppDispatch();

    const handleHistory = () => {
        setShow(true)
    }

    const handleInvoice = () => {
        let target = "_blank";
        let link = "/app/order-invoice?id=" + item.id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const invoiceView = () => {
        let target = "_blank";
        let link = "/app/invoice-view?id=" + item.invoice_id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const createPackageOrder = () => {
        dispatch(fetchPackageBikeDetails({order_id:item.id,bike_id:0}));
    }

    const handleReceipt = () => {
        let target = "_blank";
        let link = "/app/receipt?id=" + item.id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const handleOnlineCard = () => {
        let target = "_blank";
        let link = "/app/online-card?id=" + item.id;
        // 👇 Open link in new tab programmatically
        window.open(link, target, 'noreferrer');
    }

    const handleCloseHistory = () => {
        setShow(false)
    }

    const getHistory = () => {
        return (
            <ModalPortal addClass={"small-modal"}>
                <ModalWrap closeModal={handleCloseHistory}>
                    <div className={"modal-header"}>
                        {label('Order History')}
                    </div>
                    <div className={"my_packages_history "}>
                        <ul className={"my_packages_history_items "}>
                            {item.history.map((item: OrderHistoryItem) => {
                                return (
                                    <li className={"my_packages_history_item "}>
                                        <div className={"my_packages_history_item_decor " + (item.action)}/>
                                        {false && <time className="my_packages_history_item_date">{moment(item.create_at * 1000).format('HH:mm DD.MM.YY')}</time>}
                                        <time className="my_packages_history_item_date">{item.create_time}</time>
                                        <p className={"my_packages_history_item_status "}>{item.action_name}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </ModalWrap>
            </ModalPortal>
        )
    }

    let momentFormat = 'DD.MM.YYYY HH:mm';

    return (
        <Fragment>
            {showHistory && getHistory()}
            <section>
                {item.package_name && <div className={"my_packages_list_item_details_summary_item main_item"}>{item.package_name}</div>}
            </section>
            <section>
                {item.mail && <div className={"my_packages_list_item_details_summary_item"}>{item.mail}</div>}
                {item.name && <div className={"my_packages_list_item_details_summary_item"}>{item.name}</div>}
                {item.phone && <div className={"my_packages_list_item_details_summary_item"}>{item.phone}</div>}
            </section>
            <section className={"my_packages_list_item_details_summary_links"}>
                {item.history.length > 0 &&
                    <div className={"my_packages_list_item_details_summary_item link_item"}
                         onClick={handleHistory}
                    >{label('Order History')}</div>}
                {+item.status === 1 /*&& order.payment_method === "bank_transfer"*/ &&
                    <div className={"my_packages_list_item_details_summary_item link_item"}
                         onClick={handleInvoice}
                    >{label('Download Proforma Invoice')}</div>}
                {+item.status === 1 && (item.payment_method === "online_card" || item.payment_method === "bank_transfer") &&
                    <div className={"my_packages_list_item_details_summary_item link_item"}
                         onClick={handleOnlineCard}>
                        {label('Pay Online Card')}
                        <span>{' '+item.price+'€'}</span>
                    </div>}
                {+item.status !== 5 && +item.invoice_id > 0 &&
                    <div className={"my_packages_list_item_details_summary_item link_item"}
                         onClick={invoiceView}
                    >{label('Download Invoice')}</div>}
                {+item.status === 2 && +item.count_left > 0 &&
                    <div className={"my_packages_list_item_details_summary_item link_item"}
                         onClick={createPackageOrder}
                    >{label('Create Order')}</div>}
            </section>
        </Fragment>

    )
}

const MyPackageItemGoods: FC<{ item: PackageItem }> = ({item}) => {
    return (
        <ul className={"my_packages_list_item_details_goods_items"}>
            {item.details.map((detail: OrderDetail) => {
                const getImage = () => {
                    switch (detail.type) {
                        case 'bike':
                            if (item.bike_img_medium) {
                                return (
                                    <img loading="lazy"
                                         src={item.bike_img_medium}
                                         alt={detail.name}/>
                                )
                            }
                            break;
                    }
                    return null;
                }
                const getImageClass = () => {
                    switch (detail.type) {
                        case 'bike':
                            if (item.bike_img_medium) {
                                return "";
                            }
                            break;
                    }
                    return " without_image";
                }
                return (
                    <li className={"my_packages_list_item_details_goods_tile"}>
                        <div className={"my_packages_list_item_details_goods_tile_title"}>
                            <figure className="my_packages_list_item_details_goods_tile_figure">
                                <span className={"my_packages_list_item_details_goods_tile_picture " + (getImageClass())}>
                                      {getImage()}
                                </span>
                                <figcaption>{detail.name}</figcaption>
                            </figure>
                        </div>
                        <div className={"my_packages_list_item_details_goods_tile_options"}>
                            <div className={"my_packages_list_item_details_goods_tile_option"}>
                                {detail.quantity > 1 && <>
                                    <div
                                        className={"my_packages_list_item_details_goods_tile_label"}>{label('Quantity')}</div>
                                    <div className={"my_packages_list_item_details_goods_tile_digit"}>{detail.quantity}</div>
                                </>}
                            </div>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

const MyPackageItemPayments: FC<{ item: PackageItem }> = ({item}) => {
    return (
        <ul className={"my_packages_list_item_details_payments"}>
            <li className={"my_packages_list_item_details_payments_row"}>
                <span className={"my_packages_list_item_details_payments_label"}>{label('Count Days')}</span>
                <span className={"my_packages_list_item_details_payments_value"}>{item.count_days}</span>
            </li>
            {+item.status === 2 && item.count_left > 0 && <li className={"my_packages_list_item_details_payments_row"}>
                <span className={"my_packages_list_item_details_payments_label"}>{label('Days Left')}</span>
                <span className={"my_packages_list_item_details_payments_value"}>{item.count_left}</span>
            </li>}
            <li className={"my_packages_list_item_details_payments_row"}>
                <span className={"my_packages_list_item_details_payments_label"}>{label('Payment method')}</span>
                <span className={"my_packages_list_item_details_payments_value"}>{item.payment_method_name}</span>
            </li>
            {+item.status === 2 && <li className={"my_packages_list_item_details_payments_row"}>
                <span className={"my_packages_list_item_details_payments_label"}>{label('Total paid')}</span>
                <span className={"my_packages_list_item_details_payments_value sum_value"}>{item.paid.toFixed(2)}€</span>
            </li>}
            <li className={"my_packages_list_item_details_payments_row"}>
                <span className={"my_packages_list_item_details_payments_label"}>{label('Total price')}</span>
                <span className={"my_packages_list_item_details_payments_value sum_value"}>{item.price}€</span>
            </li>
        </ul>
    )
}