import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    errorList:[],
};
export const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        data_answer: (state, action: PayloadAction<any>) => {
            return {...state,errorList:action.payload.data}

        },
        close_error:(state,action:PayloadAction<number>)=>{
            return {...state,errorList:[...state.errorList.slice(0,action.payload),...state.errorList.slice(action.payload+1)]}
        }

    },
    extraReducers: (builder) => {

    }
})

export const {data_answer,close_error} = errorSlice.actions;
export const errorReducer = errorSlice.reducer;