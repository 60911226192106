import React, {FC} from 'react';
import {label} from "../../service";
import {BikeDetail} from "../../types";

export const BikeInfo:FC<{item:BikeDetail}> = ({item}) => {
    return (
        <div className={"bike_details_body_info"}>
            <div className={"bike_details_body_info_list"}>
                {!!item.engine && <div className="character">
                    <span>{item.engine}</span>
                    <label>{label('Engine Type')}&nbsp;</label>
                </div>}
                {!!item.power && <div className="character">
                    <span>{item.power}kW</span>
                    <label>{label('Power')}&nbsp;</label>
                </div>}
                {!!item.weight && <div className="character">
                    <span>{item.weight}kg</span>
                    <label>{label('Weight')}&nbsp;</label>
                </div>}
                {!!item.seat_height && <div className="character">
                    <span>{item.seat_height}mm</span>
                    <label>{label('Seat Height')}&nbsp;</label>
                </div>}
                {!!item.driving_license && <div className="character">
                    <span>{item.driving_license}</span>
                    <label>{label('Driving License')}&nbsp;</label>
                </div>}
                {item.mileage>0 && <div className="character">
                    <span>{item.mileage+label('km/day')}</span>
                    <label>{label('Mileage include')}&nbsp;</label>
                </div>}
                {!!item.deposit && <div className="character">
                    <span>€{item.deposit}</span>
                    <label>{label('Deposit')}&nbsp;</label>
                </div>}
            </div>
        </div>
    );
};

