import React, {FC, useState} from "react";
import {OrdersListItem} from "../../types/orders";
import moment from "moment";
import {label} from "../../service";
import {RiArrowDownSLine, RiArrowUpSLine} from "react-icons/ri";
import OrderItemDetails from "./OrderItemDetails";
import {useAppSelector} from "../../app/hooks";

interface itemProps{
    order:OrdersListItem
}
const OrderItem:FC<itemProps> = ({order}) => {
    const [open,setOpen] = useState(false);

    const getStatusClass = () => {
        switch(+order.status){
            case 5:
                return "canceled_item";
            case 4:
                return "completed_item";
            case 3:
                return "performed_item";
            case 2:
                return "paid_item";
            case 1:
            default:
                return "";
        }
    }

    const toggleOpen = () => {
        setOpen(!open);
    }

    const mobile = useAppSelector(state=>state.common.mobile);

    return (
        <li className={"orders_list_item"}>
            <div className={"orders_list_item_heading"}
                 onClick={toggleOpen}
            >
                <div className={"orders_list_item_heading_decor " +(getStatusClass())}/>
                <div className={"orders_list_item_heading_row"}>
                    <div className={"orders_list_item_heading_status"}>
                        <p className={"orders_list_item_heading_label"}>
                            <span style={{fontWeight:'bold'}}>{'#'+order.id}</span>
                            {false && <time>{moment(order.create_ts * 1000).format('HH:mm DD.MM.YY')}</time>}
                            <time>{order.create_time}</time>
                        </p>
                        <p className={"orders_list_item_heading_value"}>{order.status_name}</p>
                    </div>
                    {!open && <div className={"orders_list_item_heading_price"}>
                        <p className={"orders_list_item_heading_label"}>{label('Total price')}</p>
                        <p className={"orders_list_item_heading_value"}>{order.total}€</p>
                    </div>}
                    {!open && <div className={"orders_list_item_heading_goods"}>
                        {!mobile && order.tour_img_small!=="" &&  <div className={"orders_list_item_heading_goods_item"}>
                            <img src={order.tour_img_small} alt={order.tour_name}/>
                        </div>}
                        {!mobile && order.bike_img_small!=="" &&  <div className={"orders_list_item_heading_goods_item"}>
                            <img src={order.bike_img_small} alt={order.bike_name}/>
                        </div>}
                        {mobile && order.tour_img_medium!=="" &&  <div className={"orders_list_item_heading_goods_item"}>
                            <img src={order.tour_img_medium} alt={order.tour_name}/>
                        </div>}
                        {mobile && order.bike_img_medium!=="" &&  <div className={"orders_list_item_heading_goods_item"}>
                            <img src={order.bike_img_medium} alt={order.bike_name}/>
                        </div>}
                    </div>}
                    <div className={"orders_list_item_heading_chevron"}>
                        {!open ? <RiArrowDownSLine/>
                            :
                            <RiArrowUpSLine />
                        }
                    </div>
                </div>
            </div>
            {open && <OrderItemDetails
                order={order}
            />}
        </li>
    )
}

export default OrderItem;