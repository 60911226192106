import {TourBookBikes, TourBookPromo, TourBookValues} from "../types/tourBook";
import {Equipments, ExtraItem} from "../types";
import {
    TourItemHotel,
    TourItemInsurance,
    TourItemRule
} from "../types/tourItem";

export const getTourBookTotal = (
    values: TourBookValues,
    equip: Equipments[],
    extra: ExtraItem[],
    rules: TourItemRule[],
    bikes: TourBookBikes,
    hotels: TourItemHotel[],
    insurances: TourItemInsurance[],
    days_number: number,
    promo: TourBookPromo
) => {
    let price = getBikePrice(values, bikes, days_number);

    price += getRulesPrice(values, rules, days_number);

    if (values.passenger > 0) {
        price += getRulesPrice(values, rules, days_number);
    }

    price += getHotelsPrice(values, hotels, days_number);

    price += getInsurancesPrice(values, insurances, days_number);

    equip
        .filter(item => item.price > 0 && item.count > 0)
        .forEach(item => {
            if (values.accessories[item.id] !== undefined) {
                let count = values.accessories[item.id].count || 1;
                price += +item.price * count * days_number;
            }
        });

    extra
        .filter(item => +item.price > 0)
        .forEach(item => {
            if (values.extras.includes(item.id)) {
                price += +item.price;
            }
        });

    price += getPromoDiscount(values, promo, bikes, days_number)

    return price;
}

export const getTourBookPrice = (
    values: TourBookValues,
    rules: TourItemRule[],
    bikes: TourBookBikes,
    days_number: number
) => {
    let price = getBikePrice(values, bikes, days_number);

    price += getRulesPrice(values, rules, days_number);

    return price;
}

export const getBikePrice = (
    values: TourBookValues,
    bikes: TourBookBikes,
    days_number: number,
) => {
    const tourBikes = bikes[+values.tour_id] || [];
    const bike = tourBikes.find(bike => +bike.id === +values.bike_id);
    if (bike === undefined) {
        return 0;
    }
    let price = 0;
    const tour_price = bike.tour_prices[+values.tour_id];

    if (bike && tour_price) {
        price += +tour_price * days_number
    }

    return Math.round(Math.max(0, price) * 100) / 100;
}

export const getPromoDiscount = (
    values: TourBookValues,
    promo: TourBookPromo,
    bikes: TourBookBikes,
    days_number: number
) => {
    let discount = 0;

    if(promo.mode === 'voucher'){
        const tourBikes = bikes[+values.tour_id] || [];
        const bike = tourBikes.find(bike => +bike.id === +values.bike_id);
        if (bike === undefined) {
            return 0;
        }
        const tour_price = bike.tour_prices[+values.tour_id];

        let bike_price = 0;
        if (bike && tour_price) {
            bike_price += +tour_price * days_number
        }
        if (+promo.number > bike_price) {
            discount = -1 * bike_price;
        } else {
            discount = -1 * +promo.number;
        }
    }else{
        if (promo.target === 'bike') {
            const tourBikes = bikes[+values.tour_id] || [];
            const bike = tourBikes.find(bike => +bike.id === +values.bike_id);
            if (bike === undefined) {
                return 0;
            }
            const tour_price = bike.tour_prices[+values.tour_id];

            let bike_price = 0;
            if (bike && tour_price) {
                bike_price += +tour_price * days_number
            }
            if (+promo.target_id === 0 || +promo.target_id === +values.bike_id) {
                switch (promo.type) {
                    case 'relative':
                        discount = bike_price * ((+promo.number) / 100);
                        if (discount > bike_price) {
                            discount = -1 * bike_price;
                        }else{
                            discount = -1 *discount;
                        }
                        break;
                    case 'absolute':
                        if (+promo.number > bike_price) {
                            discount = -1 * bike_price;
                        } else {
                            discount = -1 * +promo.number;
                        }
                        break;
                }
            }
        }
    }

    return Math.round(discount * 100) / 100;
}

export const getRulesPrice = (
    values: TourBookValues,
    rules: TourItemRule[],
    days_number: number
) => {
    let price = 0;
    rules
        .filter(item => +item.price > 0)
        .forEach(item => {
            const isValue = values.rules.find((rule: any) => +rule.id === +item.id)
            if (isValue && isValue.status === 1) {
                if (+values.bike_id > 0 || item.subtype !== 1) {
                    price += item.one_time > 0 ? +item.price : +item.price * days_number;
                }
            }
        });

    return price;
}

export const getHotelsPrice = (
    values: TourBookValues,
    hotels: TourItemHotel[],
    days_number: number
) => {
    let price = 0;
    hotels
        .filter(item => +item.price > 0)
        .forEach(item => {
            const isValue = values.hotels.find((rule: any) => +rule.id === +item.id)
            if (isValue && isValue.status === 2) {
                price += item.one_time > 0 ? +item.price : +item.price * days_number;
            }
        });

    return price;
}

export const getInsurancesPrice = (
    values: TourBookValues,
    insurances: TourItemInsurance[],
    days_number: number
) => {
    let price = 0;
    insurances
        .filter(item => +item.price > 0)
        .forEach(item => {
            const isValue = values.insurances.find((rule: any) => +rule.id === +item.id)
            if (isValue && isValue.status === 2) {
                price += item.one_time > 0 ? +item.price : +item.price * days_number;
            }
        });

    return price;
}